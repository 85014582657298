import React from 'react';
import Slider from 'react-slick';

const Testimonials = ({ props }) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false
      };

    return (
        <>
        {props.testimonials.length > 0 && (
            <div className="container">
                <div className="max-w-[747px] md:mt-3 md:mb-4 mx-auto text-center">
                    {props.title &&
                        <p className="text-[#0A0A0A] text-[36px] font-acta">
                            {props.title}
                        </p>
                    }
                    <Slider {...settings}>
                        {props.testimonials.map((testimonial, index) => (
                            <React.Fragment key={index}>
                                {testimonial.testimonialSummary && (
                                    <div>
                                        <p className="text-[#0A0A0A] text-[18px] leading-[27px] font-benton tracking-[0.2px] font-normal mt-8" dangerouslySetInnerHTML={{ __html: testimonial.testimonialSummary }}>
                                        </p>
                                        {testimonial.testimonialSource && 
                                            <p className="text-[#0A0A0A] text-[20px] leading-[27px] font-benton tracking-[0.2px] font-normal mt-5">
                                                — {testimonial.testimonialSource}
                                            </p>
                                        }
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                        
                    </Slider>
                </div>
            </div>
        )}
        </>
    );
}

export default Testimonials;