import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

const DropdownArrow = ({ activeDropdown, id }) => {
    return (
        <span className={`ml-2 self-center arrow ${
            activeDropdown === id ? 'arrowRotate' : ''
        }`}>
            <ArrowDown />
        </span>
    );
};

const SearchSelect = ({
    id,
    title,
    changeTitle = false,
    options,
    optionType = false,
    useDropdownArrow = false,
    onSelectionChange,
    activeDropdown,
    onToggle,
    onOptionsUpdate,
    defaultValue,
    newTitleFormat
}) => {
    const [currentTitle, setCurrentTitle] = useState(title);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [filterText, setFilterText] = useState("");
    
    const [selectedOptions, setSelectedOptions] = useState(() => {
        if(defaultValue)
            return [defaultValue];

        return [];
    });  

    const handleOptionSelect = (option) => {
        let newSelectedOptions = selectedOptions;

        if (optionType === 'multipleSelect') {
            if(option === 'all') {
                const otherOptions = options.map(opt => opt.value).filter(val => val !== 'all');

                if(otherOptions.every(val => selectedOptions.includes(val))) {
                    newSelectedOptions = [];
                }
                else {
                    newSelectedOptions = otherOptions;
                }
            }
            else {
                if (selectedOptions.includes(option)) {
                    newSelectedOptions = selectedOptions.filter(loc => loc !== option);
                } else {
                    newSelectedOptions = [... selectedOptions, option];
                }
            }
        } else if (optionType === 'select') {
            newSelectedOptions = option;
            
            if(changeTitle) {
                if(newTitleFormat === "value")
                    setCurrentTitle(option);
                else if(newTitleFormat === "value+title")
                    setCurrentTitle(option + " " + title);
            }

            onToggle(null);
        }
    
        setSelectedOptions(newSelectedOptions);

        if(typeof onSelectionChange === "function")
            onSelectionChange(newSelectedOptions);

        onOptionsUpdate(newSelectedOptions);
            
    };

    useEffect(() => {
        /*if(defaultValue) {
            setSelectedOptions(defaultValue);
        }*/
        if(defaultValue)
            handleOptionSelect(defaultValue);
    }, []);

    const openingElementRef = useRef(null);
    const filterListElementRef = useRef(null);
    const optionElementRef = useRef(null);

    const toggleFilterList = (event) => {
        event.stopPropagation();
        onToggle(id);
    };

    const handleClickOutside = (event) => {
        if (
            openingElementRef.current &&
            !openingElementRef.current.contains(event.target) &&
            (!filterListElementRef.current ||
                !filterListElementRef.current.contains(event.target))
        ) {
            onToggle(null);
        }
    };

    useEffect(() => {
        if (activeDropdown === id) {
            document.addEventListener('mouseup', handleClickOutside);
            document.addEventListener('touchend', handleClickOutside);

            return () => {
                document.removeEventListener('mouseup', handleClickOutside);
                document.removeEventListener('touchend', handleClickOutside);
            };
        }
    }, [activeDropdown, id]);

    return (
        <div className={`SearchFilterOptions bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full relative ${activeDropdown === id ? 'transition duration-300 ease-in-out border-0 md:border md:border-black' : 'border border-transparent'}`}>
            <div
                className="py-3.5 cursor-pointer relative"
                onClick={toggleFilterList}
                ref={openingElementRef}
            >
                <span className="pl-3.5 pr-2 flex justify-between">
                    {currentTitle}
                    {useDropdownArrow && (
                        <DropdownArrow activeDropdown={activeDropdown} id={id} />
                    )}
                </span>
            </div>

            {activeDropdown === id && (
                <div className="static md:absolute top-[52px] bg-[#F1F1F1] flex flex-col w-full" ref={filterListElementRef}>
                    {options.map((option) => (
                        <span
                            className={`py-2 px-3.5 hover:bg-dark-blue hover:text-white cursor-pointer ${
                                selectedOptions.includes(option.value)
                                    ? 'bg-dark-blue text-white'
                                    : ''
                            }`}
                            value={option.value}
                            key={option.id}
                            onClick={() => handleOptionSelect(option.title)}
                            ref={optionElementRef}
                        >
                            {option.title}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchSelect;
