import Counter from "../../utils/counter";

const SellStatistics2Columns = ({ props }) => {
    const allNumbers = props.stats.map(stat => parseInt(stat.title.replace(/\D/g, ''))).filter(num => !isNaN(num));
    const maxNumber = Math.max(...allNumbers);

    return (
        <>
        {props.stats.length > 0 && (
            <>
            <div className="container flex flex-col md:flex-row justify-around flex-wrap gap-y-[60px]" id={props.anchor ? props.anchor : undefined} >
                {props.stats.map((stat, index) => {
                    const numberPart = parseInt(stat.title.replace(/\D/g, ''));
                    const textPart = stat.title.replace(/[0-9]/g, '');
                    
                    return (
                        <div className="flex w-full md:w-1/2" key={index}>
                            <div className="flex flex-col items-center justify-center max-w-[406px] mx-auto">
                                <span className="font-benton font-normal text-[12px] leading-[19px] uppercase mb-3 text-center">
                                    {stat.overtitle}
                                </span>
                                <span className="font-acta font-normal text-[36px] mb-3 text-center">

                                    {stat.title}
                                </span>
                                <span className="font-benton font-normal text-[15px] tracking-[0.38px] text-center">
                                    {stat.summary}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
            </>
        )}
        </>
    );
};

export default SellStatistics2Columns;