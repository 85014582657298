export const menu = [
    {
        id: 1,
        parent: 0,
        title: "Buy",
        url: "#"
    },
    {
        id: 2,
        parent: 0,
        title: "Sell",
        url: "#"
    },
    {
        id: 3,
        parent: 0,
        title: "Rent",
        url: "#"
    },
    {
        id: 4,
        parent: 0,
        title: "Neighbourhoods",
        url: "#"
    },
    {
        id: 5,
        parent: 0,
        title: "Journal",
        url: "/journals"
    },
    {
        id: 6,
        parent: 0,
        title: "International",
        url: "#"
    },
    {
        id: 7,
        parent: 0,
        title: "About us",
        url: "#"
    },
    {
        id: 8,
        parent: 0,
        title: "The team",
        url: "#"
    },
    {
        id: 9,
        parent: 0,
        title: "Contact us",
        url: "/contact-us"
    },
    {
        id: 10,
        parent: 1,
        title: "All properties",
        url: "#"
    },
    {
        id: 11,
        parent: 1,
        title: "Exclusive properties",
        url: "#"
    },
    {
        id: 12,
        parent: 1,
        title: "Houses",
        url: "#"
    },
    {
        id: 13,
        parent: 1,
        title: "Apartments",
        url: "#"
    },
    {
        id: 14,
        parent: 1,
        title: "Penthouses",
        url: "#"
    },
    {
        id: 15,
        parent: 1,
        title: "Lofts & Duplexes",
        url: "#"
    },
    {
        id: 16,
        parent: 1,
        title: "Off-market properties",
        url: "#"
    },
    {
        id: 17,
        parent: 1,
        title: "New to market",
        url: "#"
    },
    {
        id: 18,
        parent: 1,
        title: "Recent price reductions",
        url: "#"
    },
    {
        id: 19,
        parent: 3,
        title: "All properties",
        url: "#"
    },
    {
        id: 20,
        parent: 3,
        title: "Exclusive properties",
        url: "#"
    },
    {
        id: 21,
        parent: 3,
        title: "Houses",
        url: "#"
    },
    {
        id: 22,
        parent: 3,
        title: "Apartments",
        url: "#"
    },
    {
        id: 23,
        parent: 3,
        title: "Penthouses",
        url: "#"
    },
    {
        id: 24,
        parent: 3,
        title: "Lofts & Duplexes",
        url: "#"
    },
    {
        id: 25,
        parent: 3,
        title: "Off-market properties",
        url: "#"
    },
    {
        id: 26,
        parent: 3,
        title: "New to market",
        url: "#"
    },
    {
        id: 27,
        parent: 3,
        title: "Recent price reductions",
        url: "#"
    },
    {
        id: 28,
        parent: 4,
        title: "All Neighbourhoods",
        url: "#"
    },
    {
        id: 29,
        parent: 4,
        title: "Belgravia",
        url: "/neighbourhood/clj5o3z000008o65hxnvjyuki"
    },
    {
        id: 30,
        parent: 4,
        title: "Chelsea",
        url: "/neighbourhood/clj5o3qwt0006o65hwnsxkho7"
    },
    {
        id: 31,
        parent: 4,
        title: "Kensington",
        url: "/neighbourhood/clj5o0toe0000o65h0ulabg40"
    },
    {
        id: 32,
        parent: 4,
        title: "Knightsbridge",
        url: "/neighbourhood/clj4kdl0o0000mx5gia9lic19"
    },
    {
        id: 33,
        parent: 4,
        title: "St Johns Wood",
        url: "/neighbourhood/clj5o19hp0002o65hplvvnhig"
    },
    {
        id: 34,
        parent: 4,
        title: "Mayfair",
        url: "/neighbourhood/clj5o3ead0004o65hh9szyaze"
    },
];

export const neighbourhoods = [
    {
        id: "1",
        image: "/assets/images/tmp/neighbourhoods_1.jpg",
        title: "Mayfair",
        url: "/neighbourhood/clj5o3ead0004o65hh9szyaze"
    },
    {
        id: "2",
        image: "/assets/images/tmp/neighbourhoods_2.jpg",
        title: "Kensington",
        url: "/neighbourhood/clj5o0toe0000o65h0ulabg40"
    },
    {
        id: "3",
        image: "/assets/images/tmp/neighbourhoods_3.jpg",
        title: "Chelsea",
        url: "/neighbourhood/clj5o3qwt0006o65hwnsxkho7"
    },
    {
        id: "4",
        image: "/assets/images/tmp/neighbourhoods_4.jpg",
        title: "St Johns Wood",
        url: "/neighbourhood/clj5o19hp0002o65hplvvnhig"
    },
    {
        id: "5",
        image: "/assets/images/tmp/neighbourhoods_5.jpg",
        title: "Knightsbridge",
        url: "/neighbourhood/clj4kdl0o0000mx5gia9lic19"
    },
    {
        id: "6",
        image: "/assets/images/tmp/neighbourhoods_6.jpg",
        title: "Belgravia",
        url: "/neighbourhood/clj5o3z000008o65hxnvjyuki"
    },
];

export const searchOptions = {
    locations: [
        {
            id: "all",
            title: "All locations",
            value: "all"
        },
        {
            id: "community-1",
            title: "St John's Wood",
            value: "St John's Wood"
        },
        {
            id: "community-2",
            title: "Regent's Park",
            value: "Regent's Park"
        },
        {
            id: "community-3",
            title: "Belgravia",
            value: "Belgravia"
        },
        {
            id: "community-4",
            title: "Mayfair",
            value: "Mayfair"
        },
        {
            id: "community-5",
            title: "Battersea",
            value: "Battersea"
        },
        {
            id: "community-6",
            title: "Knightsbridge",
            value: "Knightsbridge"
        },
        {
            id: "community-7",
            title: "Westminster North",
            value: "Westminster North"
        },
        {
            id: "community-8",
            title: "Chelsea",
            value: "Chelsea"
        },
        {
            id: "community-9",
            title: "Nine Elms",
            value: "Nine Elms"
        },
        {
            id: "community-10",
            title: "Little Venice",
            value: "Little Venice"
        },
        {
            id: "community-11",
            title: "Mole Valley",
            value: "Mole Valley"
        },
        {
            id: "community-12",
            title: "Hampstead",
            value: "Hampstead"
        },
        {
            id: "community-13",
            title: "Holborn",
            value: "Holborn"
        },
        {
            id: "community-14",
            title: "Kensington",
            value: "Kensington"
        },
        {
            id: "community-15",
            title: "City of London",
            value: "City of London"
        },
    ],
    listingTypes: [
        {
            id: 1,
            value: "Sale",
            title: "Sale"
        },
        {
            id: 2,
            value: "Rent",
            title: "Rent"
        }
    ],
    prices: {min: 1750, max: 39500000},
    areas: {min: 580, max: 10642},
};

export const beds = [
    {
        id: "beds0",
        title: "0+",
        value: "0"
    },
    {
        id: "beds1",
        title: "1+",
        value: "1"
    },
    {
        id: "beds2",
        title: "2+",
        value: "2"
    },
    {
        id: "beds3",
        title: "3+",
        value: "3"
    },
    {
        id: "beds4",
        title: "4+",
        value: "4"
    },
    {
        id: "beds5",
        title: "5+",
        value: "5"
    },
    {
        id: "beds6",
        title: "6+",
        value: "6"
    }
]

export const baths = [
    {
        id: "baths0",
        title: "0+",
        value: "0"
    },
    {
        id: "baths1",
        title: "1+",
        value: "1"
    },
    {
        id: "baths2",
        title: "2+",
        value: "2"
    },
    {
        id: "baths3",
        title: "3+",
        value: "3"
    },
    {
        id: "baths4",
        title: "4+",
        value: "4"
    },
    {
        id: "baths5",
        title: "5+",
        value: "5"
    },
    {
        id: "baths6",
        title: "6+",
        value: "6"
    }
]

export const featuresAmenities = [
    {
        id: "feature1",
        title: "Balcony",
        value: "balcony"
    },
    {
        id: "feature2",
        title: "Pool",
        value: "pool"
    },
    {
        id: "feature3",
        title: "Walk-in closet",
        value: "closet"
    },
    {
        id: "feature4",
        title: "Maid's room",
        value: "maids-room"
    },
    {
        id: "feature5",
        title: "Study room",
        value: "study-room"
    },
    {
        id: "feature6",
        title: "Concierge service",
        value: "concierge"
    },
    {
        id: "feature7",
        title: "Private gym",
        value: "private-gym"
    },
    {
        id: "feature8",
        title: "Built-in wardrobes",
        value: "wardrobes"
    },
    {
        id: "feature9",
        title: "Private garden",
        value: "garden"
    },
    {
        id: "feature10",
        title: "Equipped Kitchen",
        value: "kitchen"
    },
    {
        id: "feature11",
        title: "Private pool",
        value: "private-pool"
    },
    {
        id: "feature12",
        title: "Covered parking",
        value: "parking"
    },
    {
        id: "feature13",
        title: "Private jacuzzi",
        value: "jacuzzi"
    },
    {
        id: "feature14",
        title: "Maid service",
        value: "maid"
    },
    {
        id: "feature15",
        title: "Spa",
        value: "spa"
    },
    {
        id: "feature16",
        title: "Gym",
        value: "gym"
    },
    {
        id: "feature17",
        title: "Security",
        value: "security"
    }
]

const locations = [
    {
        id: "all",
        title: "All locations",
        value: "all"
    }
];