import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as PinterestIcon } from '../../assets/icons/pinterest.svg';
import { useEffect, useState } from 'react';

const FooterSocialLinks = ({ settings }) => {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (settings && settings.relatedLinks) {
            const activeLinks = settings.relatedLinks.filter(link => {
                return ["Twitter", "Facebook", "Instagram", "Linkedin", "Youtube", "Pinterest"].includes(link.label);
            });
            setLinks(activeLinks);
        }
    }, [settings]);

    const renderIcon = (label) => {
        switch (label) {
            case "Facebook": return <FacebookIcon className="footer-social-link transition-all duration-300" />;
            case "Twitter": return <TwitterIcon className="footer-social-link transition-all duration-300" />;
            case "Instagram": return <InstagramIcon className="footer-social-link transition-all duration-300" />;
            case "Linkedin": return <LinkedinIcon className="footer-social-link transition-all duration-300" />;
            case "Youtube": return <YoutubeIcon className="footer-social-link transition-all duration-300" />;
            case "Pinterest": return <PinterestIcon className="footer-social-link transition-all duration-300" />;
            default: return null;
        }
    }
    
    return (
        <div className="mt-9 md:mt-14 flex justify-center items-center">
            {links.map(link => (
                <a href={link.href} key={link.label} target="_blank" className="mx-3">
                    {renderIcon(link.label)}
                </a>
            ))}
        </div>
    );
}

export default FooterSocialLinks;