import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDownFooter } from '../assets/icons/arrow_down_footer.svg';

const FooterDropdown = ({ options, selectedOption, onSelect}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionSelect = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef.current]);

    return (
        
        <div ref={dropdownRef} className="relative ">
            <div
                type="button"
                className="py-2.5 flex justify-between border border-blacked rounded-md bg-sothebys-blue text-white text-[11px]"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="pl-4 font-normal cursor-pointer">{selectedOption && selectedOption.label}</span> 
                <span className="self-center mr-4">
                    <ArrowDownFooter className={`w-full h-full cursor-pointer transform ${isOpen ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`} />
                </span>
            </div>
            {isOpen && (
                <div className={`absolute ml-2 z-[999] w-full -left-2 top-[28px] animate-topDropdownOpen bg-sothebys-blue border border-blacked rounded-md text-white px-3.5 py-2 cursor-pointer flex flex-col items-center text-[11px] transform transition-all duration-1000 ease-in-out ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}>
                    {options.map((option) => (
                    <span
                        key={option.value}
                        className={`text-center cursor-pointer w-full ${option.value === selectedOption.value ? 'font-bold' : ''}`}
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option.label}
                    </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FooterDropdown;

