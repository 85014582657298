import JournalCard from "../Journals/JournalCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const OtherPrArticles = ({ articles }) => {
    return (
        <div className="journal container">
            <h2 className="font-regular font-normal md:font-light text-xl md:text-2xl h-[63px] md:h-[135px] text-center flex items-center justify-center">Related Articles</h2>
            <div className="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-4 items-center md:items-stretch md:justify-items-center md:gap-8 mb-[45px] md:mb-[90px] md:space-between">
                {articles.map(article => (
                    <JournalCard 
                        key={article.id}
                        id={article.id}
                        slug={article.slug}
                        photo={article.featuredImages.length > 0 ?article.featuredImages[0].url : false } 
                        category={article.category}
                        title={article.title}
                        excerpt={article.excerpt.document}
                        date={article.date}
                        readTime={article.readingTime}
                        createdAt={article.createdAt}
                    />
                ))}
            </div>
            <div className="text-center">
                <Link to="/media-pr" className="button mb-[70px]">View more</Link>
            </div>
        </div>
    );
}

export default OtherPrArticles;