import { Link } from 'react-router-dom';
import LazyBackground from '../../utils/LazyBackground';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as ExpandImage } from '../../assets/icons/expand_image.svg';
import { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/cross_icon.svg';

const AboutUsHero = ({ props }) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    };

    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNeighbourhoodPage = location.pathname.includes('/neighbourhood/');
    const heroImages = isMobile && props.mobile_images.length > 0 ? props.mobile_images : props.image;
    const [fullscreenImage, setFullscreenImage] = useState(null);
    let mobileHeight = 0;
    let desktopHeight = 0;

    if(isNeighbourhoodPage) {
        mobileHeight = 362;
        desktopHeight = 905;
    }
    else {
        mobileHeight = 432;
        if(props.big)
            desktopHeight = 650;
        else
            desktopHeight = 432;
    }

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    }

    const closeFullscreen = () => {
        setFullscreenImage(null);
    }
    
    return (
        
            <div className="wide-container relative">
                <Slider {...settings}>
                    {heroImages && heroImages.map((image, index) => (
                        <div key={index} className={`relative h-[${mobileHeight}px] md:h-[${desktopHeight}px] !block`}>
                            <LazyBackground 
                                className={"w-full h-full"}
                                backgroundImage={image.data.url}
                                width={1624}
                                height={desktopHeight}
                                quality={75}
                            />
                            {isNeighbourhoodPage &&
                                <div className="hidden md:block absolute top-6 right-6">
                                    <ExpandImage 
                                        className="cursor-pointer" 
                                        onClick={() => openFullscreen(image.data.url)}
                                    />
                                </div>
                            }
                        </div>
                    ))}
                </Slider>

                {props.action && props.action.label && props.action.url && (
                    <div className="absolute w-full left-0 bottom-[44px] flex justify-center">
                        <Link to={props.action.url} className="button !border-0 bg-white  !px-[61px]">
                            {props.action.label}
                        </Link>
                    </div>
                )}

                {/*{fullscreenImage && (
                    <div className="fixed z-50 top-0 left-0 w-full h-full bg-white flex items-center justify-center" onClick={closeFullscreen}>
                        <img src={fullscreenImage} alt="fullscreen" className="max-w-full max-h-full" />
                        <div className="absolute top-4 right-4">
                            <CloseIcon 
                                onClick={closeFullscreen} 
                                className="cursor-pointer" 
                            />
                        </div>
                    </div>
                )}*/}
            </div>
    );
}

export default AboutUsHero;