import Hamburger from 'hamburger-react';
import { useState, useRef, useEffect } from 'react';

const PropertiesTop = ({ sectionTitle, propertyTypes, propertyTypeTitle, onTabChange, activeTab }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const burgerRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if(menuRef.current && !menuRef.current.contains(event.target) && burgerRef.current && !burgerRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, setMenuOpen]);

    return (
        <div className="properties-top">
            {/* Mobile */}
            {/*
            {propertyTypeTitle && (
                <div className="md:hidden container mt-12 font-normal text-[26px]">
                    {propertyTypeTitle}
                </div>
            )}
            */}
            <div className="container h-20 md:h-[91px] flex items-center justify-between relative">
                {/* Desktop */}
                <div className="properties-top-title hidden md:block text-xl md:text-h4 text-[#0A0A0A]">
                    {propertyTypeTitle}
                </div>
                {/* Mobile */}
                {propertyTypes.map((item, index) => (
                    <div key={index} className={`${index !== activeTab ? "hidden" : "block md:hidden"} md:text-h4 font-normal text-[#0A0A0A]`}>
                        {item}
                    </div>
                ))}
                <div className="font-benton text-subtitle uppercase tracking-wide font-normal">
                    {propertyTypes.map((item, index) => (
                        <span 
                            key={index} 
                            className={`text-[15px] hidden md:inline-block cursor-pointer ${index !== activeTab ? "ml-12 text-[#888888]":"text-[#0A0A0A]"} ${index !== 0 ? "ml-12" : ""}`}
                            onClick={() => onTabChange(index)}
                        >
                            {item}
                        </span>
                    ))}
                    <span className="md:hidden cursor-pointer" ref={burgerRef}>
                        <Hamburger size={24} toggled={isMenuOpen} toggle={setMenuOpen} />
                    </span>
                </div>

                {isMenuOpen && 
                    (<div className="md:hidden absolute top-[79px] left-0 w-full h-auto z-20 flex flex-col bg-[#F1F1F1] tracking-[1.2px] text-[11px] leading-[16px] font-benton font-normal uppercase transition-all duration-500" ref={menuRef}>
                        {propertyTypes.map((item, index) => (
                            <span 
                                key={index} 
                                className={`pl-6 py-[15px] text-[15px] inline-block cursor-pointer ${index !== activeTab ? "text-black":"text-white bg-sothebys-blue"}`}
                                onClick={() => { onTabChange(index); setMenuOpen(false); }}
                            >
                                {item}
                            </span>
                        ))}
                    </div>)
                }
            </div>
        </div>
    );
}

export default PropertiesTop;