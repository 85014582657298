import FooterDropdown from '../FooterDropdown';
import { useState } from 'react';

const FooterDimensions = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect}) => {
    const [activeDimension, setActiveDimension] = useState(selectedDimensionOption);

    const handleDimensionClick = (option) => {
        setActiveDimension(option);
        onDimensionOptionSelect(option);
    }

    return (
        <div className="w-full md:w-2/3 xl:w-1/2 text-right flex flex-col md:flex-row flex-wrap items-center justify-end tracking-[0.375px]">
            <div className="w-full md:w-auto flex justify-between md:justify-normal items-center mb-6 md:mb-0">
                <span className="inline-block text-[15px] md:text-[13px] font-medium md:font-normal text-left max-w-[120px] sm:max-w-none mr-2.5">
                    Property dimensions
                </span>
                <div>
                {dimensionOptions.map(option => (
                    <span
                        key={option.value} 
                        className={`py-2.5 px-6 border font-normal border-blacked rounded-md mr-1 text-[11px] cursor-pointer ${selectedDimensionOption.value === option.value ? "bg-sothebys-blue text-white":"bg-white text-black"}`}
                        onClick={() => handleDimensionClick(option)}    
                    >
                        {option.label}
                    </span>
                ))}
                </div>
            </div>
            
            <div className="w-full md:w-auto flex justify-between md:justify-normal items-center mb-5 md:mb-0">
                <span className="ml-0 md:ml-6 mr-3 text-[15px] md:text-[13px] font-medium md:font-normal">
                    Currency
                </span>
                <div className="w-[76px]">
                    <FooterDropdown options={currencyOptions} selectedOption={selectedCurrencyOption} onSelect={onCurrencyOptionSelect} />
                </div>
                
            </div>
        </div>
    );
}

export default FooterDimensions;