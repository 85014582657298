import NeighbourhoodCard from '../Neighbourhood/NeighbourhoodCard';
import ActionButton from '../ContentBlocks/ActionButton';

const Neighbourhoods = ({ props }) => {
    let neighbourhoods = [];
    props.neighbourhoods.map(neighbourhood => {
        neighbourhoods.push(neighbourhood.data);
    });
    
    return neighbourhoods ? (
        <div className="container text-center">
            <h2 className="text-[#0A0A0A] font-normal md:font-light text-xl text-[22px] md:text-h4 mb-6 md:mb-8 mt-7 md:mt-9">Neighbourhoods</h2>
            <div className="grid grid-cols sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {neighbourhoods.map(neighbourhood => (
                    <div key={neighbourhood.id}>
                        <NeighbourhoodCard neighbourhood={neighbourhood} />
                    </div>
                ))}
            </div>
            
            { props && props.action.url && props.action.label &&
                <ActionButton url={props.action.url} label={props.action.label} />
            }
        </div>
    ) : "";
}

export default Neighbourhoods;