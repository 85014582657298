import { useHistory } from 'react-router-dom';

const useBackButton = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return goBack;
};

export default useBackButton;