import { Link } from 'react-router-dom';
import PropertyCard from './PropertyCard';
import { useMediaQuery } from 'react-responsive';

const SimilarProperties = ({properties, showTitle, viewMoreButton}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
//max-w-[335px] md:max-w-[450px]
    return (
        <div className="similar-properties container mt-[40px] lg:mt-[70px] border border-white">
            {showTitle &&
                <div className="mt-7 lg:mt-[50px] mb-10 lg:mb-[50px] text-center text-[20px] lg:text-2xl">
                    Similar properties
                </div>
            }
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-[43px] gap-y-5 lg:gap-y-[50px] place-items-center">
                {
                    properties.slice(0, 3).map((property, index) => (
                        <div key={index} className="max-w-[100%]">
                            <PropertyCard property={property} />
                        </div>
                    ))
                }
            </div>
            { viewMoreButton &&
                <div className="mt-[30px] lg:mt-[50px] text-center">
                    <Link to="/search" className="button inline-block">View more</Link>
                </div>
            }
        </div>
    );
}

export default SimilarProperties;