import { ReactComponent as FilledStar } from "../../assets/icons/filled_star.svg";
import { ReactComponent as UnfilledStar } from "../../assets/icons/unfilled_star.svg";
import { ReactComponent as SliderPrev } from "../../assets/icons/slider_prev_white.svg";
import { ReactComponent as SliderNext } from "../../assets/icons/slider_next_white.svg";
import { useState, useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import LazyBackground from "../../utils/LazyBackground";
import { getCurrencySymbol, formatPriceOutput } from "../../utils/currency";

const PropertyCard = ({ property }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRefs = useRef([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isFavorited, setIsFavorited] = useState(property.isFavorited);
  const currencySymbol = getCurrencySymbol(property.currency_iso_code);
  const formattedPrice = formatPriceOutput(property.listingprice);

  const toggleFavorite = () => {
    setIsFavorited(!isFavorited);
  };

  const handlePrevClick = () => (event) => {
    sliderRefs.current.slickPrev();
  };

  const handleNextClick = () => (event) => {
    sliderRefs.current.slickNext();
  };

  return (
    <div className="property-card-container">
      <div className="relative small-slider-container">
        <Slider {...settings} ref={(el) => (sliderRefs.current = el)}>
          {property.media.map((image, index) => (
            <div key={index} className="!block">
              <LazyBackground
                className={
                  "property-card-image bg-cover bg-no-repeat bg-center property-image-mobile mx-auto"
                }
                backgroundImage={image}
                width={695}
                height={438}
              />
            </div>
          ))}
        </Slider>

        {property?.tags?.length > 0 && (
          <div className="absolute tracking-wider uppercase bg-sothebys-blue font-benton text-white font-bold text-[13px] leading-[14px] bottom-0 left-0 z-10 px-[13px] py-3">
            {property.tags[0].name}
          </div>
        )}

        <button
          className="absolute top-1.5 md:top-4 right-1.5 md:right-4 bg-transparent text-white hover:text-red-600 transition-colors duration-300"
          onClick={toggleFavorite}
        >
          {isFavorited ? (
            <FilledStar className="block absolute z-99 top-0 right-0 cursor-pointer z-10" />
          ) : (
            <UnfilledStar className="block absolute top-0 right-0 cursor-pointer z-10" />
          )}
        </button>

        <div className="absolute top-1/2 -translate-y-1/2 w-full z-10 flex justify-between">
          <div
            className="small-slider-prev  ml-2.5 md:ml-4 cursor-pointer opacity-0 transition-opacity duration-300"
            onClick={handlePrevClick()}
          >
            <SliderPrev width={isMobile ? 12 : 16} height={isMobile ? 20 : 26} />
          </div>
          <div
            className="small-slider-next mr-2.5 md:mr-4 cursor-pointer opacity-0 transition-opacity duration-300"
            onClick={handleNextClick()}
          >
            <SliderNext width={isMobile ? 12 : 16} height={isMobile ? 20 : 26} />
          </div>
        </div>

        <Link
          className="absolute top-0 bottom-0 left-0 right-0 z-0"
          to={`/property/${property.idField}`}
        />
      </div>

      <Link to={`/property/${property.idField}`}>
        <div className="flex justify-center md:justify-between mt-2.5">
          <span className="text-base leading-5 font-normal">
            {property.community_propertyfinder}, {property.city_propertyfinder}{" "}
            {property.postalcode}
          </span>
          <span className="hidden md:block font-benton text-sm leading-5 font-normal">
            {currencySymbol}
            {formattedPrice}
          </span>
        </div>
        <div className="flex justify-between mt-[2.5px]">
          <div className="hidden md:block text-base leading-5 font-normal">
            {property.propertytype !== "None" ? property.propertytype : ""}
          </div>
          <div className="flex gap-5 font-benton text-sm leading-5 font-normal justify-center md:justify-end w-full md:w-auto">
            <span>{property.bedrooms} Bed</span>
            <span>{property.fullbathrooms} Bath</span>
            <span>{parseFloat(property.totalarea)}sqft</span>
            <span className="md:hidden">
              {currencySymbol}
              {formattedPrice}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PropertyCard;
