import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropertyCard from "../Property/PropertyCard";

const SavedProperties = ({ properties }) => {
    return properties.length > 0 ? (
        <div>
            {properties?.length > 0 && (
                    <div className="grid gap-5 md:gap-12 grid-cols md:grid-cols-2">
                    {properties.map((property, index) => (
                        <div key={index}>
                            <PropertyCard key={index} property={property} />
                        </div>
                    ))}
                    </div>
                )
            }
            <div className="mt-[50px] pb-[50px] flex justify-center">
                <Link to="#" className="button inline-block">
                    View all saved properties
                </Link>
            </div>
            
        </div>
    ) : "<div>There are no properties to show</div>";
};

export default SavedProperties;