import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as DropdownTriangle } from '../../assets/icons/dropdown_triangle.svg';

const DropdownArrow = ({ activeDropdown, id }) => {
    return (
        <span className={`ml-2 self-center arrow absolute right-[8px] top-1/2  ${
            activeDropdown === id ? 'transform rotate-180' : ''
        } transform -translate-y-1/2`}>
            <ArrowDown />
        </span>
    );
};

const HomeSearchInputSelect = ({
    id,
    placeholder,
    options,
    optionType = false,
    useDropdownArrow = false,
    onSelectionChange,
    activeDropdown,
    onToggle,
    onOptionsUpdate,
    defaultValue,
    onFocus,
    onBlur
}) => {
    const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState(() => {
        if (defaultValue) {
            return [defaultValue];
        }
        return [];
    });
    const [selectedTitles, setSelectedTitles] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const openingElementRef = useRef(null);
    const filterListElementRef = useRef(null);

    const handleOptionSelect = (option) => {
        let newSelectedOptions = selectedOptions;
        let newSelectedTitles = selectedTitles;
        
        if (optionType === 'multipleSelect') {
            if (option.id === 'all') {
                const otherOptions = options.map(opt => opt.id).filter(val => val !== 'all');
    
                if (otherOptions.every(val => selectedOptions.includes(val))) {
                    newSelectedOptions = [];
                    newSelectedTitles = [];
                } else {
                    newSelectedOptions = otherOptions;
                    newSelectedTitles = options.filter(opt => otherOptions.includes(opt.id)).map(opt => opt.title);
                }
            } else {
                if (selectedOptions.includes(option.id)) {
                    newSelectedOptions = selectedOptions.filter(id => id !== option.id);
                    newSelectedTitles = selectedTitles.filter(title => title !== option.title);
                } else {
                    newSelectedOptions = [...selectedOptions, option.id];
                    newSelectedTitles = [...selectedTitles, option.title];
                }
            }
        } else if (optionType === 'select') {
            newSelectedOptions = [option.id];
            newSelectedTitles = [option.title];
            onToggle(null);
        }
    
        setSelectedOptions(newSelectedOptions);
        setSelectedTitles(newSelectedTitles);
        setInputValue('');
        setFilteredOptions(options);
        openingElementRef.current.focus();
        
        if(newSelectedTitles.length > 0)
            setInputPlaceholder(newSelectedTitles.length > 1 ? (newSelectedTitles[0] + " + " + (newSelectedTitles.length-1) + ((newSelectedTitles.length-1) === 1 ? " location" : " locations")).toUpperCase() : newSelectedTitles[0].toUpperCase());
        else
            setInputPlaceholder(placeholder);
    
        if (typeof onSelectionChange === "function") {
            onSelectionChange(newSelectedTitles);
        }
    
        onOptionsUpdate(newSelectedOptions);
    };    

    const toggleFilterList = (event) => {
        event.stopPropagation();

        if(activeDropdown === id)
            return;

        onToggle(id);
    };

    const handleClickOutside = (event) => {
        if (
            openingElementRef.current &&
            !openingElementRef.current.contains(event.target) &&
            (!filterListElementRef.current ||
                !filterListElementRef.current.contains(event.target))
        ) {
            onToggle(null);
        }
    };

    const handleFilterChange = (event) => {
        const filterText = event.target.value.toLowerCase();
        setInputValue(filterText);
        setFilteredOptions(options.filter(option =>
            option.title.toLowerCase().includes(filterText)
        ));
    };

    useEffect(() => {
        if (activeDropdown === id) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('touchstart', handleClickOutside);
            };
        }
    }, [activeDropdown, id]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);
    
    return (
        <div className={`SearchFilterOptions bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full md:relative ${activeDropdown === id ? 'transition duration-300 ease-in-out' : ''}`}>
            
            <span className="flex justify-between md:relative">
                <input type="text" 
                    className="w-full h-[46px] bg-[#F1F1F1] placeholder:font-benton font-benton text-[13px] placeholder:text-[13px] leading-4 font-normal  focus:border-0 md:focus:border focus:border-black focus:outline-none pl-0 md:pl-[26px] text-sothebys-blue placeholder:text-sothebys-blue"
                    placeholder={inputPlaceholder} 
                    onChange={handleFilterChange}
                    ref={openingElementRef}
                    onClick={toggleFilterList}
                    value={inputValue}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {useDropdownArrow && (
                    <DropdownArrow 
                        activeDropdown={activeDropdown} 
                        id={id}
                    />
                )}
            </span>

            {activeDropdown === id && (
                <div>
                    <DropdownTriangle className="absolute z-50 top-[52px] left-1/2 transform -translate-x-1/2" />
                    <div className={`absolute z-50 top-[60px] bg-white flex flex-col w-full rounded-md max-h-[160px] custom-scrollbar overflow-y-scroll left-1/2 transform -translate-x-1/2`} ref={filterListElementRef}>
                        {filteredOptions.map((option) => (
                            <div
                                className={`py-2 pl-[26px] hover:bg-dark-blue hover:text-white cursor-pointer ${
                                    selectedOptions.includes(option.id)
                                        ? 'bg-dark-blue text-white'
                                        : ''
                                }`}
                                value={option.value}
                                key={option.id}
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option.title}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomeSearchInputSelect;
