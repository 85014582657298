import NeighbourhoodsListingCard from "./NeighbourhoodsListingCard";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';

const NeighbourhoodsListing = ({ neighbourhoods }) => {
    const isWideScreen = useMediaQuery({ query: '(min-width: 1536px)' });
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1535px)' });
    const [typesGrid, setTypesGrid] = useState([]);
    
    useEffect(() => {
        if(isWideScreen)
            setTypesGrid(["heading", "double", "double", "double", "double", "heading"]);

        if(isMediumScreen)
            setTypesGrid(["double", "double", "double", "double", "double", "double", "regular"]);
    }, [isWideScreen, isMediumScreen]);

    return (
        <>
            <div className="flex md:gap-x-[30px] gap-y-0 flex-wrap justify-center md:justify-start">
                {neighbourhoods.map((neighbourhood, index) => (
                    <NeighbourhoodsListingCard key={neighbourhood.id}
                        id={neighbourhood.id}
                        type={typesGrid[index]}
                        page={"listing"}
                        photo={neighbourhood.listing_images.length > 0 ? neighbourhood.listing_images[0].url : ""} 
                        photo2={neighbourhood.listing_images.length > 1 ? neighbourhood.listing_images[1].url : false}
                        category={neighbourhood.region}
                        title={neighbourhood.neighbourhood_title}
                        excerpt={neighbourhood.excerpt.document}
                        slug={neighbourhood.slug}
                    />
                ))}
            </div>
            {/*<div className="my-[83px] flex justify-center">
                <span className="button !mx-[30px]">Load more</span>
            </div>*/}
        </>
    );
}

export default NeighbourhoodsListing;