import { ReactComponent as SliderPrevArrow } from '../../assets/icons/slider_prev_black.svg';
import { ReactComponent as SliderNextArrow } from '../../assets/icons/slider_next_black.svg';
import { ReactComponent as FilledStar } from '../../assets/icons/filled_star.svg';
import { ReactComponent as UnfilledStar } from '../../assets/icons/unfilled_star.svg';
import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import LazyBackground from '../../utils/LazyBackground';
import { useMediaQuery } from 'react-responsive';

const PropertiesBigSlider = ({ property }) => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const sliderRef = useRef(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const [propertyState, setPropertyState] = useState(null);

    useEffect(() => {
        if(property) {
            setPropertyState(property);
        }
    }, [property]);

    const toggleFavorite = () => {
        setPropertyState({ ...propertyState, isFavorited: !propertyState.isFavorited });
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    }

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    }

    return (
        <div className="wide-container mx-auto mb-[30px] md:mb-[50px]">
            {propertyState && (
                <>
                    <Slider {...settings} ref={sliderRef}>
                        {propertyState.media.map((image, index) => (
                            <div key={index}  className="wide-container relative !block">
                                <Link to={`/property/${property.idField}`}>
                                    <LazyBackground
                                    className={"wide-container bg-cover bg-no-repeat bg-center"}
                                    backgroundImage={image}
                                    width={isMobile ? 768 : 1624}
                                    height={isMobile ? 353 : 657}
                                    />
                                </Link>
                                
                                <button
                                    className="absolute top-1.5 md:top-4 right-1.5 md:right-4 bg-transparent text-white hover:text-red-600 transition-colors duration-300"
                                    onClick={() => toggleFavorite(property.id)}
                                >
                                {property.isFavorited ? <FilledStar className="block absolute z-99 top-0 right-0 cursor-pointer z-10" /> : <UnfilledStar className="block absolute top-0 right-0 cursor-pointer z-10" />}
                                </button>
                            </div>
                        ))}
                    </Slider>

                    <div className="wide-container flex items-center justify-around">
                        <div className="flex item-center justify-center ml-auto md:ml-0">
                            <SliderPrevArrow className="self-center cursor-pointer mt-1" onClick={handlePrevClick} />
                        </div>

                        <div className="container flex justify-around md:justify-between flex-col md:flex-row item-center no-margin font-normal">
                            <div className="self-center md:self-start text-center md:text-left">
                                <p className="text-[13px] md:text-[15px] pt-2 md:pt-3.5 font-benton tracking-[0.38px] font-normal mb-0">
                                    Featured property
                                </p>
                                <p className="text-2xl md:text-h2 mt-2 font-acta font-normal leading-[28px] md:leading-[40px] mb-0">
                                    { property.listing_title }
                                </p>
                                <p className="text-[15px] font-normal font-benton mt-1 tracking-[0.38px] mb-0">
                                    {property.city_propertyfinder}, {property.community_propertyfinder}, { property.postalcode }
                                </p>
                            </div>
                            <div className="mt-3.5 md:mt-0 hidden md:flex item-center justify-center md:justify-end md:max-w-[333px] md:w-full">
                                <Link to={`/property/${property.idField}`} className="button self-center ">View property</Link>
                            </div>
                        </div>

                        <div className="flex item-center mr-auto md:mr-0">
                            <SliderNextArrow className="self-center cursor-pointer mt-1" onClick={handleNextClick} />
                        </div>
                    </div>
                    <div className="container md:hidden">
                        <div className="mt-3.5 md:mt-0 flex item-center justify-center md:justify-end md:max-w-[333px]">
                            <Link to={`/property/${property.idField}`} className="button self-center w-full text-center">View property</Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default PropertiesBigSlider;