import TitleBlock from "../components/ContentBlocks/TitleBlock";
import Tabs from "../components/ContentBlocks/Tabs";
import GapBlock from "../components/ContentBlocks/GapBlock";
import ImageMosaic from "../components/ContentBlocks/ImageMosaic";
import TextWithImage from "../components/ContentBlocks/TextWithImage";
import FeaturedProperties from "../components/Home/FeaturedProperties";
import HomeTitle from "../components/Home/HomeTitle";
import Journal from "../components/Home/Journal";
import Neighbourhoods from "../components/Home/Neighbourhoods";
import Team from "../components/Home/Team";
import JournalHeader from "../components/Journal/JournalHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ContentHorizontalLine from "../components/ContentBlocks/ContentHorizontalLine";
import ImageSlider from "../components/ContentBlocks/ImageSlider";
import SimpleTextBlock from "../components/ContentBlocks/SimpleTextBlock";
import AboutUsHero from "../components/AboutUs/AboutUsHero";
import Testimonials from "../components/ContentBlocks/Testimonials";
import AboutUsOurOffices from "../components/AboutUs/AboutUsOurOffices";
import SellStatistics4Columns from "../components/SellWithUs/SellStatistics4Columns";
import SellStatistics2Columns from "../components/SellWithUs/SellStatistics2Columns";
import VideoBlock from "../components/ContentBlocks/VideoBlock";
import MediaPrContentBlock from "../components/ContentBlocks/MediaPrContentBlock";
import ContactForm from "../components/ContactUs/ContactForm";

const useComponentMapping = () => {
    const location = useLocation();
    const isJournalPage = location.pathname.includes('/journal/');
    const isMediaArticlePage = location.pathname.includes('/pr-article/');
    const isPartnerPage = location.pathname.includes('/partner/');
    const isHomePage = location.pathname === "/" ? true : false;
    
    const components = {
        hero: isJournalPage || isMediaArticlePage || isPartnerPage ? JournalHeader : "",
        titleBlock: isHomePage ? HomeTitle : TitleBlock,
        neighbourhoods: Neighbourhoods,
        featuredProperties: FeaturedProperties,
        team: Team,
        journal: Journal,
        textBlock: TextWithImage,
        imageMosaic: ImageMosaic,
        gap: GapBlock,
        textBlockSlider: Tabs,
        line: ContentHorizontalLine,
        imageSlider: ImageSlider,
        simpleTextBlock: SimpleTextBlock,
        aboutHero: AboutUsHero,
        testimonials: Testimonials,
        //contactUs: AboutUsOurOffices,
        contactUs: ContactForm,
        statsFourColumns: SellStatistics4Columns,
        stats: SellStatistics2Columns,
        video: VideoBlock,
        mediaPr: MediaPrContentBlock
    };

    return components;
};

export default useComponentMapping;