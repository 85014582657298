const FeaturesAmenities = ({ property }) => {
    const privateAmenities = property.private_amenities.split(";");
    const commercialAmenities = property.commercial_amenities.split(";");
    //const amenities = [...privateAmenities, ...commercialAmenities];
    const amenities = privateAmenities;
    
    return amenities.length > 0 ? (
        <div className="container">
            <div className="mt-12 lg:my-12 pl-4 md:pl-12 md:pt-9 pb-4 md:pb-12 bg-[#F5F6F6] border border-transparent">
                <div className="hidden lg:block">
                    <span className="text-[32px] leading-[38px] tracking-wide font-normal">Features & Amenities</span>
                </div>
                <div className="lg:hidden mt-5">
                    <span className="text-[20px] leading-[38px] tracking-wide font-normal">Features & Amenities</span>
                </div>
                <div className="flex flex-col leading-[31px] mt-1 md:mt-6 font-benton font-normal text-base leading-9 md:grid md:grid-cols-5">
                    {amenities.map((item, index) => (
                        <span key={index}>
                            {item}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    ) : <div className="mt-12"></div>;
}

export default FeaturesAmenities;