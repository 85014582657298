import { useState } from 'react';
import { searchOptions } from '../database';

const MobileSearchCategories = () => {
    const [activeCategory, setActiveCategory] = useState('Sale');

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    }

    return (
        <div className="container flex justify-center gap-5 mx-auto md:hidden my-4">
            {searchOptions.listingTypes.map(listingType => (
                <div
                    key={listingType.id}
                    className={`mobile-header-category rounded-full ${activeCategory === listingType.value ? 'active': ''}`} 
                    onClick={() => handleCategoryClick(listingType.value)}>
                    {listingType.title}
                </div>
            ))}
        </div>
    );
}

export default MobileSearchCategories;