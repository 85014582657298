import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import ScrollTop from './utils/ScrollTop';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Search from './pages/Search';
import Property from './pages/Property';
import NeighbourhoodGuide from './pages/NeighbourhoodGuide';
import Register from './pages/Register';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import CheckEmail from './pages/CheckEmail';
import VerifyEmail from './pages/VerifyEmail';
import EmailVerified from './pages/EmailVerified';
import Dashboard from './pages/Dashboard';
import ContactUs from './pages/ContactUs';
import TeamMember from './pages/TeamMember';
import GDPRMessage from './components/Footer/GDPRMessage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import client from './utils/client';
import { ApolloProvider } from '@apollo/client';
import Journals from './pages/Journals';
import Journal from './pages/Journal';
import AboutUs from './pages/AboutUs';
import SellWithUs from './pages/SellWithUs';
import Neighbourhoods from './pages/Niehgbourhoods';
import MediaPR from './pages/MediaPR';
import Partners from './pages/Partners';
import Team from './pages/Team';
import PrArticle from './pages/PrArticle';
import TermsOfUse from './pages/TermsOfUse';
import { useGetSettings } from './queries/Menus';
import { HelmetProvider } from 'react-helmet-async';
import Partner from './pages/Partner';
import Landing from './pages/Landing';

function App() {
  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <ChildComponent />
      </HelmetProvider>
    </ApolloProvider>
  )
}

function ChildComponent() {
  const getSettings = useGetSettings();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [settings, setSettings] = useState({});

  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(null);

  const dimensionOptions = [
      { value: 'sqft', label: 'SQ. FT.' },
      { value: 'sqm', label: 'SQ. M.' },
  ];
    
  const [selectedDimensionOption, setSelectedDimensionOption] = useState(dimensionOptions[0]);
    
  const handleCurrencyOptionSelect = (option) => {
      setSelectedCurrencyOption(option);
  };

  const handleDimensionOptionSelect = (option) => {
      setSelectedDimensionOption(option);
  };

  useEffect(() => {
    if(getSettings && Object.keys(getSettings).length > 0) {
      //const fetchedSettings = getSettings[0];
      setSettings(getSettings);

      let newCurrencyOptions = [];
      getSettings.currencies?.map(currency => {
        newCurrencyOptions.push({value: currency.iso_code, label: currency.iso_code});
      });

      setCurrencyOptions(newCurrencyOptions);
      setSelectedCurrencyOption(newCurrencyOptions[0]);
    }
  }, [getSettings]);
  
  return (
    <Router>
      <div className="App">
        <Header 
          currencyOptions={currencyOptions}
          selectedCurrencyOption={selectedCurrencyOption}
          onCurrencyOptionSelect={handleCurrencyOptionSelect}
          dimensionOptions={dimensionOptions}
          selectedDimensionOption={selectedDimensionOption}
          onDimensionOptionSelect={handleDimensionOptionSelect}
          settings={settings}
        />
        <div className="content">
          <Switch>
            <Route path="/agent/:slug">
              <ScrollTop />
              <TeamMember settings={settings} />
            </Route>
            <Route path="/landings/:slug*">
              <ScrollTop />
              <Landing settings={settings} />
            </Route>
            <Route path="/contact-us">
              <ScrollTop />
              <ContactUs />
            </Route>
            <Route path="/journal/:slug">
              <ScrollTop />
              <Journal />
            </Route>
            <Route path="/journals/:category?">
              <ScrollTop />
              <Journals />
            </Route>
            <Route path="/partner/:slug">
              <ScrollTop />
              <Partner />
            </Route>
            <Route path="/partners/:category?">
              <ScrollTop />
              <Partners />
            </Route>
            <Route path="/pr-article/:slug">
              <ScrollTop />
              <PrArticle />
            </Route>
            <Route path="/media-pr/:category?">
              <ScrollTop />
              <MediaPR />
            </Route>
            <Route path="/register">
              <ScrollTop />
              <Register settings={settings} />
            </Route>
            <Route path="/login">
              <ScrollTop />
              <Login />
            </Route>
            <Route path="/reset-password">
              <ScrollTop />
              <ResetPassword />
            </Route>
            <Route path="/check-email">
              <ScrollTop />
              <CheckEmail />
            </Route>
            <Route path="/verify-email">
              <ScrollTop />
              <VerifyEmail />
            </Route>
            <Route path="/email-verified">
              <ScrollTop />
              <EmailVerified />
            </Route>
            <Route path="/dashboard">
              <ScrollTop />
              <Dashboard 
                currencyOptions={currencyOptions}
                selectedCurrencyOption={selectedCurrencyOption}
                onCurrencyOptionSelect={handleCurrencyOptionSelect}
                dimensionOptions={dimensionOptions}
                selectedDimensionOption={selectedDimensionOption}
                onDimensionOptionSelect={handleDimensionOptionSelect}
              />
            </Route>
            <Route path="/property/:propertyId">
              <ScrollTop />
              <Property settings={settings} />
            </Route>
            <Route path="/neighbourhoods/:category?">
              <ScrollTop />
              <Neighbourhoods />
            </Route>
            <Route path="/neighbourhood/:neighbourhoodSlug">
              <ScrollTop />
              <NeighbourhoodGuide />
            </Route>
            <Route path="/search">
              <ScrollTop />
              <Search settings={settings} />
            </Route>
            <Route path="/privacy-policy">
              <ScrollTop />
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-of-use">
              <ScrollTop />
              <TermsOfUse />
            </Route>
            <Route path="/about-us">
              <ScrollTop />
              <AboutUs />
            </Route>
            <Route path="/sell-with-us">
              <ScrollTop />
              <SellWithUs />
            </Route>
            <Route path="/team">
              <ScrollTop />
              <Team settings={settings} />
            </Route>
            <Route path="/">
              <ScrollTop />
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer 
          currencyOptions={currencyOptions}
          selectedCurrencyOption={selectedCurrencyOption}
          onCurrencyOptionSelect={handleCurrencyOptionSelect}
          dimensionOptions={dimensionOptions}
          selectedDimensionOption={selectedDimensionOption}
          onDimensionOptionSelect={handleDimensionOptionSelect}
          settings={settings}
        />
        <GDPRMessage />
      </div>
    </Router>
  );
}

export default App;