import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as FloorplanIcon } from '../../assets/icons/floorplan.svg';
import { ReactComponent as GalleryIcon } from '../../assets/icons/gallery.svg';

const PropertyPopupLinks = ({ property, openFullscreen, activeImageIndex, openRequestInformation, openFullscreenFloorplan }) => {
    return (
        <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="flex gap-[30px] items-end text-sm font-benton leading-6 tracking-[1.5px] font-normal">
                {property.floorplans && (
                    <div className="capitalize flex hover:opacity-60"
                        onClick={openFullscreenFloorplan}>
                        <Link to="#">
                            <FloorplanIcon className="mr-2.5" />
                        </Link>
                        <Link to="#">
                            Floorplan
                        </Link>
                    </div>
                )}
                <div className="capitalize flex hover:opacity-60" 
                    onClick={() => openFullscreen(activeImageIndex)}>
                    <Link to="#">
                        <GalleryIcon className="mr-2.5" />
                    </Link>
                    <Link to="#">
                        Gallery ({property.media.length})
                    </Link>
                </div>
            </div>

            <div className="flex md:ml-[30px] mt-3 md:mt-0 items-center">
                <Link to="#" 
                    className="button !py-[7px] !px-[20px] !border-[#888888]" 
                    onClick={openRequestInformation}
                >
                    Request information
                </Link>
            </div>
        </div>
    );
};

export default PropertyPopupLinks;