import { useState } from 'react';
import TextWithImage from './TextWithImage';

const Tabs = ({ props }) => {
    const [activeTab, setActiveTab] = useState(0);
    let hasSummary = false;

    props.slides && props.slides.map(tab => {
        if(tab.slideSummary) {
            hasSummary = true;
            return;
        }
    });

    const handleTabClick = (index) => {
        setActiveTab(index);
    }
    
    return (
        <div className="lifestyle tabs" id={props.anchor ? props.anchor : undefined}>
            <div className="container">
                <div className="w-full md:w-1/2 mx-auto mt-16">
                    {props.title && <div className="text-4xl text-[#1D1D1B] text-center">{props.title}</div>}

                    <div className="my-8 font-[#1D1D1B] font-normal text-xl leading-10 flex justify-between md:justify-center items-center md:gap-[70px]">
                        { props.slides && props.slides.map((tab, index) => (
                            <span 
                                key={index} 
                                data-title={tab.slideTitle}
                                className={`cursor-pointer tab ${index === activeTab ? "active" : "inactive"}`}
                                onClick={() => handleTabClick(index)}    
                            >
                                {tab.slideTitle}
                            </span>
                        ))}
                    </div>

                    {hasSummary && (
                        <div className="mb-[40px]">
                            { props.slides && props.slides.map((tab, index) => (
                                <div className={`tab-content font-benton font-normal text-[#0A0A0A] text-xl tracking-[0.375px] text-center hidden md:block ${index === activeTab ? "active" : "inactive"}`} key={index}>
                                    {tab.slideSummary}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            { props.slides && props.slides.map((tab, index) => (
                <div key={index} className={`tab-content container flex flex-col md:flex-row ${index === activeTab ? "active" : "inactive"}`}>
                    <TextWithImage
                        props={tab}
                    />
                </div>
            ))}
        </div>
    );
}

export default Tabs;