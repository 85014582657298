import { useState, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/cross_icon.svg';
import { getCloudflareImageUrl } from '../../utils/getCloudflareUrl';
import { Link } from 'react-router-dom';
import LazyBackground from '../../utils/LazyBackground';
import { ReactComponent as AgentPhone } from '../../assets/icons/property_agent_phone.svg';
import { ReactComponent as AgentWhatsapp } from '../../assets/icons/property_agent_whatsapp.svg';
import { ReactComponent as AgentEmail } from '../../assets/icons/property_agent_email.svg';
import GalleryPortal from '../../utils/GalleryPortal';
import { useMediaQuery } from 'react-responsive';
import useSticky from '../../utils/useSticky';
import { useLocation } from "react-router-dom";
import getGoogleDriveFileLink from '../../utils/getGoogleDriveFileLink';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

const RequestInformation = ({ active = false, agent, onClose, phone, whatsapp }) => {
    const [requestInformationActive, setRequestInformationActive] = useState(active);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isSticky = useSticky(isMobile || isTablet ? 0 : 30);
    const location = useLocation();
    const pathParts = location.pathname.split("/").filter(Boolean);
    let topPaddingSticked = 0;
    let topPaddingUnsticked = 0;
    const languages = agent && agent.languages && agent.languages.split(",");

    if(pathParts[0] === "agent") {
        topPaddingSticked = 72;
        topPaddingUnsticked = 104;
    }
    else if(pathParts[0] === "property") {
        topPaddingSticked = 110;
        topPaddingUnsticked = -1;
    }

    useEffect(() => {
        setRequestInformationActive(active);

        if(active && (isMobile || isTablet))
            scrollToTop();
    }, [active]);

    const closeRequestInformation = () => {
        if(onClose)
            onClose();
    }
    
    return (
        <>
        {isMobile || isTablet ? (
            requestInformationActive && (
                <GalleryPortal>
                    {/* Mobile Version */}
                    <div className="absolute inset-0 z-[1000] top-0 left-0 bottom-0 w-full h-fit bg-white flex flex-col items-center justify-center">

                    <div className="request-information wide-container bg-white">
                        <div className="relative">
                            <div className="h-[114px] text-[24px] flex justify-center items-center">Request information</div>
                            <div 
                                className="absolute top-1/2 right-[32px] -translate-y-1/2 cursor-pointer"
                                onClick={closeRequestInformation}    
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="container w-full flex justify-end font-benton font-normal text-[15px]">
                                <div className="w-full max-w-[461px] text-[#2A2A2A]">
                                    <input 
                                        type="text" 
                                        className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                        placeholder="Full name" 
                                    />
                                    <input 
                                        type="email" 
                                        className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                        placeholder="E-mail address" 
                                    />
                                    <input 
                                        type="tel" 
                                        className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                        placeholder="+44" 
                                    />
                                    <textarea 
                                        className="request-information-field w-full h-[100px] rounded mb-8 md:mb-4 pl-3 pt-4 resize-none"
                                        placeholder="How can we help?" 
                                    ></textarea>

                                    <Link to="#">
                                        <span className="button !px-7 !border-[#888888]">
                                            Submit enquiry
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full flex flex-col mt-[63px]">
                                <div className="relative h-[480px]">
                                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[335px] lg:-auto">
                                        <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`}>
                                            <LazyBackground
                                                className={"bg-cover bg-no-repeat bg-center h-[480px] lg:-auto mb-4"}
                                                backgroundImage={getGoogleDriveFileLink(agent.photo)}
                                                width={335}
                                                height={480}
                                            />
                                        </Link>
                                    </div>
                                    <div className="h-1/2">

                                    </div>
                                    <div className="h-1/2 bg-sothebys-blue">

                                    </div>
                                </div>
                                
                                <div className="text-[24px] leading-[35px] w-full bg-sothebys-blue text-white pb-4 lg:pb-0">
                                    <div className="flex flex-col items-center">
                                        <div className="mt-[58px] text-[24px] leading-[35px] text-center">
                                            Need immediate <br />assistance?
                                        </div>
                                        <div className="inline-block mt-4 font-normal font-benton uppercase tracking-[1.1px] text-[11px] leading-[16px]h-[28px] border border-t-0 border-x-0 border-white">
                                            {agent.position}
                                        </div>
                                        <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`}>
                                            <div className="text-xl mt-3">
                                                {agent.firstname} {agent.lastname}
                                            </div>
                                        </Link>
                                        <div className="mt-3 flex gap-3">
                                            {agent.email &&
                                                <a href={`mailto:${agent.email}`} className="hover:opacity-80">
                                                    <AgentEmail />
                                                </a>
                                            }
                                            {whatsapp &&
                                                <a href={`https://wa.me/${whatsapp}`}>
                                                    <AgentWhatsapp />
                                                </a>
                                            }
                                            {phone &&
                                                <a href={`tel:${phone}`}>
                                                    <AgentPhone />
                                                </a>
                                            }
                                        </div>
                                        {languages.length > 0 &&
                                            <div className="mt-3 mb-2 font-benton text-[13px]">
                                                {languages.map((language, index) => (
                                                    <span key={index} className={`${index > 0 ? "border border-t-0 border-b-0 border-r-0 border-white px-2.5" : "pr-2.5"}`}>{language}</span>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </GalleryPortal>
                )
            ) : (
                requestInformationActive && (
                /* Desktop Version */
                <div className={`request-information wide-container bg-white ${isSticky ? "fixed" : "absolute"} left-1/2 -translate-x-1/2 z-[200] w-full`} style={{ top: `${isSticky ? topPaddingSticked : topPaddingUnsticked}px`}}>
                    <div className="relative">
                        <div className="h-[135px] text-[24px] flex justify-center items-center">Request information</div>
                        <div 
                            className="absolute top-1/2 right-[20px] md:right-[95px] -translate-y-1/2 cursor-pointer"
                            onClick={closeRequestInformation}    
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row mb-[66px]">
                        <div className="container md:no-container w-full md:w-1/2 flex justify-end font-benton font-normal text-[15px]">
                            <div className="md:mr-[63px] w-full max-w-[461px] text-[#2A2A2A]">
                                <input 
                                    type="text" 
                                    className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                    placeholder="Full name" 
                                />
                                <input 
                                    type="email" 
                                    className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                    placeholder="E-mail address" 
                                />
                                <input 
                                    type="tel" 
                                    className="request-information-field w-full h-[58px] rounded mb-4 pl-3"
                                    placeholder="+44" 
                                />
                                <textarea 
                                    className="request-information-field w-full h-[100px] rounded mb-8 md:mb-4 pl-3 pt-4 resize-none"
                                    placeholder="How can we help?" 
                                ></textarea>

                                <Link to="#">
                                    <span className="button !px-7 !border-[#888888]">
                                        Submit enquiry
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 flex h-[321px]">
                            <div className="w-[227px]">
                                <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`}>
                                    <LazyBackground
                                        className={"bg-cover bg-no-repeat bg-center h-full lg:-auto mb-4"}
                                        backgroundImage={getGoogleDriveFileLink(agent.photo)}
                                        width={227}
                                        height={321}
                                        quality={75}
                                    />
                                </Link>
                            </div>
                            
                            <div className="flex flex-col text-[24px] leading-[35px] w-full max-w-[385px] bg-sothebys-blue text-white">
                                <div className="ml-9">
                                    <div className="mt-[58px] text-[24px] leading-[35px]">
                                        Need immediate <br />assistance?
                                    </div>
                                    <div className="inline-block mt-3.5 font-normal font-benton uppercase tracking-[1.1px] text-[11px] leading-[16px]h-[28px] border border-t-0 border-x-0 border-white">
                                        {agent.position}
                                    </div>
                                    <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`}>
                                        <div className="text-xl mt-3">
                                            {agent.firstname} {agent.lastname}
                                        </div>
                                    </Link>
                                    <div className="mt-3 flex gap-3">
                                        {agent.email &&
                                            <a href={`mailto:${agent.email}`}>
                                                <AgentEmail />
                                            </a>
                                        }
                                        {whatsapp &&
                                            <a href={`https://wa.me/${whatsapp}`}>
                                                <AgentWhatsapp />
                                            </a>
                                        }
                                        {phone &&
                                            <a href={`tel:${phone}`}>
                                                <AgentPhone />
                                            </a>
                                        }
                                    </div>
                                    {languages.length > 0 &&
                                        <div className="mt-3 font-benton text-[13px]">
                                            {languages.map((language, index) => (
                                                <span key={index} className={`${index > 0 ? "border border-t-0 border-b-0 border-r-0 border-white px-2.5" : "pr-2.5"}`}>{language}</span>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            )}
        </>
    );
}

export default RequestInformation;