import { useState, useRef, useEffect } from 'react';
import PriceSlider from './PriceSlider';
import AreaSlider from './AreaSlider';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { mergeArrays } from '../../utils/mergeArrays';
import SearchSelect from './SearchSelect';
import SearchInputSelect from './SearchInputSelect';
import SearchInput from './SearchInput';
import SearchSelectMulticolumn from './SearchSelectMulticolumn';
import SearchSelectMulticolumnToggle from './SearchSelectMulticolumnToggle';
import useFetch from "../../utils/useFetch";
import { searchOptions, beds, baths, featuresAmenities } from "../database";
import { useGetListingStats, useGetLocations } from '../../queries/Properties';
import transformListingStatsResponse from '../../utils/transformListingStatsResponse';

const SearchOptions = (props) => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const locations = useGetLocations();
    const [listingType, setlistingType] = useState("Sale");
    const getListingStats = useGetListingStats();
    const [selectedOptionsLoading, setSelectedOptionsLoading] = useState(true);
    const [isUrlParamsRead, setIsUrlParamsRead] = useState(false);
    
    const [ranges, setRanges] = useState({
        Rent: { price: {min: 0, max: 99999999}, area:{min:0, max:999999999}},
        Sale: { price: {min: 0, max: 99999999}, area:{min:0, max:999999999}}
    });
    const [currentRanges, setCurrentRanges] = useState(null);
    const [initialMinPrice, setInitialMinPrice] = useState(ranges[listingType].price.min);
    const [initialMaxPrice, setInitialMaxPrice] = useState(ranges[listingType].price.max);
    const [initialMinArea, setInitialMinArea] = useState(ranges[listingType].area.min);
    const [initialMaxArea, setInitialMaxArea] = useState(ranges[listingType].area.max);
    const [minPrice, setMinPrice] = useState(initialMinPrice);
    const [maxPrice, setMaxPrice] = useState(initialMaxPrice);
    const [minArea, setMinArea] = useState(initialMinArea);
    const [maxArea, setMaxArea] = useState(initialMaxArea);
    const [price, setPrice] = useState(ranges[listingType].price);
    const [area, setArea] = useState(ranges[listingType].area);

    const [community, setCommunity] = useState();
    const [minBeds, setMinBeds] = useState(0);
    const [minBaths, setMinBaths] = useState(0);
    const [reference, setReference] = useState("");
    const containerRef = useRef(null);
    const [propertyCount, setPropertyCount] = useState(0);
    const [shouldFetchProperties, setShouldFetchProperties] = useState(false);
    const [properties, setProperties] = useState([]);
    const [hasMounted, setHasMounted] = useState(false);

    const getRequestBody = () => {
        return {
            ...(community ? { community } : {}),
            listingType,
            price,
            area,
            minBeds,
            minBaths,
            ...(reference ? { reference } : {}),
            perPage: props.perPage,
            offset: props.pageNumber * props.perPage
        };
    };

    /*useEffect(() => {
        if(isUrlParamsRead) {
            const requestBody = {
                ...(community ? { community } : {}),
                listingType,
                price,
                area,
                minBeds,
                minBaths,
                ...(reference ? { reference } : {}),
                perPage: props.perPage,
                offset: props.pageNumber * props.perPage
            };
        }
    }, [isUrlParamsRead]);*/
    

    useEffect(() => {
        if(getListingStats && getListingStats.length > 0)
            setRanges(transformListingStatsResponse(getListingStats));
    }, [getListingStats]);

    useEffect(() => {
        if(ranges && ranges[listingType]) {
            setCurrentRanges(ranges[listingType]);
            setInitialMinPrice(ranges[listingType].price.min);
            setInitialMaxPrice(ranges[listingType].price.max);
            setInitialMinArea(ranges[listingType].area.min);
            setInitialMaxArea(ranges[listingType].area.max);
            setMinPrice(initialMinPrice);
            setMaxPrice(initialMaxPrice);
            setMinArea(initialMinArea);
            setMaxArea(initialMaxArea);
            setPrice({ min: initialMinPrice, max: initialMaxPrice });
            setArea({ min: initialMinArea, max: initialMaxArea });
        }
    }, [ranges]);

    // update page url adding search params on every param change
    useEffect(() => {
        if(hasMounted && isUrlParamsRead) {
            const params = new URLSearchParams();
            const requestBody = getRequestBody();

            for(const key in requestBody) {
                if(requestBody.hasOwnProperty(key) && requestBody[key]) {
                    if(typeof requestBody[key] === "object" && requestBody[key] !== null) {
                        for(const subKey in requestBody[key]) {
                            if(requestBody[key].hasOwnProperty(subKey) && requestBody[key][subKey]) {
                                params.append(`${key}.${subKey}`, requestBody[key][subKey]);
                            }
                        }
                    } else {
                        params.append(key, requestBody[key]);
                    }
                }
            }

            history.push({ search: params.toString() });
        }
    }, [community, listingType, price, area, minBeds, minBaths, reference, props.perPage, props.pageNumber]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        
        if(params.has('community.0')) {
            const communities = Array.from(queryParams.entries())
                .filter(([key]) => key.startsWith("community."))
                .map(([, value]) => value);
            setCommunity(communities);
            setSelectedOptions(communities);
        }
        setSelectedOptionsLoading(false);
        
        if(params.has('listingType'))
            setlistingType(params.get('listingType'));

        if(params.has('price.min'))
            setMinPrice(Number(params.get('price.min')));

        if(params.has('price.max'))
            setMaxPrice(Number(params.get('price.max')));

        if(params.has('area.min'))
            setMinArea(Number(params.get('area.min')));

        if(params.has('area.max'))
            setMaxArea(Number(params.get('area.max')));
        
        setHasMounted(true);
        setIsUrlParamsRead(true);
        // show search results on page load with the search from url params
        fetchProperties(true);
    }, []);

    useEffect(() => {
        setCurrentRanges(ranges[listingType]);
    }, [listingType]);

    useEffect(() => {
        setPrice({ min: minPrice, max: maxPrice });
    }, [minPrice, maxPrice]);

    useEffect(() => {
        setArea({ min: minArea, max: maxArea });
    }, [minArea, maxArea]);

    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

    // Getting Search Results number
    const { data: countData, isLoading, error } = useFetch(
        isUrlParamsRead ? `${apiUrl}/api/search-count` : null, 
        "POST", 
        getRequestBody(),
        [community, listingType, price, area, minBeds, minBaths, reference, isUrlParamsRead]);

    // Getting properties
    const { data: propertiesData, isLoadingProperties, errorProperties } = useFetch(
        shouldFetchProperties ? `${apiUrl}/api/search` : null, 
        "POST", 
        getRequestBody(),
        [community, listingType, price, area, minBeds, minBaths, reference, shouldFetchProperties]);
    
    useEffect(() => {
        if(countData && countData.hasOwnProperty('count'))
            setPropertyCount(countData.count);
    }, [countData]);

    useEffect(() => {
        if(props.pageNumber > 0) {
            fetchProperties();
        }
    }, [props.pageNumber]);

    const fetchProperties = (resetPage = false) => {
        if(resetPage === true)
            props.onPageNumberChange(0);

        setShouldFetchProperties(true);
    }

    useEffect(() => {
        if(propertiesData) {
            setProperties(propertiesData);
            props.onSearchClick(propertiesData);
            props.onPropertyCountChange(propertyCount);
            setShouldFetchProperties(false);
        }
    }, [propertiesData]);

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [showOptionsDiv, setShowOptionsDiv] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [multicolumnOptions, setMulticolumnOptions] = useState([]);
    const toggleRef = useRef(null);
    const [showClearButton, setShowClearButton] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const multiColumnSelectRef = useRef(null);
    const [listingTypes, setlistingTypes] = useState(searchOptions.listingTypes);

    useEffect(() => {
        if (selectedOptions.length > 0 || multicolumnOptions.length > 0 || minPrice !== initialMinPrice || maxPrice !== initialMaxPrice || minArea !== initialMinArea || maxArea !== initialMaxArea) {
            setShowClearButton(true);
        } else {
            setShowClearButton(false);
        }
    }, [multicolumnOptions, minPrice, maxPrice, minArea, maxArea, initialMinPrice, initialMaxPrice, selectedOptions]);

    const resetPrices = () => {
        setMinPrice(initialMinPrice);
        setMaxPrice(initialMaxPrice);
    }

    const resetAreas = () => {
        setMinArea(initialMinArea);
        setMaxArea(initialMaxArea);
    }

    const resetBeds = () => {
        setMinBeds(0);
    }

    const resetBaths = () => {
        setMinBaths(0);
    }

    const handleMulticolumnOptionsUpdate = (updatedOptions) => {
        setMulticolumnOptions(updatedOptions);
    };

    const handleOptionsUpdate = (options) => {
        if(options !== "Sale")
            setSelectedOptions(mergeArrays(selectedOptions, options));
    };

    const handleToggle = (id) => {
        setActiveDropdown((prev) => (prev === id ? null : id));
    }

    const handleToggleOptionsDiv = () => {
        setShowOptionsDiv(!showOptionsDiv);
    }

    const handleClearAllFilters = () => {
        setResetKey((prevKey) => prevKey + 1);
        setSelectedOptions([]);
    
        if (multiColumnSelectRef.current) {
            multiColumnSelectRef.current.clearSelectedOptions();
        }

        setlistingType("Sale");
        resetPrices();
        resetAreas();
        resetBeds();
        resetBaths();
        fetchProperties(true);
    };

    const handleLocationSelectionChange = (selectedLocation) => {
        setCommunity(selectedLocation);
    }

    const handlelistingTypeSelectionChange = (selectedlistingType) => {
        setlistingType(selectedlistingType);
    }

    const handleBedsSelectionChange = (selectedBeds) => {
         setMinBeds(selectedBeds);
    }

    const handleBathsSelectionChange = (selectedBaths) => {
        setMinBaths(selectedBaths);
    }

    const handlePriceRangeChange = (values) => {
        if (values && values.length === 2) {
            setMinPrice(values[0]);
            setMaxPrice(values[1]);
        }

        setPrice({ min: values[0], max: values[1] });
    }

    const handleAreaRangeChange = (values) => {
        if (values && values.length === 2) {
            setMinArea(values[0]);
            setMaxArea(values[1]);
        }

        setArea({ min: values[0], max: values[1] });
    }

    const handleInputChange = (event) => {
        const input = event.target.value;
        setReference(input.replace(/\D/g, ""));
    }

    const handleMulticolumnSelectionChange = (selectedValues) => {
        {/*  Multicolumn Change Processing */ }
    }

    /*useEffect(() => {
        const communityParam = queryParams.get("community");
        const listingTypeParam = queryParams.get("listingType");

        if(communityParam || listingTypeParam) {
            if(communityParam)
                setCommunity(communityParam);
            
            if(listingTypeParam)
                setlistingType(listingTypeParam);

            fetchProperties(true);
        }
    }, []);*/

    useEffect(() => {
        function handleClickOutside(event) {
            if(containerRef.current && !containerRef.current.contains(event.target) && toggleRef.current && !toggleRef.current.contains(event.target)) {
                setShowOptionsDiv(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    return (
        <div className="search-options container flex flex-col md:flex-row">
            <div className="relative flex flex-col gap-[7px] md:flex-row flex-grow">

                <div className="w-full md:w-[22.8%]">
                    {locations && !selectedOptionsLoading && <SearchInputSelect
                        id="filter1"
                        placeholder="LOCATION"
                        changeTitle={true}
                        newTitleFormat="title"
                        options={locations}
                        optionType="multipleSelect"
                        useDropdownArrow={true}
                        initialSelectedOptions={selectedOptions}
                        onSelectionChange={handleLocationSelectionChange}
                        activeDropdown={activeDropdown}
                        onToggle={handleToggle}
                        onOptionsUpdate={handleOptionsUpdate}
                        key={`filter1-${resetKey}`}
                    />}
                </div>

                <div className="w-full md:w-[8.3%]">
                    {listingTypes && isUrlParamsRead &&
                        <SearchSelect
                            id="filter2"
                            title=""
                            changeTitle={true}
                            newTitleFormat="value"
                            options={listingTypes}
                            optionType="select"
                            useDropdownArrow={true}
                            onSelectionChange={handlelistingTypeSelectionChange}
                            activeDropdown={activeDropdown}
                            onToggle={handleToggle}
                            onOptionsUpdate={handleOptionsUpdate}
                            key={`filter1-${resetKey}`}
                            width={11.3}
                            defaultValue={listingType}
                        />
                    }
                </div>

                <div className="w-full md:w-[14.3%]">
                    {currentRanges &&
                        <PriceSlider
                            id="filter3"
                            title="price range"
                            changeTitle={true}
                            minPrice = {minPrice}
                            maxPrice = {maxPrice}
                            useDropdownArrow={true}
                            initialMinPrice={currentRanges.price.min}
                            initialMaxPrice={currentRanges.price.max}
                            onPriceRangeChange={handlePriceRangeChange}
                            activeDropdown={activeDropdown}
                            onToggle={handleToggle}
                            onOptionsUpdate={handleOptionsUpdate}
                            key={`filter1-${resetKey}`}
                        />
                    }
                </div>

                <div className="w-full md:w-[14.3%]">
                    {currentRanges &&
                        <AreaSlider
                            id="filter4"
                            title="size"
                            changeTitle={true}
                            minArea = {minArea}
                            maxArea = {maxArea}
                            useDropdownArrow={true}
                            initialMinArea={currentRanges.area.min}
                            initialMaxArea={currentRanges.area.max}
                            onAreaRangeChange={handleAreaRangeChange}
                            activeDropdown={activeDropdown}
                            onToggle={handleToggle}
                            onOptionsUpdate={handleOptionsUpdate}
                            key={`filter1-${resetKey}`}
                        />
                    }
                </div>

                <div className="w-full md:w-[14.3%]">
                    <SearchSelect
                        id="filter5"
                        title="beds"
                        options={beds}
                        changeTitle={true}
                        newTitleFormat="value+title"
                        optionType="select"
                        useDropdownArrow={true}
                        onSelectionChange={handleBedsSelectionChange}
                        activeDropdown={activeDropdown}
                        onToggle={handleToggle}
                        onOptionsUpdate={handleOptionsUpdate}
                        key={`filter1-${resetKey}`}
                        width={16.5}
                    />
                </div>

                <div className="w-full md:w-[14.3%]">
                    <SearchSelect
                        id="filter6"
                        title="baths"
                        changeTitle={true}
                        newTitleFormat="value+title"
                        options={baths}
                        optionType="select"
                        useDropdownArrow={true}
                        onSelectionChange={handleBathsSelectionChange}
                        activeDropdown={activeDropdown}
                        onToggle={handleToggle}
                        onOptionsUpdate={handleOptionsUpdate}
                        key={`filter1-${resetKey}`}
                        width={16.5}
                    />
                </div>

                <div className="w-full md:w-[11.9%]">
                    <SearchInput 
                        id="filter7"
                        placeholder="REFERENCE"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="w-full md:w-[44px] h-[46px]" ref={containerRef}>
                    <SearchSelectMulticolumnToggle 
                        onClick={handleToggleOptionsDiv}
                        ref={toggleRef}
                        isExpanded={showOptionsDiv}
                    />
                
                    {
                        showOptionsDiv && (
                            <div
                                className="md:absolute top-[52px] left-0 right-0 bottom-0 z-5"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <SearchSelectMulticolumn
                                    id="filter8"
                                    title="Features & Amenities"
                                    options={featuresAmenities}
                                    optionType="multipleSelect"
                                    useDropdownArrow={true}
                                    onSelectionChange={handleMulticolumnSelectionChange}
                                    activeDropdown={activeDropdown}
                                    onToggle={handleToggle}
                                    onOptionsUpdate={handleMulticolumnOptionsUpdate}
                                    toggleRef={toggleRef}
                                    selectedOptions={multicolumnOptions}
                                    setSelectedOptions=
                                    {handleMulticolumnOptionsUpdate}
                                    ref={multiColumnSelectRef}
                                    onClear={() => setResetKey((prevKey) => prevKey + 1)}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <div>
                <div className='flex justify-center md:justify-end items-top w-full md:w-[156px] md:ml-[7px]'>
                    <Link 
                        to="#" 
                        className="button-blue !px-4 md:!px-5 !text-[11px] uppercase h-[46px] flex items-center mt-6 md:mt-0 md:w-full"
                        onClick={(event) => {
                            event.preventDefault();   
                            fetchProperties(true);
                        }}
                    >
                        Show {propertyCount} results
                    </Link>
                </div>
                
                {/* Clear All Button */}
                <div className='flex justify-center w-full md:max-w-[156px] h-[39px]  md:ml-[7px]'>
                    {showClearButton && (
                        <Link 
                            to="#" 
                            className="mt-3 text-[13px] uppercase flex items-center justify-center font-normal font-benton hover:font-bold"
                            onClick={handleClearAllFilters}
                        >
                            Clear all filters
                        </Link>
                    )}
                </div>

            </div>
        </div>
    );
}

export default SearchOptions;