import JournalCard from "../Journals/JournalCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const OtherJournals = ({ journals }) => {
    return (
        <div className="journal container">
            <h2 className="font-regular font-normal md:font-light text-xl md:text-2xl h-[63px] md:h-[135px] text-center flex items-center justify-center">Related news</h2>
            <div className="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-4 items-center md:items-stretch md:justify-items-center md:gap-8 mb-[45px] md:mb-[90px] md:justify-between">
                {journals.map(journal => (
                    <JournalCard 
                        key={journal.id}
                        id={journal.id}
                        slug={journal.slug}
                        photo={journal.featuredImages.length > 0 ?journal.featuredImages[0].url : false } 
                        category={journal.category}
                        title={journal.title}
                        excerpt={journal.excerpt.document}
                        date={journal.date}
                        readTime={journal.readingTime}
                        createdAt={journal.createdAt}
                    />
                ))}
            </div>
            <div className="text-center">
                <Link to="/journals" className="button mb-[70px]">View more</Link>
            </div>
        </div>
    );
}

export default OtherJournals;