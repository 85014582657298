import { ReactComponent as SliderPrevArrowBlack } from "../../assets/icons/slider_prev_black.svg";
import { ReactComponent as SliderNextArrowBlack } from "../../assets/icons/slider_next_black.svg";
import Slider from "react-slick";
import { useState, useRef } from "react";
import RequestInformation from '../TeamMember/RequestInformation';
import { useMediaQuery } from 'react-responsive';
import LazyBackground from "../../utils/LazyBackground";

const PropertyHero = ({ 
    property, 
    onActiveImageIndexChange, 
    agent, 
    onRequestInformationActive,
    onCloseRequestInformation,
    siteSettings
}) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlide(current);
      onActiveImageIndexChange(current);
    },
  };

  const getWidth = () => {
    if(isMobile)
      return 768;
    if(isTablet)
      return 1024;
    
    return 1624;
  }

  const getHeight = () => {
    if(isMobile)
      return 463;
    if(isTablet)
      return 600;
    
    return 905;
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)'});
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="property-hero relative">
        <RequestInformation 
            active={onRequestInformationActive} 
            agent={agent} 
            onClose={onCloseRequestInformation}
            phone={siteSettings.phone}    
            whatsapp={siteSettings.whatsapp}
        />

      <div className="hidden lg:flex absolute w-full left-0 top-0" style={{height: 'calc(100% - 8px)'}}>
        <div 
          className="w-1/2 z-10 prev-arrow-cursor" 
          onClick={handlePrevClick}>
        </div>
        <div className="w-1/2 z-10 next-arrow-cursor"
          onClick={handleNextClick}>  
        </div>
      </div>

      <Slider {...settings} ref={sliderRef}>
        {property.media.map((image, index) => (
          <div key={index}>
            <LazyBackground
              className={"wide-container bg-cover bg-no-repeat bg-center h-[463px] md:h-[600px] lg:h-[905px]"}
              backgroundImage={image}
              width={getWidth()}
              height={getHeight()}
            />
          </div>
        ))}
      </Slider>
      {/* Mobile */}
      <div className="container lg:hidden flex justify-between items-center h-[73px]">
        <SliderPrevArrowBlack onClick={handlePrevClick} />
        <span className="text-[24px]">
          {currentSlide + 1}/{property.media.length}
        </span>
        <SliderNextArrowBlack onClick={handleNextClick} />
      </div>
    </div>
  );
};

export default PropertyHero;
