import React from 'react';
import ActionButton from "../components/ContentBlocks/ActionButton";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const RichTextRenderer = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  const renderFormattedText = (textParts, propPath) => {
    return textParts.map((part, index) => {
      let style = {};
      if (part.bold) {
        style.fontWeight = 'bold';
      }
      if (part.italic) {
        style.fontStyle = 'italic';
      }
      if (part.underline) {
        style.textDecoration = 'underline';
      }
      if (part.strikethrough) {
        style.textDecoration = 'line-through';
      }
      
      if (part.type === 'link') {
        if (propPath === 'action2') {
          // Skip rendering the link as text because it will be rendered as an ActionButton
          return null;
        }

        if (part.href.startsWith('https://')) {
          return <a href={part.href} style={style} key={index} className="underline">{part.children[0].text}</a>;
        } else {
          return <Link to={part.href} style={style} key={index} className="underline">{part.children[0].text}</Link>;
        }
      }
      return <span style={style} key={index}>{part.text}</span>;
    });
  };

  return (
    <div>
      {data.map((block, bIndex) => {
                console.log('Processing block:', block);

        const propPath = block.propPath?.[0];
        const textAlign = block?.children[0]?.textAlign || 'left';

        return (
          <div key={bIndex}>
            {block.children.map((paragraph, pIndex) => {
                            console.log('Processing paragraph:', paragraph);

              if (paragraph.type === 'paragraph') {
                return (
                  <div style={{ textAlign }} key={pIndex}>
                    {renderFormattedText(paragraph.children, propPath)}
                    {propPath === 'action2' && paragraph.children.some(child => child.type === 'link') && (
                      <ActionButton url={paragraph.children.find(child => child.type === 'link').href} label={paragraph.children.find(child => child.type === 'link').children[0].text} />
                    )}
                  </div>
                );
              } else if (paragraph.type === 'heading') {
                const Tag = `h${paragraph.level}`;
                return (
                    <Tag key={pIndex}>
                        {renderFormattedText(paragraph.children, propPath)}
                    </Tag>
                );
            }
              return null;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default RichTextRenderer;
