import { Link } from 'react-router-dom';
import LazyBackground from '../../utils/LazyBackground';

const Partnership = () => {
    const image = "/assets/images/tmp/featured_properties_3.jpg";

    return (
        <div className="md:container mx-auto mt-10 md:mt-[105px]">
            <div className="flex items-center justify-center text-center text-4xl text-normal pt-7 md:pt-12 pb-8 md:pb-12">
                Let's do business together
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-[94px] lg:gap-[35px]">
                <div className="w-full max-w-[560px]">
                    <div className="bg-no-repeat bg-top h-[374px]" style={{ backgroundImage: `url("/assets/images/tmp/partnership_01.jpg")` }}></div>
                    <div className="container flex flex-col">
                        <span className="font-normal text-[24px] text-center mt-8 md:mt-8 mb-5 md:mb-8">
                            Find your agent
                        </span>
                        <span className="text-center mb-8 md:mb-9 font-benton text-[15px] leading-[22px] max-w-[80%] mx-auto">
                            Our team offers a diversity of experience and expertise.
                        </span>
                        <span className="text-center">
                            <Link to="#" className="button !px-[30px]">
                                Meet the sales team
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="w-full max-w-[560px]">
                    <div className="bg-no-repeat bg-top h-[374px]" style={{ backgroundImage: `url("/assets/images/tmp/partnership_02.jpg")` }}></div>
                    <div className="container flex flex-col">
                        <span className="font-normal text-[24px] text-center mt-8 md:mt-8 mb-5 md:mb-8">
                            Sell your property with us
                        </span>
                        <span className="text-center mb-8 md:mb-9 font-benton text-[15px] leading-[22px] max-w-[80%] mx-auto">
                            We'll match you with a Specialist that knows your area best.
                        </span>
                        <span className="text-center">
                            <Link to="#" className="button !px-[30px]">
                                Sell your property
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Partnership;