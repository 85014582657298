function transformListingStatsResponse(data) {
    let result = {};

    data.forEach(item => {
        let listingType = item.listingtype;
        result[listingType] = {
            price: {
                min: parseInt(item._min.listingprice),
                max: parseInt(item._max.listingprice)
            },
            area: {
                min: parseInt(item._min.totalarea),
                max: parseInt(item._max.totalarea)
            }
        };
    });

    return result;
}

export default transformListingStatsResponse;