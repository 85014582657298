import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';

export const useGetPartnersCategories = () => {
  const GET_PARTNERS_CATEGORIES = gql`
    query PreferredPartnersCategories {
      preferredPartnersCategories {
        id
        title
        slug
        parent {
          id
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_PARTNERS_CATEGORIES);

  if(loading || error) {
    return null;
  }
  
  const newData = [
    {
      "id": 0,
      "title": "All Categories",
      "slug": "",
      "parent": null
    },
    ...data.preferredPartnersCategories 
  ];

  return newData;
}

export const useGetPartner = (slug) => {
  const GET_PARTNER = gql`
    query PreferredPartner($where: PreferredPartnerWhereUniqueInput!) {
      preferredPartner(where: $where) {
        id
        category {
          title
          slug
        }
        title
        slug
        readingTime
        featuredImages {
          url
        }
        author {
          name
        }
        tags {
          name
          id
        }
        status
        publishedAt
        updatedAt
        createdAt
        OGTitle
        OGDescription
        PageTitle
        PageDescription
        PageKeywords
        TwitterImage {
          url
        }
        FacebookImage {
          url
        }
        document {
          document(hydrateRelationships: true)
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_PARTNER, { 
      variables: {
        "where": {
          "slug": slug
        },
      },
  });

  if(loading || error) {
    return null;
  }
  
  return data.preferredPartner;
}

export const useGetPartners = (limit, offset, categorySlug) => {
  const GET_PARTNERS = useMemo(() => gql`
    query PreferredPartners($where: PreferredPartnerWhereInput, $take: Int, $skip: Int) {
      preferredPartners(where: $where, take: $take, skip: $skip) {
        id
        category {
          id
          title
          slug
        }
        title
        slug
        featuredImages {
          id
          url
        }
        excerpt {
          document
        }
        tags {
          name
          id
        }
        createdAt
        document {
          document(hydrateRelationships: true)
        }
      }
    }
  `, []);

  const currentTime = useMemo(() => new Date().toISOString(), []);

  const baseWhere = useMemo(() => ({
    status: {
      equals: "published"
    },
    OR: [
      {
        publishedAt: { 
          lt: currentTime
        }
      },
      {
        publishedAt: { 
          equals: null
        }
      }
    ]
  }), [currentTime]);

  let variables = useMemo(() => ({
    take: limit,
    skip: offset,
    where: categorySlug
      ? {
          AND: [
            baseWhere,
            {
              OR: [
                {
                  category: {
                    parent: {
                      slug: {
                        equals: categorySlug
                      }
                    }
                  }
                },
                {
                  category: {
                    slug: {
                      equals: categorySlug
                    }
                  }
                }
              ]
            }
          ]
        }
      : baseWhere
  }), [limit, offset, categorySlug, baseWhere]);

  const { loading, error, data } = useQuery(GET_PARTNERS, { 
    variables,
  });

  return {
    isLoading: loading,
    isError: error,
    data: data ? data.preferredPartners : null
  }
}

export const useGetOtherPartners = (limit, excludeId) => {
  const GET_PARTNERS = gql`
    query PreferredPartner($where: PreferredPartnerWhereInput!, $take: Int) {
      preferredPartners(where: $where, take: $take) {
        id
        category {
          slug
          title
        }
        title
        slug
        featuredImages {
          url
        }
        excerpt {
          document
        }
        author {
          name
          id
        }
        createdAt
        publishedAt
        readingTime
      }
    }
  `;

  let variables = {
    take: limit,
  };

  const currentTime = useMemo(() => new Date().toISOString(), []);
  variables.where = {
    "AND": [
      {
        "id": {
          "not": {
            "equals": excludeId
          }
        }
      },
      {
        "status": {
          "equals": "published"
        }
      },
      {
        OR: [
          {
            publishedAt: { 
              lt: currentTime
            }
          },
          {
            publishedAt: { 
              equals: null
            }
          }
        ]
      }
    ]
    
  };

  const { loading, error, data } = useQuery(GET_PARTNERS, { 
    variables,
    skip: !excludeId
  });

  if(loading || error) {
    return null;
  }
  
  return  data ? data.preferredPartners : null;
}