import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import DynamicComponent from "../components/DynamicComponent";
import { SeoHelmet, seoData } from "../utils/Seo";
import { useGetPartner, useGetOtherPartners } from "../queries/Partners";
import OtherPartners from "../components/Partners/OtherPartners";

const Partner = () => {
    const { slug } = useParams();
    const partner = useGetPartner(slug);
    const otherPartners = useGetOtherPartners(4, partner?.id);
    const partnerComponents = partner?.document?.document;
    const [seoSettings, setSeoSettings] = useState({});
    
    useEffect(() => {
        if(partner) {
            setSeoSettings(seoData({...partner, defaultTitle: `${partner.title} - Sotheby's`}));
        }
    }, [partner]);
    
    return (
        <div className="partner mt-7">
            <SeoHelmet seoSettings={seoSettings} />

            {partner === null ? (<div className='container text-center'><h2>Error! There is no such partner!</h2></div>) : null}

            {partnerComponents && partnerComponents.map((componentData, index) => {
                const extraProps = componentData.component === "hero" ? {createdAt: partner.createdAt, author: partner.author, category: partner.category, image: partner.featuredImages[0].url, readTime: partner.readingTime} : {};

                return (
                    <DynamicComponent
                        key={index}
                        componentData={componentData}
                        props={{...componentData.props, ...extraProps}}
                    />
                );
            })}

            {otherPartners && <OtherPartners partners={otherPartners} />}
        </div>
    );
}

export default Partner;