import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CheckEmail = () => {
    useEffect(() => {
        document.title = "Check Email Page - Sotheby's";
    }, []);

    return (
        <div className="container md:!max-w-[460px] md:!mx-auto text-center font-normal">
            <div className="mt-7 md:mt-12 mb-9 md:mb-7 text-[22px] leading-[27px] text-black font-normal">
                Please check your email
            </div>
            <div className="mb-7 font-benton text-[15px] leading-[22px] text-[#2A2A2A] tracking-[0.375px]">
                If the email doesn’t arrive in 10 minutes, please check your spam/junk folder or send us an email to <a href="mailto:support@sothebysrealty.com" className="text-[#002349] font-medium">support@sothebysrealty.com</a>
            </div>
            
            <div className="flex flex-col gap-2.5 text-[#002349] font-benton text-[15px] leading-[22px] tracking-[0.375px] font-medium">
                <Link to="/resend-email">
                    Re-send email
                </Link>
                <Link to="/login">
                    Log in instead
                </Link>
                <Link to="/register">
                    Register new account
                </Link>
            </div>
        </div>
    );
}

export default CheckEmail;