import { useParams } from 'react-router-dom';
import TeamMemberLeftInfo from '../components/TeamMember/TeamMemberLeftInfo';
import TeamMemberRightInfo from '../components/TeamMember/TeamMemberRightInfo';
import TeamMemberJournal from '../components/TeamMember/TeamMemberJournal';
import TeamMemberProperties from '../components/TeamMember/TeamMemberProperties';
import { useGetAgent } from '../queries/Agents';
import RequestInformation from '../components/TeamMember/RequestInformation';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const TeamMember = ({ settings }) => {
    const params = useParams();
    const teamMember = useGetAgent(params.slug);
    const [isRequestInformationActive, setIsRequestInformationActive] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    useEffect(() => {
        document.title = `${teamMember.firstname} ${teamMember.lastname} Sotheby's Agent`;
    }, [teamMember]);

    const openRequestInformation = () => {
        setIsRequestInformationActive(true);
    }

    const closeRequestInformation = () => {
        setIsRequestInformationActive(false);
    }

    let teamMemberContacts = {};
    if(!teamMember || teamMember.active === "inactive") {
        teamMemberContacts = {
            email: "marylebone.branch@sothebysrealty.co.uk",
            firstname: "Marylebone",
            lastname: "Branch",
            languages: "English, Italian, Arabic",
            photo: "https://sothebys-assets.s3.eu-west-2.amazonaws.com/509f7f97-a0a8-47a6-91f1-5c115d2c2045.jpg",
            position: "",
            slug: ""
        }
    }
    else
        teamMemberContacts = teamMember;

    const journals = [
        {
          id: 1,
          publisher: "Real Estate Magazine",
          title: "Luxury Waterfront Homes: The Epitome of Elegance",
          description:
            "Discover the allure of luxury waterfront homes, where breathtaking views meet exquisite architecture. These exceptional properties offer the perfect blend of opulence and tranquility, providing a lifestyle that truly embraces the beauty of nature.",
          date: "June 1st, 2023",
        },
        {
          id: 2,
          publisher: "Urban Living Gazette",
          title: "City Apartments: Embrace the Vibrant Urban Lifestyle",
          description:
            "Step into the vibrant world of city apartments and experience the ultimate urban lifestyle. From sleek modern designs to historic lofts, these carefully curated spaces offer the perfect balance between comfort, convenience, and the bustling energy of city living.",
          date: "May 28th, 2023",
        },
    ];
   
    return (
        <div className={`teamMember ${isMobile || isTablet ? "wide-container" : "container"}`}>
            {teamMemberContacts && <RequestInformation 
                active={isRequestInformationActive} 
                agent={teamMemberContacts} 
                onClose={closeRequestInformation}
                phone={settings.phone}    
                whatsapp={settings.whatsapp}
            />}
            <div className="!max-w-[1267px] mx-auto">
                <div className="flex flex-col lg:flex-row mt-12 md:mt-[84px]">
                    <div className="w-full lg:w-[447px] flex lg:flex-none justify-center lg:justify-end">
                        {teamMember && <TeamMemberLeftInfo teamMember={teamMember} phone={settings.phone} whatsapp={settings.whatsapp} />}
                    </div>
                    <div>
                        {teamMember && <TeamMemberRightInfo 
                        teamMember={teamMember}
                        onOpenRequestInformation={openRequestInformation}    
                        onCloseRequestInformation={closeRequestInformation} />
                        }
                    </div>
                </div>
                <div className={`${isMobile || isTablet ? "container" : ""} flex lg:justify-between mb-10 lg:mb-12 mt-[52px]`}>
                    <div className="hidden lg:block w-[447px] flex-none"></div>
                    <div className="w-full">
                        <TeamMemberJournal journals={journals} />
                    </div>
                </div>
            </div>
            {teamMember && <TeamMemberProperties agentName={teamMember.firstname} agentEmail={teamMember.email} />}
        </div>
    );
}

export default TeamMember;