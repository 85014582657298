import { ReactComponent as ExpandImage } from '../../assets/icons/expand_image.svg';
import Slider from 'react-slick';
import { ReactComponent as SliderPrevArrow } from '../../assets/icons/slider_prev_black.svg';
import { ReactComponent as SliderNextArrow } from '../../assets/icons/slider_next_black.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cross_icon.svg';
import { useState, useRef, useEffect } from 'react';

const ImageSlider = ({ props }) => {
    let sliderImages = [];

    props.images.map(image => {
        image.data.url && sliderImages.push(image.data.url);
    })

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current),
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);
    const [fullscreenImage, setFullscreenImage] = useState(null);

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    }

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    }

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    }

    const closeFullscreen = () => {
        setFullscreenImage(null);
    }

    return (
        <div className="slider-block relative" id={props.anchor ? props.anchor : undefined}>
            <div className="relative wide-container mx-auto">
                <Slider {...settings} ref={sliderRef} className="relative z-10">
                {sliderImages.map((image, index) => (
                    <div key={index}>
                        <div 
                            className="relative wide-container bg-cover bg-no-repeat bg-center h-[362px] md:h-[905px]" 
                            style={{ backgroundImage: `url("${image}")` }}
                        >
                            <div className="hidden md:block absolute top-6 right-6">
                                <ExpandImage 
                                    className="cursor-pointer" 
                                    onClick={() => openFullscreen(image)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                </Slider>
                <div className={`hidden md:block absolute top-1/2 left-0 ml-[-35px] cursor-pointer ${sliderImages.length <= 1 ? "!hidden" : ""}`}>
                    <SliderPrevArrow onClick={handlePrevClick} />
                </div>
                <div className={`hidden md:block absolute top-1/2 right-0 mr-[-35px] cursor-pointer ${sliderImages.length <= 1 ? "!hidden" : ""}`}>
                    <SliderNextArrow onClick={handleNextClick} />
                </div>

                <div className={`container md:hidden flex justify-between items-center h-[73px] ${sliderImages.length <= 1 ? "!hidden" : ""}`}>
                    <SliderPrevArrow onClick={handlePrevClick} />
                    <span className="text-[24px]">{currentSlide + 1}/{sliderImages.length}</span>
                    <SliderNextArrow onClick={handleNextClick} />
                </div>
            </div>

            {fullscreenImage && (
                <div className="fixed z-50 top-0 left-0 w-full h-full bg-white flex items-center justify-center" onClick={closeFullscreen}>
                    <img src={fullscreenImage} alt="fullscreen" className="max-w-full max-h-full" />
                    <div className="absolute top-4 right-4">
                        <CloseIcon 
                            onClick={closeFullscreen} 
                            className="cursor-pointer" 
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImageSlider;