import { ReactComponent as ShowPasswordIcon } from '../../assets/icons/show_password.svg';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hide_password.svg';
import FooterDropdown from '../../components/FooterDropdown';
import { useState } from 'react';

const PersonalDetails = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect}) => {
    const locations = ["All locations", "Mayfair", "Knightsbridge", "Kensington", "Chelsea", "Hampstead", "Fulham", "Marylebone", "Others"];
    const newsletterPreferences = ["Real Estate", "Investments", "Economy"];

    const [checkedLocations, setCheckedLocations] = useState(
        locations.reduce((location, option) => {
            location[option] = false;
            return location;
        }, {})
    );

    const toggleLocationCheck = (option) => {
        setCheckedLocations((prev) => ({ ...prev, [option]: !prev[option]}));
    };

    const [checkedNewsletterPreferences, setCheckedNewsletterPreferences] = useState(
        newsletterPreferences.reduce((preference, option) => {
            preference[option] = false;
            return preference;
        }, {})
    );

    const toggleNewsletterPreferenceCheck = (option) => {
        setCheckedNewsletterPreferences((prev) => ({ ...prev, [option]: !prev[option]}));
    };

    const [activeDimension, setActiveDimension] = useState(selectedDimensionOption);

    const handleDimensionClick = (option) => {
        setActiveDimension(option);
        onDimensionOptionSelect(option);
    }

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    }

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    return (
        <div className="flex pb-[70px]">
            {/* Left Column */}
            <div className="w-1/2 font-benton">
                <div className="w-11/12">
                    <span className="inline-block text-[#2A2A2A] tracking-[0.375px] font-medium text-[15px] leading-[22px] mb-4">
                        Personal details
                    </span>
                    <div>
                        <input type="text" value="Bruce Wayne" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none mb-4 pl-[14px] tracking-[0.375px] font-normal text-[15px] leading-[22px]" />
                    </div>
                    <div>
                        <input type="email" value="brucewayne@wayneenterprises.com" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none mb-4 pl-[14px] tracking-[0.375px] font-normal text-[15px] leading-[22px]" />
                    </div>
                    <span className="inline-block text-[#2A2A2A] tracking-[0.375px] font-medium text-[15px] leading-[22px] my-4">
                        Change Password
                    </span>
                    <div className="relative mb-4">
                        <input 
                            type={showCurrentPassword ? "text":"password"}
                            placeholder="Current password" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none  pl-[14px] tracking-[0.375px] font-normal text-[15px] leading-[22px] placeholder:opacity-50"
                        />
                        <div 
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" 
                            onClick={toggleShowCurrentPassword}
                        >
                            {showCurrentPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                        </div>
                    </div>
                    <div className="relative mb-4">
                        <input 
                            type={showNewPassword ? "text":"password"}
                            placeholder="New password" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none  pl-[14px] tracking-[0.375px] font-normal text-[15px] leading-[22px] placeholder:opacity-50"
                        />
                        <div 
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" 
                            onClick={toggleShowNewPassword}
                        >
                            {showNewPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                        </div>
                    </div>
                    <div className="relative mb-4">
                        <input 
                            type={showConfirmPassword ? "text":"password"}
                            placeholder="Confirm password" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none  pl-[14px] tracking-[0.375px] font-normal text-[15px] leading-[22px] placeholder:opacity-50"
                        />
                        <div 
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" 
                            onClick={toggleShowConfirmPassword}
                        >
                            {showConfirmPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                        </div>
                    </div>
                    <span className="inline-block text-[#2A2A2A] tracking-[0.375px] font-medium text-[15px] leading-[22px] my-4 hidden">
                        Mismatch of new passwords. Please try again.
                    </span>
                    <span className="inline-block text-[#2A2A2A] tracking-[0.375px] font-medium text-[15px] leading-[22px] my-4">
                        Default metrics
                    </span>
                    <div className="flex justify-between mb-[14px]">
                        <span className="text-[13px] text-[#111111] font-normal tracking-[0.375px] leading-[19px] flex items-center">
                            Property dimensions
                        </span>
                        <div>
                            {dimensionOptions.map(option => (
                                <span
                                    key={option.value} 
                                    className={`py-2.5 px-6 border font-normal border-blacked rounded-md mr-1 text-[11px] cursor-pointer ${selectedDimensionOption.value === option.value ? "bg-sothebys-blue text-white":"bg-white text-black"}`}
                                    onClick={() => handleDimensionClick(option)}    
                                >
                                    {option.label}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="text-[13px] text-[#111111] font-normal tracking-[0.375px] leading-[19px] flex items-center">
                            Currency
                        </span>
                        <div>
                            <FooterDropdown options={currencyOptions} selectedOption={selectedCurrencyOption} onSelect={onCurrencyOptionSelect} />
                        </div>
                    </div>
                    <div className="button inline-block mt-4 !px-[30px]">
                        Save changes
                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div className="w-1/2 font-benton flex justify-end">
                <div className="w-11/12">
                    <span className="inline-block text-[#2A2A2A] tracking-[0.375px] font-medium text-[15px] leading-[22px] mb-4">
                        Optional sales preferences
                    </span>
                    <div className="flex justify-between mb-4">
                        <span className="text-[13px] text-[#111111] font-normal tracking-[0.375px] leading-[19px] flex items-center">
                            Budget
                        </span> 
                        <div>
                            <input type="text" placeholder="Prefered budget in £" className="login-field w-full rounded border border-[#DDDDDC] h-[22px] focus:outline-none mb-4 tracking-[0.375px] font-normal text-[13px] placeholder:text-[13px] leading-[22px]" />
                        </div>
                    </div>
                    <div className="text-[13px] text-[#111111] font-normal tracking-[0.375px] leading-[22px]">
                        <span className="font-medium inline-block mb-[13px]">
                            Interested in location
                        </span>
                        {locations.map((location, index) => (
                            <div key={index} className={`flex items-center ${index !== 0 ? "mt-3" : ""}`}>
                                <input 
                                    type="checkbox" 
                                    className="hidden" 
                                    id={location} 
                                    checked={checkedLocations[location]}
                                    onChange={() => toggleLocationCheck(location)}
                                /> 
                                <label 
                                    htmlFor={location} 
                                    className={`w-[18px] h-[18px] border border-[#111111] mr-2 inline-block bg-white relative cursor-pointer rounded-sm`}
                                >
                                    {checkedLocations[location] &&
                                        <span className="absolute w-[12px] h-[12px] bg-sothebys-blue left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-sm"></span>
                                    }
                                </label>
                                {location}
                            </div>
                        ))}
                    </div>
                    <div className="text-[13px] text-[#111111] font-normal tracking-[0.375px] leading-[22px] mt-4">
                        <span className="font-medium inline-block mb-[13px]">
                            Newsletter preferences
                        </span>
                        {newsletterPreferences.map((preference, index) => (
                            <div key={index} className={`flex items-center ${index !== 0 ? "mt-3" : ""}`}>
                                <input 
                                    type="checkbox" 
                                    className="hidden" 
                                    id={preference} 
                                    checked={checkedNewsletterPreferences[preference]}
                                    onChange={() => toggleNewsletterPreferenceCheck(preference)}
                                /> 
                                <label 
                                    htmlFor={preference} 
                                    className={`w-[18px] h-[18px] border border-[#111111] mr-2 inline-block bg-white relative cursor-pointer rounded-sm`}
                                >
                                    {checkedNewsletterPreferences[preference] &&
                                        <span className="absolute w-[12px] h-[12px] bg-sothebys-blue left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-sm"></span>
                                    }
                                </label>
                                {preference}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalDetails;