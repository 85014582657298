import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import { ReactComponent as SliderPrev } from '../../assets/icons/slider_prev_black.svg';
import { ReactComponent as SliderNext } from '../../assets/icons/slider_next_black.svg';
import { useMediaQuery } from 'react-responsive';
import TeamMemberCard from "../TeamMember/TeamMemberCard";
import ActionButton from "../ContentBlocks/ActionButton";
import { useGetSettings } from "../../queries/Menus";

const TeamSlider = ({ teamMembers, siteSettings }) => {
    const isMobileDevice = useMediaQuery({ query: '(max-width: 768px)' });

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1535, // Tailwind's default "md" breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 1300, // Tailwind's default "md" breakpoint
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 1000, // Tailwind's default "md" breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 768, // Tailwind's default "md" breakpoint
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    variableWidth: false,
                    adaptiveHeight: true,
                },
            },
        ],
    };

    const sliderRef = React.useRef(null);

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    }

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    }

    return (
        <>
            <div className="container w-full flex md:flex-col items-center justify-between">
                <div className="w-full flex items-center justify-between md:justify-center mt-7 mb-10 md:my-12">
                <SliderPrev width={12} height={20} className="block md:hidden cursor-pointer ml-2.5" onClick={handlePrevClick} />
                {/*<div className="hidden md:block mb-10 mx-20"></div>*/}
                <h2 className="pt-1 md:pt-0 text-xl md:text-2xl text-center font-normal md:font-light">The Team</h2>
                <SliderNext width={12} height={20} className="block md:hidden cursor-pointer mr-2.5" onClick={handleNextClick} />
                </div>
            </div>
            
            <div className={`${!isMobileDevice ? "container" : ""} ml-5 md:mx-auto overflow-hidden md:overflow-visible relative`}>
                <SliderPrev width={21} height={38} className="hidden md:block absolute top-[193px] cursor-pointer" onClick={handlePrevClick} />
                <div className="mb-10 md:mx-20">
                    {teamMembers && (
                        <Slider {...settings} ref={sliderRef}>
                        {teamMembers.map((member, index) => (
                            <TeamMemberCard member={member} key={index} phone={siteSettings.phone} whatsapp={siteSettings.whatsapp} cardType={"slider"} />
                        ))}
                        </Slider>
                    )}
                </div>
                <SliderNext width={21} height={38} className="hidden md:block absolute right-0 top-[193px] cursor-pointer" onClick={handleNextClick} />
            </div>
        </>
    );
}

const TeamGrid = ({ teamMembers, siteSettings }) => {
    
    return (
        <div className="container mt-7 flex w-full max-w-[1276px] flex-wrap gap-[35px] mx-auto justify-center">
            {teamMembers && teamMembers.length > 0 && teamMembers.map((agent, index) => (
                <TeamMemberCard member={agent} key={index} phone={siteSettings.phone} whatsapp={siteSettings.whatsapp} cardType={"grid"} />
            ))}
        </div>
    );
};

const Team = ({ props }) => {
    const getSettings = useGetSettings();
    const [settings, setSettings] = useState({});

    let members = [];
    props.team_members.map(member => {
        members.push(member.data);
    });

    const teamMembers = members.sort((a, b) => b.id.localeCompare(a.id));

    useEffect(() => {
        if(getSettings && Object.keys(getSettings).length > 0) {
          setSettings(getSettings);
        }
      }, [getSettings]);
    
    return (
        <div className="team wide-container md:container text-center relative">
            {Object.keys(settings).length > 0 ?
            (props.slider ? <TeamSlider teamMembers={teamMembers} siteSettings={settings} /> : <TeamGrid teamMembers={teamMembers} siteSettings={settings} />) : null} 
            {props.action && props.action.label && props.action.url && (
                <ActionButton url={props.action.url} label={props.action.label} />
            )}
        </div>
    );
}

export default Team;