import FooterNavigation from "../components/Footer/FooterNavigation";
import FooterInfo from "../components/Footer/FooterInfo";
import FooterSocialLinks from "../components/Footer/FooterSocialLinks";
import FooterContacts from "../components/Footer/FooterContacts";
import FooterDimensions from "../components/Footer/FooterDimensions";
import FooterLinks from "../components/Footer/FooterLinks";

const Footer = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect, settings}) => {
    return (
        <footer className="footer container border-t border-sothebys-blue mt-18 mb-8 md:mb-16 font-benton text-xs tracking-tight mx-auto footer-text">
            <div className="max-w-6xl mx-auto">
                <div className="hidden md:block">
                    <FooterNavigation />
                    <FooterInfo />
                    <FooterSocialLinks settings={settings} />
                </div>
                <div className="block md:hidden">
                    <FooterInfo />
                    <FooterSocialLinks settings={settings} />
                    <FooterNavigation />
                </div>
                <div className="flex flex-col-reverse md:flex-row justify-center mt-12">
                    <FooterContacts settings={settings} />
                    <FooterDimensions 
                        currencyOptions={currencyOptions}
                        selectedCurrencyOption={selectedCurrencyOption}
                        onCurrencyOptionSelect={onCurrencyOptionSelect}
                        dimensionOptions={dimensionOptions}
                        selectedDimensionOption={selectedDimensionOption}
                        onDimensionOptionSelect={onDimensionOptionSelect}
                    />
                </div>
                <FooterLinks />
            </div>
        </footer>
    );
}

export default Footer;