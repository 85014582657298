import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SavedSearchTop = ({ search }) => {
    let searchTitle = search.listingType;

    if(search.community.length > 0)
        searchTitle += " in " + search.community.join(", ");

    if(search.price.min > 0 && search.price.max < 99999999)
        searchTitle += " between £" + search.price.min.toLocaleString() + " - £" + search.price.max.toLocaleString();
    else if(search.price.min > 0 && search.price.max == 99999999)
        searchTitle += " from £" + search.price.min.toLocaleString();
    else if(search.price.min == 0 && search.price.max < 99999999)
        searchTitle += " up to £" + search.price.max.toLocaleString();

    if(parseInt(search.minBaths) > 0)
        searchTitle += " " + search.minBaths + "+ Baths,";

    if(parseInt(search.minBeds) > 0)
        searchTitle += " " + search.minBeds + "+ Beds";

    if(searchTitle.endsWith(","))
        searchTitle.slice(0, -1);

    return (
        <div className="h-[91px] flex items-center justify-between">
            <div className="text-[26px]">
                {searchTitle}
            </div>
            <div className="mt-[50px] pb-[50px] flex items-center justify-center">
                <Link to="#" className="font-benton font-medium text-[15px] leading-[22px] tracking-[0.375px] hover:opacity-60">
                    Remove search
                </Link>
                <span className="button inline-block ml-[50px]">
                    View all results
                </span>
            </div>
            
        </div>
    );
};

export default SavedSearchTop;