import { useQuery, gql } from '@apollo/client';
import { useState, useEffect } from 'react';

export const useGetProperty = (idField) => {
  const GET_PROPERTY = gql`
  query Listing($where: ListingWhereUniqueInput!) {
    listing(where: $where) {
      id
      idField
      isReady
      listing_title
      listingprice
      currency_iso_code
      listingtype
      lotsize
      property
      propertytype
      status
      totalarea
      description
      media
      bedrooms
      fullbathrooms
      state
      country
      country_code
      postalcode
      latitude
      longitude
      listing_agent_email
      createdAt
      mode
      timestamp
      listing_id
      reference_number
      city_propertyfinder
      property_propertyfinder
      community_propertyfinder
      sub_community_propertyfinder
      address
      epc_url
      floorplans
      OGTitle
      OGDescription
      PageDescription
      PageTitle
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
      private_amenities
      commercial_amenities
    }
  }`;
      
  const { loading, error, data } = useQuery(GET_PROPERTY, {
      variables: {
          "where": {
            "idField": idField
          }
      },
  });
  
  if(loading || error) 
      return null;

  return data.listing;
}

export const useGetHomeFeaturedProperties = () => {
    const GET_HOME_FEATURED = gql`
    query Home {
      home {
        featured_listings {
          idField
          address
          bedrooms
          city_propertyfinder
          community_propertyfinder
          currency_iso_code
          description
          fullbathrooms
          listing_agent_email
          listing_id
          listing_title
          listingprice
          listingtype
          postalcode
          property
          property_propertyfinder
          propertytype
          reference_number
          state
          status
          sub_community_propertyfinder
          totalarea
          media
        }
      }
    }`;
        
    const { loading, error, data } = useQuery(GET_HOME_FEATURED, {
        variables: {
            "where": null
        },
    });

    if(loading || error) 
        return null;

    return data.home.featured_listings.data;
}

export const useGetHomeNewProperties = (amount) => {
  const GET_HOME_NEW = gql`
  query Listings($take: Int, $orderBy: [ListingOrderByInput!]!) {
    listings(take: $take, orderBy: $orderBy) {
      id
      idField
      isReady
      listing_title
      listingprice
      currency_iso_code
      listingtype
      lotsize
      property
      propertytype
      status
      totalarea
      description
      media
      bedrooms
      fullbathrooms
      state
      country
      country_code
      postalcode
      latitude
      longitude
      listing_agent_email
      createdAt
      mode
      timestamp
      listing_id
      reference_number
      city_propertyfinder
      property_propertyfinder
      community_propertyfinder
      sub_community_propertyfinder
      address
    }
  }`;
      
  const { loading, error, data } = useQuery(GET_HOME_NEW, {
    variables: {
      "take": amount,
      "orderBy": [
        {
          "createdAt": "desc"
        }
      ]
    }
  });

  if(loading || error) 
      return null;

  return data.listings;
}

export const useGetHomePopularProperties = () => {
  const GET_HOME_POPULAR = gql`
  query Home {
    home {
      popular_listings {
        address
        bedrooms
        city_propertyfinder
        community_propertyfinder
        country
        country_code
        createdAt
        currency_iso_code
        description
        fullbathrooms
        id
        idField
        isReady
        latitude
        listing_agent_email
        listing_id
        listing_title
        listingprice
        listingtype
        longitude
        lotsize
        media
        mode
        postalcode
        property
        property_propertyfinder
        propertytype
        reference_number
        state
        status
        sub_community_propertyfinder
        timestamp
        totalarea
      }
    }
  }`;
      
  const { loading, error, data } = useQuery(GET_HOME_POPULAR, {
      variables: {
          "where": null
      },
  });

  if(loading || error) 
      return null;

  return data.home.popular_listings;
}

export const useGetLocations = () => {
  const GET_LOCATIONS = gql`
    query Locations {
      locations {
        id
        name
      }
    }
  `;
      
  const { loading, error, data } = useQuery(GET_LOCATIONS);
  const [locations, setLocations] = useState([]);
  
  useEffect(() => {
    if(!loading && data) {
      let newLocations = [];
      newLocations.push({id: "all", title: "All Locations", value: "all"});

      data.locations.map(location => {
        newLocations.push({id: location.id, title: location.name, value: location.id})
      });

      setLocations(newLocations);
    }
  }, [loading, data]);

  return locations;
}

export const useGetListingStats = () => {
  const GET_STATS = gql`
    query Query {
      listingStats
    }`;
      
  const { loading, error, data } = useQuery(GET_STATS);
  
  if(loading || error) 
      return null;
    
  return data.listingStats;
}