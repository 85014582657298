import { useQuery, gql } from '@apollo/client';

export const useGetAgent = (slug = false, id = false, email = false) => {
    const GET_AGENT = gql`
      query GetAgent($where: AgentWhereUniqueInput!) {
        agent(where: $where) {
          firstname
          lastname,
          photo,
          email,
          position,
          languages,
          slug
          document {
            document(hydrateRelationships: true)
          }
          active
        }
      }
    `;
    
    let variables = {};

    if(slug)
      variables = { "where": { "slug": slug }};
    else if(id)
      variables = { "where": { "id": id }};
    else if(email)
      variables = { "where": { "email": email }};

    const { loading, error, data } = useQuery(GET_AGENT, { 
        variables,
        skip: Object.keys(variables).length === 0
    });
    
  if(loading) {
    return <div className="container mx-auto">Loading...</div>;
  }

  if(error)  {
    return <div className="container mx-auto">Error fetching data</div>;
  }

  return data ? data.agent : null;
}

export const useGetAgents = ({ name, per_page = 10, offset = 0 }) => {
  const GET_AGENTS = gql`
    query Agents($orderBy: [AgentOrderByInput!]!, $take: Int, $skip: Int!, $where: AgentWhereInput!) {
      agents(orderBy: $orderBy, take: $take, skip: $skip, where: $where) {
        email
        firstname
        lastname
        licence
        mobile
        phone
        photo
        slug
        position
        languages
      }
    }
  `;
  
  let where = {
    AND: [
      {
        active: {
          equals: "active"
        }
      }
    ],
  };

  if(name) {
    where.AND.push({
      OR: [
        {
          firstname: {
            contains: name,
            mode: "insensitive"
          }
        },
        {
          lastname: {
            contains: name,
            mode: "insensitive"
          }
        }
      ]
    });
  }

  let variables = {};
  variables.orderBy = {"id": "desc"};
  variables.take = per_page;
  variables.skip = offset;
  variables.where = where;

  const { loading, error, data } = useQuery(GET_AGENTS, { 
    variables
  });

  if(loading) {
    return '';
  }

  if(error)  {
    return '';
  }

  return data ? data.agents : null;
}

export const useGetAgentProperties = (email, listingType, take, skip = 0) => {
  const GET_AGENT = gql`
  query Listing($take: Int, $skip: Int, $orderBy: [ListingOrderByInput!]!, $where: ListingWhereInput!) {
    listings(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      idField
      isReady
      tags {
        id
        name
      }
      tagsCount
      listing_title
      listingprice
      currency_iso_code
      listingtype
      lotsize
      property
      propertytype
      status
      totalarea
      description
      media
      bedrooms
      fullbathrooms
      state
      country
      country_code
      postalcode
      latitude
      longitude
      listing_agent_email
      listing_agent_firstname
      listing_agent_lastname
      listing_agent_licence
      listing_agent_mobil_phone
      listing_agent_phone
      listing_agent_photo {
        url
      }
      createdAt
      mode
      timestamp
      listing_id
      reference_number
      city_propertyfinder
      property_propertyfinder
      community_propertyfinder
      sub_community_propertyfinder
      address
      private_amenities
      commercial_amenities
      epc_title
      epc_url
      floorplans
      OGTitle
      OGDescription
      PageTitle
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_AGENT, { 
      variables: {
        "take": take,
        "skip": skip,
        "orderBy": [
          {
            "createdAt": "desc"
          }
        ],
        "where": {
          "listing_agent_email": {
            "equals": email
          },
          "listingtype": {
            "equals": listingType
          }
        }
      },
  });

  if(loading || error) {
    return null;
  }

  return data.listings;
}