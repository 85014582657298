import React, { useState, useEffect, useRef } from 'react';
import Counter from "../../utils/counter";

const SellStatistics4Columns = ({ props }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const containerRef = useRef(null);

    const allNumbers = props.stats.map(stat => parseInt(stat.summary.replace(/\D/g, ''))).filter(num => !isNaN(num));
    const maxNumber = Math.max(...allNumbers);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setStartAnimation(true);
                observer.unobserve(containerRef.current);
            }
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        }
    }, []);
 
    return (
        <>
        {props.stats.length > 0 &&
            <div ref={containerRef} className="container flex flex-col gap-y-[60px] md:gap-y-0 md:flex-row justify-around" id={props.anchor ? props.anchor : undefined}>
                {props.stats.map((stat, index) => {
                    let numberPart, textPart;

                    // determine if we have numbers inside a text string determined with special {} brackets
                    if(stat.summary.includes('{') && stat.summary.includes('}')) {
                        numberPart = parseInt(stat.summary.match(/\(\((.*?)\)\)/)[1].replace(/\D/g, ''));
                    } else {
                        numberPart = parseInt(stat.summary.replace(/[^\d]/g, ''));
                    }

                    return (
                        <div className="flex flex-col items-center" key={index}>
                            <span className="stats4-number font-acta font-normal text-[36px] leading-[36px] mb-2" data-title={stat.summary}>
                                {stat.summary.includes('((') && stat.summary.includes('))') ? (
                                    <span>
                                    {stat.summary.split(/\(\((.*?)\)\)/).map((part, i) => (
                                        i % 2 === 0 ? part : (
                                            <Counter 
                                                key={i}
                                                targetNumber={parseInt(part.replace(/[^\d]/g, ''))} 
                                                duration={1500} 
                                                startAnimation={startAnimation} 
                                                setAnimationComplete={setAnimationComplete} 
                                            />
                                        )
                                    ))}
                                    </span>
                                ) : (
                                    <>
                                        {numberPart > 0 && 
                                            <Counter 
                                                targetNumber={numberPart} 
                                                duration={1500} 
                                                startAnimation={startAnimation} 
                                                setAnimationComplete={setAnimationComplete} 
                                            />
                                        }
                                    </>
                                )}
                            </span>
                            <span className="font-benton font-normal text-xs uppercase tracking-[0.98 px]">
                                {stat.title}
                            </span>
                        </div>
                    );
                })}
            </div>
        }
        </>
    );
};

export default SellStatistics4Columns;
