import { useGetJournal, useGetOtherJournals } from "../queries/Journals";
import { useMemo } from "react";
import { useParams } from 'react-router-dom';
import DynamicComponent from "../components/DynamicComponent";
import OtherJournals from "../components/Journal/OtherJournals";
import { SeoHelmet, seoData } from "../utils/Seo";

const Journal = () => {
    const { slug } = useParams();
    const journal = useGetJournal(slug);
    const otherJournals = useGetOtherJournals(4, journal?.id);
    const journalComponents = journal?.document?.document;
    let extraProps = {};
    const seoSettings = useMemo(() => {
        if(journal) {
            return seoData({...journal, defaultTitle: `${journal.title} - Sotheby's`});
        }
        return {};
    }, [journal]);
    
    return (
        <div className="journal mt-7">
            <SeoHelmet seoSettings={seoSettings} />

            {journal === null ? (<div className='container text-center'><h2>Error! There is no such journal!</h2></div>) : null}

            {journalComponents && journalComponents.map((componentData, index) => {
                if(componentData.component === "hero") {
                    extraProps = {
                        props: {
                            createdAt: journal.createdAt, 
                            author: journal.author, 
                            category: journal.category, 
                            image: journal.featuredImages[0]?.url, 
                            readTime: journal.readingTime
                        }
                    };
                } else {
                    extraProps = {};
                }
                
                return (
                    <DynamicComponent
                        key={index}
                        componentData={{...componentData, ...extraProps}}
                    />
                );
            })}

            {otherJournals && <OtherJournals journals={otherJournals} />}
        </div>
    );
}

export default Journal;