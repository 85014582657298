import NeighbourhoodGuideProperties from "../components/Neighbourhood/NeighbourhoodGuideProperties";
import OtherNeighbourhoods from "../components/Neighbourhood/OtherNeighbourhoods";
import { useGetNeighbourhood, useGetNeighbourhoods } from "../queries/Neighbourhoods";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import shuffleArray from "../utils/shuffleArray";
import DynamicComponent from "../components/DynamicComponent";
import UnderTitleNavigation from "../components/Neighbourhood/UnderTitleNavigation";
import { SeoHelmet, seoData } from "../utils/Seo";

const NeighbourhoodGuide = () => {
    const { neighbourhoodSlug } = useParams();
    const neighbourhood = useGetNeighbourhood(neighbourhoodSlug);
    const [requestBody, setRequestBody] = useState(null);
    const otherNeighbourhoods = useGetNeighbourhoods(4, 0, neighbourhoodSlug);
    const [shuffledOtherNeighbourhoods, setShuffledOtherNeighbourhoods] = useState([]);
    const [seoSettings, setSeoSettings] = useState({});

    useEffect(() => {
        if(neighbourhood) {
            setSeoSettings(seoData({...neighbourhood, defaultTitle: `${neighbourhood.neighbourhood_title} Neighbourhood Guide - Sotheby's`}));
        }
    }, [neighbourhood]);

    useEffect(() => {
        if(Array.isArray(otherNeighbourhoods)) {
            let shuffled = [...otherNeighbourhoods];
            shuffleArray(shuffled);
            setShuffledOtherNeighbourhoods(shuffled);
        }
    }, [otherNeighbourhoods]);
    
    return (
        <div className="neighbourhood-guide">
            <SeoHelmet seoSettings={seoSettings} />
            
            {otherNeighbourhoods && neighbourhood && neighbourhood.document.document.map((componentData, index) => {
                
                return (
                    <div key={index}>
                        <DynamicComponent
                            componentData={componentData}
                        />
                        {shuffledOtherNeighbourhoods.length > 0 && componentData && componentData.component === "titleBlock" &&
                            <UnderTitleNavigation
                                nextLink={{url: shuffledOtherNeighbourhoods[0].slug, anchor:shuffledOtherNeighbourhoods[0].neighbourhood_title}}
                            />
                        }
                    </div>
                );
            })}

            
            { neighbourhood && (
            <NeighbourhoodGuideProperties 
                neighbourhoodTitle={neighbourhood.neighbourhood_title}
            />
            )}

            {otherNeighbourhoods && <OtherNeighbourhoods neighbourhoods={otherNeighbourhoods} />}
        </div>
    );
}

export default NeighbourhoodGuide;