import React from "react";
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_team.svg';
import {ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp_team.svg';
import {ReactComponent as EmailIcon } from '../../assets/icons/email_team.svg';
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import getGoogleDriveFileLink from "../../utils/getGoogleDriveFileLink";
import LazyBackground from "../../utils/LazyBackground";

const TeamMemberCard = ({ member, phone, whatsapp, cardType = "grid" }) => {
    const isMobileDevice = useMediaQuery({ query: '(max-width: 768px)' });
    const photo = member.photo && getGoogleDriveFileLink(member.photo);
    let height = 0;
    let width = 0;

    if(!isMobileDevice || (isMobileDevice && cardType === "slider"))
        height = 283;
    else
        height = 417;

    if(isMobileDevice && cardType === "grid")
        width = 335;
    else
        width = 227;

    return (
        
        <div key={member.id} className={`member-card ${cardType} w-full ${cardType === "slider" ? "max-w-[227px] md:max-w-[240px]" : "max-w-[335px] md:max-w-[227px]"}`}>
            <div className="member-slider-gap">
                <Link to={`/agent/${member.slug}`}>
                    <LazyBackground
                        className={`bg-cover bg-no-repeat bg-center mx-auto mb-4`}
                        backgroundImage={photo}
                        width={width}
                        height={height}
                    />
                </Link>
                <div className="border border-black border-t-0 border-l-0 border-r-0 h-10">
                    <p className="font-benton uppercase w-3/4 text-[11px] md:text-xs mx-auto pb-2 font-normal text-center tracking-[1.1px]">
                        {member.position}
                    </p>
                </div>
                <Link to={`/agent/${member.slug}`}>
                    <p className="text-xl mt-3.5 mb-4 leading-[20px] text-center font-acta">
                        <span className="block mb-[6px]">{member.firstname}</span>
                        <span>{member.lastname}</span>
                    </p>
                </Link>
                <div className="flex items-center justify-center my-4 md:my-[21px] gap-x-6">
                    {member.email &&
                        <span>
                            <a href={`mailto:${member.email}`}>
                                <EmailIcon />
                            </a>
                        </span>
                    }
                    {whatsapp && 
                        <span>
                            <a href={`https://wa.me/${whatsapp}`}>
                                <WhatsappIcon />
                            </a>
                        </span>
                    }
                    { phone &&
                        <span>
                           
                            <a href={`tel:${phone}`}>
                                <PhoneIcon />
                            </a>
                        </span>
                    }
                </div>
            </div>
            <div className="font-benton text-[13px] mr-4 text-center">
                {member.languages && member.languages.split(",").map((language, index) => (
                    <React.Fragment key={language}>
                        {index !== 0 && <span className="mx-2.5">|</span>}
                        <span>{language}</span>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default TeamMemberCard;