const ImageMosaic = ({ props }) => {
    let images = [];
    props.images.map(image => {
        images.push(image.data.url);
    })
    
    return (
        <div className="image-mosaic-block container small-container !mx-auto" id={props.anchor ? props.anchor : undefined}>
            {images && Array.isArray(images) && images.length === 1 && (
                <div className="mt-10 md:mt-14 w-full bg-cover bg-no-repeat bg-center h-[463px] md:h-[478px]" 
                style={{ backgroundImage: `url("${images[0]}")` }}></div>
            )}

            {images && Array.isArray(images) && (images.length === 2 || images.length === 3) && (
                <div className="flex flex-col md:flex-row gap-7 mt-10 md:mt-14 ">
                    <div className="w-full bg-cover bg-no-repeat bg-center h-[463px] md:h-[478px] md:grow md:shrink md:basis-[36%]" 
                    style={{ backgroundImage: `url("${images[0]}")` }}></div>

                    <div className="w-full bg-cover bg-no-repeat bg-center h-[463px] md:h-[478px] md:grow md:shrink md:basis-0" 
                    style={{ backgroundImage: `url("${images[1]}")` }}></div>
                </div>
            )}

            {images && Array.isArray(images) && images.length === 3 &&
                <div className="mt-7 w-full bg-cover bg-no-repeat bg-center h-[463px] md:h-[513px]" 
                style={{ backgroundImage: `url("${images[2]}")` }}></div>
            }
        </div>
    );
}

export default ImageMosaic;