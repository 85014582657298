import { ReactComponent as EmailIcon } from '../../assets/icons/team_member_email.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/team_member_whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/team_member_phone.svg';
import { useMediaQuery } from 'react-responsive';
import getGoogleDriveFileLink from '../../utils/getGoogleDriveFileLink';
import LazyBackground from '../../utils/LazyBackground';

const TeamMemberLeftInfo = ({ teamMember, phone, whatsapp }) => {
    const isMobileDevice = useMediaQuery({ query: '(max-width: 1024px)' });
    const languages = teamMember && teamMember.languages && teamMember.languages.split(",");

    return (
        <div className="w-full lg:max-w-[413px] lg:mr-[34px] flex flex-col lg:justify-start items-center pb-[26px] lg:pb-[43px] relative text-white font-benton font-normal">
            <LazyBackground
                className={"bg-cover bg-no-repeat bg-center w-[295px] lg:w-[335px] pt-[-102px] mb-3.5 lg:mb-7"}
                backgroundImage={getGoogleDriveFileLink(teamMember.photo)}
                width={isMobileDevice ? 295 : 335}
                height={isMobileDevice ? 340 : 417}
            />
            <div className="mb-3 lg:mb-3.5 uppercase text-[13px] leading-4 tracking-[1.1px] font-light md:font-normal">
                {teamMember.position}
            </div>
            <div className="mb-3 lg:mb-[19px] font-acta text-[32px] leading-[32px] font-light">
                {teamMember.firstname} {teamMember.lastname}
            </div>
            <div className="mb-3 lg:mb-[19px] flex gap-[27px]">
                <a href={`mailto:${teamMember.email}`}>
                    <EmailIcon />
                </a>
                <a href={`https://wa.me/${whatsapp}`}>
                    <WhatsappIcon />
                </a>
                <a href={`tel:${phone}`}>
                    <PhoneIcon />
                </a>
            </div>
            <div className="mb-3.5 text-base font-normal md:font-light">
                {languages && languages.map((language, index) => (
                    <span key={index}>
                        {index !== 0 &&
                            <span className="mx-2.5">
                                |
                            </span>
                        }
                        {language}
                    </span>
                ))
                }
            </div>
            { teamMember.licence && parseInt(teamMember.licence) > 0 &&
                <div className="uppercase text-[13px] leading-4 tracking-[1.1px]">
                    License no: {teamMember.licence}
                </div>
            }
            <div className={`absolute bg-sothebys-blue left-0 w-full  z-[-1]`} style={{ height: `calc(100% - ${isMobileDevice ? 186 : 102}px)`, top: `${isMobileDevice ? 186 : 102}px` }}></div>
        </div>
    );
}

export default TeamMemberLeftInfo;