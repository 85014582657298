import PropertiesBigSlider from "../PropertiesModule/PropertiesBigSlider";
import PropertiesList from "../PropertiesModule/PropertiesList";
import PropertiesList3Columns from "../PropertiesModule/PropertiesList3Columns";

const SearchProperties = ({ properties, columns }) => {
    console.log('columns', columns)
    return (
        <div className="mb-12">
            {parseInt(columns) === 2 ? <PropertiesList properties={properties} /> : 
            <PropertiesList3Columns properties={properties} />}
        </div>
    );
}

export default SearchProperties;