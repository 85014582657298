import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ActionButton = ({ url, label, align = "center" }) => {
  const isExternal = url.startsWith("https://");

  return (
    <div className={`action-button mt-[30px] md:mt-[50px] flex justify-${align}`}>
      {isExternal ? (
        <a href={url} className="button inline-block">
          {label}
        </a>
      ) : (
        <Link to={url} className="button inline-block">
          {label}
        </Link>
      )}
    </div>
  );
};

export default ActionButton;
