import { useState, useEffect } from 'react';
import OurOffices from './OurOffices';
import { sendEmail } from '../../utils/sendEmail';
import { useGetSettings } from '../../queries/Menus';

const ContactForm = ({ props }) => {
    const getSettings = useGetSettings();
    const [settings, setSettings] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formValid, setFormValid] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSearchClick = () => {
        setShowResults(true);
    }

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        message: "",
    });

    const handleNewEnquiry = () => {
        setFormSubmitted(false);
        setFormData({
            fullName: "",
            email: "",
            phone: "",
            message: "",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        setFormErrors({ ...errors, emailSending: null });

        if(Object.keys(errors).length === 0) {
            const sendingResult = await sendEmail({
                from: formData.email,
                to: settings.email,
                senderName: formData.fullName,
                subject: "[Sothebys UK] New Enquiry from Contact Us Page",
                message: formData.message,
                phone: formData.phone
            });
            
            if(sendingResult.success) {
                setFormValid(true);
                setFormSubmitted(true);
            } else {
                setFormValid(false);
                setFormErrors(prevErrors => ({ ...prevErrors, emailSending: sendingResult.error }));
            }
        } else {
            setFormValid(false);
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if(!data.fullName) errors.fullName = 'Full name is required';
        if(!data.email) errors.email = 'Email is required';
        if (!data.phone) {
            errors.phone = 'Phone is required';
        } else if (!/^\+?[0-9\s-]*$/.test(data.phone)) {
            errors.phone = 'Invalid phone number';
        }
        if(!data.message) errors.message = 'Message is required';
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if(getSettings && Object.keys(getSettings).length > 0) {
          setSettings(getSettings);
            console.log(getSettings)
        }
    }, [getSettings]);
    
    let offices = [];
    props.offices.map(office => offices.push(office.office.data));

    return (
        <div>
            {/*<div className="container my-12 text-4xl text-[px] text-center">
                Contact us
            </div>*/}
            <div className="wide-container !max-w-[1518px] bg-sothebys-blue text-white border-0 flex flex-col lg:flex-row" id={props.anchor ? props.anchor : undefined} >
                <div className="w-full lg:w-1/2 pt-12 pb-[46px] lg:pb-[72px] lg:pl-5 xl:pl-[180px] font-normal flex justify-center lg:justify-start">
                    <div className="container !max-w-[461px] xl:!ml-0">
                        <div className="mb-6 text-2xl">
                            {props.title}
                        </div>
                        {formSubmitted ? (
                            <div className="flex flex-col justify-between h-full">
                                <div className="mb-5 font-benton text-[15px] leading-[22px] tracking-[0.375px]">
                                    Enquiry sent, thank you for reaching out. We appreciate your inquiry and will get back to you soon.
                                </div>
                                <div 
                                    className="button inline-block w-fit hover:bg-white hover:text-black !px-[30px] !py-[15px] !border-[#888888] leading-[25px] mb-[60px]"
                                    onClick={handleNewEnquiry}
                                >
                                    Send a new enquiry
                                </div>
                            </div>
                        ) : (
                            <>
                            <div className="mb-5 font-benton text-[15px] leading-[22px] tracking-[0.375px]">
                                {props.paragraph}
                            </div>
                            <form className="font-benton" onSubmit={handleSubmit}>
                                <input 
                                    type="text" 
                                    name="fullName"
                                    className={`contact-us-field w-full h-[58px] rounded mb-5 lg:mb-4 pl-3 ${formErrors.fullName ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                                    placeholder="Full name" 
                                    value={formData.fullName}
                                    onChange={handleChange}
                                />
                                <input 
                                    type="email" 
                                    name="email"
                                    className={`contact-us-field w-full h-[58px] rounded mb-5 lg:mb-4 pl-3 ${formErrors.email ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                                    placeholder="E-mail address" 
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <input 
                                    type="tel" 
                                    name="phone"
                                    className={`contact-us-field w-full h-[58px] rounded mb-5 lg:mb-4 pl-3 ${formErrors.phone ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                                    placeholder="+44" 
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <textarea 
                                    className={`contact-us-field w-full h-[100px] rounded mb-8 lg:mb-4 pl-3 pt-4 resize-none ${formErrors.message ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                                    name="message"
                                    placeholder="How can we help?" 
                                    value={formData.message}
                                    onChange={handleChange}
                                >
                                </textarea>

                                {!formValid && (
                                    <div className="mb-4">
                                        Please add details on all fields marked <span className="text-[#FF1414]">red</span>.
                                        {Object.values(formErrors).map((error, index) => {
                                            return (<p key={index}>{error}</p>);
                                        })}
                                    </div>
                                )}

                                <button type="submit" className="button hover:bg-white hover:text-black !px-8 !py-[15px] !border-[#888888] leading-[25px]">Send enquiry</button>
                            </form>
                            </>
                        )}
                    </div>
                </div>
                <div className="w-full lg:w-1/2 pt-0 lg:pt-12 pb-9 lg:pb-12">
                    <OurOffices title={"Our offices"} offices={offices} />
                </div>
            </div>
        </div>
    );
}

export default ContactForm;