import { useQuery, gql } from '@apollo/client';

export const useGetHome = () => {
  const GET_HOME = gql`
    query Document {
      home {
        document {
          document(hydrateRelationships: true)
        }
        OGTitle
        OGDescription
        PageTitle
        PageDescription
        PageKeywords
        TwitterImage {
          url
        }
        FacebookImage {
          url
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_HOME);

  if(loading || error) {
    return null;
  }

  return data.home;
}

export const useGetAboutUs = () => {
  const GET_ABOUT_US = gql`
  query AboutUs {
    aboutUs {
      PageTitle
      slug
      OGTitle
      OGDescription
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
      document {
        document(hydrateRelationships: true)
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_ABOUT_US);

  if(loading || error) {
    return null;
  }
  
  return data.aboutUs;
}

export const useGetContactUs = () => {
  const GET_CONTACT_US = gql`
  query ContactUs {
    contactUs {
      PageTitle
      slug
      OGTitle
      OGDescription
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
      document {
        document(hydrateRelationships: true)
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_CONTACT_US);

  if(loading || error) {
    return null;
  }

  return data.contactUs;
}

export const useGetSellWithUs = () => {
  const GET_SELL_WITH_US = gql`
  query SellWithUs {
    sellWithUs {
      id
      slug
      OGTitle
      OGDescription
      PageTitle
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
      document {
        document(hydrateRelationships: true)
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_SELL_WITH_US);

  if(loading || error) {
    return null;
  }

  return data.sellWithUs;
}

export const useGetPrivacyPolicy = () => {
  const GET_PRIVACY_POLICY = gql`
  query PrivacyPolicy {
    privacyPolicy {
      id
      page_title
      page_content {
        document(hydrateRelationships: true)
      }
      slug
      OGTitle
      OGDescription
      PageTitle
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_PRIVACY_POLICY);

  if(loading || error) {
    return null;
  }

  return data.privacyPolicy;
}

export const useGetTermsOfUse = () => {
  const GET_TERMS_OF_USE = gql`
  query TermsOfUse {
    termsOfUse {
      id
      page_title
      page_content {
        document(hydrateRelationships: true)
      }
      slug
      OGTitle
      OGDescription
      PageTitle
      PageDescription
      PageKeywords
      TwitterImage {
        url
      }
      FacebookImage {
        url
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_TERMS_OF_USE);

  if(loading || error) {
    return null;
  }

  return data.termsOfUse;
}

export const useGetGDPRPopup = (shouldFetch) => {
  const GET_GDPR_POPUP = gql`
  query PrivacyPolicy {
    privacyPolicy {
      id
      popup_description {
        document
      }
      popup_title
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_GDPR_POPUP, { 
      variables: {},
      skip: !shouldFetch,
  });

  if(loading || error || !data) {
    return null;
  }

  return data.privacyPolicy;
}

export const useGetLanding = (slug) => {
  const GET_LANDING = gql`
    query PropertyLandingPage($where: PropertyLandingPageWhereUniqueInput!) {
      propertyLandingPage(where: $where) {
        title
        slug
        OGTitle
        OGDescription
        PageTitle
        PageDescription
        PageKeywords
        TwitterImage {
          url
        }
        FacebookImage {
          url
        }
        document {
          document(hydrateRelationships: true)
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LANDING, {
      variables: {
          "where": {
            "slug": slug
          }
      },
  });

  if(loading || error) {
    return null;
  }

  return data.propertyLandingPage;
}