const TitleBlock = ({ props }) => {
    let textAlignment ="items-start";

    if(props.text_alignment === "center")
        textAlignment = "items-center";
    if(props.text_alignment === "right")
        textAlignment = "items-end";

    return (
        <div id={props.anchor ? props.anchor : undefined}  className={`title-block container small-container md:!mx-auto flex flex-col ${textAlignment} gap-5 text-center`} style={{ whiteSpace: 'pre-line' }}>
            {props.overtitle && 
                <p className={`font-benton text-sm uppercase tracking-widest font-normal mb-0 ${props.overtitle_underline ? "underline" : ""
                }`} dangerouslySetInnerHTML={{ __html: props.overtitle }}>
                </p>
            }
            <h1 className="text-[32px] md:text-[54px] leading-[48px] md:leading-[72px]" dangerouslySetInnerHTML={{ __html: props.title }}>
            </h1>
            <p className="font-benton text-[20px] leading-[30px] text-[#0A0A0A] font-normal" dangerouslySetInnerHTML={{ __html: props.paragraph }}>
            </p>
        </div>
    );
}

export default TitleBlock;