import { ReactComponent as FooterLogo } from '../../assets/images/footer_logo_uk.svg';
import { Link } from 'react-router-dom';

const FooterInfo = () => {
    return (
        <div className="md:border md:border-x-0 md:border-b-0 md:border-t border-footer-line mx-auto text-center">
            <Link to="/" className="pointer-events-none">
                <FooterLogo className="mx-auto mt-8 md:mt-11 w-48 inline-block pointer-events-auto" />
            </Link>
            <p className="mt-9 max-w-3xl mx-auto font-normal font-benton tracking-[0.375px] text-[#111111] text-xs">SOTHEBY'S INTERNATIONAL REALTY LTD.<br />
            Registered Office: 48 Conduit Street, London, England, W1S 2YR
            </p>
        </div>
    );
}

export default FooterInfo;