import { useEffect } from "react";
import JournalsListing from "../components/Journals/JournalsListing";
import JournalsMenu from "../components/Journals/JournalsMenu";
import { useMediaQuery } from "react-responsive";
import { useGetJournalsCategories, useGetJournals } from "../queries/Journals";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Journals = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { category } = useParams();

    useEffect(() => {
        document.title = "Journal - Sotheby's";
    }, []);

    const journalsCategories = useGetJournalsCategories();
    const { data: journals, isLoading, isError } = useGetJournals(11, 0, category);
    
    return (
        <div className={`journals ${!isMobile ? "container" : ""} md:mt-[35px] flex flex-col md:flex-row md:gap-[37px]`}>
            <div className="md:flex-grow min-w-min md:max-w-[335px]">
                <h1 className="h-[50px] text-[32px] md:text-[48px] leading-[48px] mb-2.5 md:mb-[51px] text-center md:text-left">Journal</h1>
                {journalsCategories && <JournalsMenu menu={journalsCategories} />}
            </div>
            <div className="flex-grow w-full md:max-w-[1068px]">
                {isLoading && <div>Loading Journals . . .</div>}
                {isError && <div>An error occured!</div>}
                {journals && journals.length > 0 && <JournalsListing journals={journals} />}
                {journals && journals.length === 0 &&
                    <div className={`${isMobile ? "container mt-5" : ""}`}>No journals available in this category</div>
                }
            </div>
        </div>
    );
}

export default Journals;