import { useQuery, gql } from '@apollo/client';
import { useEffect } from 'react';

export const useGetNeighbourhood = (slug) => {
    const GET_NEIGHBOURHOOD = gql`
      query Neighbourhood($where: NeighbourhoodWhereUniqueInput!) {
        neighbourhood(where: $where) {
          document {
            document(hydrateRelationships: true)
          }
          id
          slug
          neighbourhood_title
          OGTitle
          OGDescription
          PageDescription
          PageTitle
          PageKeywords
          TwitterImage {
            url
          }
          FacebookImage {
            url
          }
        }
      }`;
        
    const { loading, error, data } = useQuery(GET_NEIGHBOURHOOD, {
        variables: {
            "where": {
                "slug": slug
            }
        },
    });

    if(loading || error) 
        return null;

    return data.neighbourhood;
}

export const useGetNeighbourhoodByTitle = (title, shouldRefetch) => {
  const GET_NEIGHBOURHOOD = gql`
    query Neighbourhood($where: NeighbourhoodWhereInput!) {
      neighbourhoods(where: $where) {
        document {
          document(hydrateRelationships: true)
        }
        id
        slug
        neighbourhood_title
        OGTitle
        OGDescription
        PageDescription
        PageTitle
        PageKeywords
        TwitterImage {
          url
        }
        FacebookImage {
          url
        }
        Banner_url
        banner_image {
          url
        }
        Banner_label
      }
    }`;
      
  const { data, loading, error, refetch } = useQuery(GET_NEIGHBOURHOOD, {
      variables: {
          "where": {
              "neighbourhood_title": {
                "equals": title
              }
          }
      },
  });

  useEffect(() => {
    refetch();
  }, [shouldRefetch]);

  if(loading || error) 
      return null;

  return data.neighbourhoods[0] ? data.neighbourhoods[0] : null;
}

export const useGetNeighbourhoods = (limit = 3, offset = 0, excludeSlug = false) => {
  const GET_NEIGHBOURHOODS = gql`
  query Listing_image($take: Int, $skip: Int!, $where: NeighbourhoodWhereInput) {
    neighbourhoods(take: $take, skip: $skip, where: $where) {
      listing_images {
        url
      }
      id
      slug
      neighbourhood_title
    }
  }`;

  let variables = {};
  
  if(limit > 0)
    variables.take = limit;

  variables.skip = offset;

  if(excludeSlug)
    variables.where = {
      "slug": {
        "not": {
          "equals": excludeSlug
        }
      }
    };
      
    const { loading, error, data } = useQuery(GET_NEIGHBOURHOODS, {
      variables,
  });

  if(loading || error) 
      return null;

  return data.neighbourhoods;
}

export const useGetRegions = () => {
  const GET_REGIONS = gql`
    query Post {
      regions {
        id
        title
        slug
        parent {
          id
          title
          slug
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_REGIONS, { 
      variables: {
        
      },
  });

  if(loading || error) {
    return null;
  }

  const newData = [
    {
      "id": 0,
      "title": "All Neighbourhoods",
      "slug": "",
      "parent": null
    },
    ...data.regions 
  ];

  return newData;
}

export const useGetNeighbourhoodsByCategory = (categorySlug) => {
  const GET_NEIGHBOURHOODS = gql`
    query Neighbourhoods($where: NeighbourhoodWhereInput) {
      neighbourhoods(where: $where) {
        id
        region {
          title
          slug
          parent {
            title
            slug
          }
        }
        slug
        neighbourhood_title
        listing_images {
          url
        }
        excerpt {
          document(hydrateRelationships: true)
        }
      }
    }
  `;

  let variables = {};

  if (categorySlug) {
    variables = {
      "where": {
        OR: [
          {
            region: {
              parent: {
                slug: {
                  equals: categorySlug
                }
              }
            }
          },
          {
            "region": {
              "slug": {
                "equals": categorySlug
              }
            }
          }
        ]
      }
    };
  }

  const { loading, error, data } = useQuery(GET_NEIGHBOURHOODS, { 
    variables,
  });

  return {
    isLoading: loading,
    isError: error,
    data: data ? data.neighbourhoods : null
  }
}