import PropertiesTop from "../PropertiesModule/PropertiesTop";
import PropertiesBigSlider from "../PropertiesModule/PropertiesBigSlider";
import PropertiesList from "../PropertiesModule/PropertiesList";
import { useState } from "react";
import { useGetHomeFeaturedProperties, useGetHomePopularProperties, useGetHomeNewProperties } from "../../queries/Properties";
import PropertiesList3Columns from "../PropertiesModule/PropertiesList3Columns";

const FeaturedProperties = ({ props }) => {
    const [activeTab, setActiveTab] = useState(0);
    let featuredPropertiesData = [];
    let popularPropertiesData = [];

    props.featured_listings.map((property, index) => {
        featuredPropertiesData[index] = property.data;
    });

    props.popular_listings.map((property, index) => {
        popularPropertiesData[index] = property.data;
    });

    const newPropertiesData = useGetHomeNewProperties(featuredPropertiesData && featuredPropertiesData.length ? featuredPropertiesData.length : 0);
    
    const propertiesData = {
        featured: featuredPropertiesData || [],
        popular: popularPropertiesData || [],
        new: newPropertiesData || []
    }

    const handleTabChange = (index) => {
        setActiveTab(index);
    }

    const tabs = ["Featured", "Popular", "New"];
    
    return (
        <div className={`homepage-properties columns-${props.featured_columns}`}>
            {tabs && <PropertiesTop propertyTypeTitle={"Featured Properties"} propertyTypes={tabs} onTabChange={handleTabChange} activeTab={activeTab} />}
            {tabs.map((tab, index) => {
                const properties = [...propertiesData[tab.toLowerCase()]];
                console.log('properties', properties);
                return (
                    <div key={index} className={`tab-content ${index === activeTab ? "active" : "inactive"}`}>
                        {properties && <PropertiesBigSlider property={properties[0]} />}
                        {properties && parseInt(props.featured_columns) === 2 && <PropertiesList properties={properties.slice(1)} key={properties.length} />}
                        {properties && parseInt(props.featured_columns) === 3 && <PropertiesList3Columns properties={properties.slice(1)} key={properties.length} />}
                    </div>
                );
            })}
        </div>
    );
}

export default FeaturedProperties;