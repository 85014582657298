import JournalCard from "./JournalCard";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';
import getArticleReadingTime from "../../utils/getArticleReadingTime";

const JournalsListing = ({ journals }) => {
    const isWideScreen = useMediaQuery({ query: '(min-width: 1536px)' });
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1535px)' });
    const [typesGrid, setTypesGrid] = useState([]);
    
    useEffect(() => {
        if(isWideScreen)
            setTypesGrid(["heading", "regular", "regular", "regular", "double", "regular", "regular", "regular", "regular", "regular", "double"]);

        if(isMediumScreen)
            setTypesGrid(["double", "regular", "regular", "double", "double", "regular", "regular", "double", "regular", "regular", "regular"]);
    }, [isWideScreen, isMediumScreen]);

    return (
        <>
            <div className="flex md:gap-x-[30px] gap-y-0 flex-wrap justify-center md:justify-start">
                {journals.map((journal, index) => {
                    const readTime = getArticleReadingTime(journal.document.document);
                    return (
                        <JournalCard key={journal.id}
                            id={journal.id}
                            type={typesGrid[index]}
                            page={"listing"}
                            photo={journal.featuredImages[0] ? journal.featuredImages[0].url : ""} 
                            photo2={journal.featuredImages[1] ? journal.featuredImages[1].url : false}
                            category={journal.category}
                            title={journal.title}
                            excerpt={journal.excerpt.document}
                            createdAt={journal.createdAt}
                            readTime={readTime}
                            slug={journal.slug}
                        />
                    );
                })}
            </div>
            {/*<div className="my-[83px] flex justify-center">
                <span className="button !mx-[30px]">Load more</span>
            </div>*/}
        </>
    );
}

export default JournalsListing;