import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as BackIcon } from '../../assets/icons/property_back.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/share_linkedin.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/share_email.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/share_twitter.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/share_facebook.svg';
import useBackButton from '../../utils/useBackButton';
import { useMediaQuery } from 'react-responsive';
import { convertDate } from "../../utils/dateUtils";
import { useLocation } from 'react-router-dom';

const getSocialSharing = ({ props, location }) => {
    const url = encodeURIComponent(window.location.origin + location.pathname);
    const title = props.title;
    const LinkedinShareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=&source=`;
    const FacebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const TwitterShareURL = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
    const EmailShareURL = `mailto:?subject=${title}&body=Check%20out%20this%20link:%20${url}`;

    return (
        <div className="flex flex-row md:flex-col gap-x-[22px] md:gap-x-0 md:gap-y-2.5">
            <div>
                <a href={LinkedinShareURL} target="_blank" rel="noopener noreferrer" className="inline-block">
                    <LinkedinIcon className="social-sharing-icon transition-all duration-300" />
                </a>
            </div>
            <div>
            <a href={FacebookShareURL} target="_blank" rel="noopener noreferrer" className="inline-block">
                    <FacebookIcon className="social-sharing-icon transition-all duration-300" />
                </a>
            </div>
            <div>
                <a href={TwitterShareURL} target="_blank" rel="noopener noreferrer" className="inline-block">
                    <TwitterIcon className="social-sharing-icon transition-all duration-300" />
                </a>
            </div>
            <div>
                <a href={EmailShareURL} target="_blank" className="inline-block">
                    <EmailIcon className="social-sharing-icon transition-all duration-300" />
                </a>
            </div>
        </div>
    );
};

const JournalHeader = ({ props }) => {
    const goBack = useBackButton();
    const location = useLocation();
    const isJournalPage = location.pathname.includes('/journal/');
    const isMediaArticlePage = location.pathname.includes('/pr-article/');
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const date = convertDate(props.createdAt, "MMMM do, yyyy");
    
    return (
        <div className={`${isMobile ? "wide-container" : "container"} mb-12 mt-8 flex flex-col-reverse md:flex-row md:gap-x-[30px]`}>
            <div className={`${isMobile ? "container" : ""} w-full mt-7 md:mt-0 md:w-1/2 flex flex-col md:justify-between`}>
                <div>
                    { props.title &&
                        <h1 className="text-black text-[44px] leading-[48px] 2xl:w-11/12">{props.title}</h1>
                    }

                    {/* Mobile View Socials */}
                    <div className="md:hidden">
                        {getSocialSharing({ props, location })}
                    </div>

                    { props.category &&
                        <p className="mt-11 md:mt-8 text-[#888888] font-benton text-[15px] leading-[22px] font-normal">
                            <Link to={isJournalPage ? "/journals" : "/media-pr"}>{isJournalPage ? "Journals" : "Articles"}</Link>  /  <Link to={isJournalPage ? `/journals/${props.category.slug}` : `/media-pr/${props.category.slug}`}>{props.category.title}</Link>
                        </p>
                    }

                    { props.author &&
                        <p className="mt-2.5 text-black font-benton text-[13px] leading-[20px] font-medium">by {props.author.name}</p>
                    }

                    { props.readTime > 0 &&
                        <p className="mt-2.5 text-black font-benton text-[13px] leading-[20px] font-medium">Reading time: {props.readTime}min</p>
                    }

                    { date &&
                        <p className="mt-2.5 text-black font-benton text-[13px] leading-[20px] font-medium mb-0">{date}</p>
                    }

                    {/* Desktop View Socials */}
                    <div className="hidden md:block mt-12">
                        {getSocialSharing({ props, location })}
                    </div>
                </div>
                <div className="mt-5 md:mt-0 flex items-center">
                    <Link to="#">
                        <BackIcon className="self-center" onClick={goBack} />
                    </Link>
                    <Link to="#">
                        <span 
                            className="ml-3.5 font-benton text-[15px] leading-[22px] text-[#888888] tracking-wide font-normal flex items-center hover:font-medium"
                            onClick={goBack}    
                        >BACK</span>
                    </Link>
                </div>
            </div>
            { props.image && 
                <div className="w-full flex md:w-1/2">
                    <div 
                        className="w-full bg-cover bg-no-repeat bg-center max-w-[702px] h-[375px] md:h-[737px]" 
                        style={{ backgroundImage: `url("${props.image}")` }}
                    ></div>
                </div>
            }
        </div>
    );
}

export default JournalHeader;