const TeamMemberJournal = ({journals}) => {
    
    
    return (
        <div>
            <h3 className="mt-[100px] mb-8 md:my-12 text-[36px] leading-[36px]">Journal / Press</h3>
            <div>
                {journals.map((journal, index) => (
                    <div key={index} className={`max-w-[545px] flex flex-col font-benton ${index !== 0 ? "mt-12" : ""}`}>
                        <span className="mb-3 tracking-[1.1px] text-[11px] leading-[26px] font-normal uppercase border border-black border-t-0 border-x-0 w-fit">{journal.publisher}</span>
                        <span className="mb-3 font-acta text-xl">{journal.title}</span>
                        <span className="mb-3 text-[15px] leading-5 font-normal tracking-[0.2px]">{journal.description}</span>
                        <span className="font-benton font-bold text-[13px] leading-5">{journal.date}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeamMemberJournal;