import { gql, useQuery, useLazyQuery } from '@apollo/client';

export const useGetUser = () => {
    const GET_USER =  gql`
    query Users($where: UserWhereInput!) {
        users(where: $where) {
          id
          name
          email
        }
      }
    `;
  
    const [getUser, { loading, error, data }] = useLazyQuery(GET_USER); 
  
    return {
      getUser: (email) => getUser({
        variables: {
          where: {
            email: {
              equals: email
            }
          }
        }
      }),
      isLoading: loading,
      isError: error,
      data: data ? data.users : null
    }
}

/*export const useGetUser = (email) => {
    const GET_USER = (() => gql`
    query Users($where: UserWhereInput!) {
        users(where: $where) {
          id
          name
          email
        }
      }
    `, []);
  
    const { loading, error, data } = useLazyQuery(GET_USER, {
        "where": {
          "email": {
            "equals": email
          }
        }
      });
  
    return {
      isLoading: loading,
      isError: error,
      data: data ? data.users : null
    }
}*/