import ActionButton from "../ContentBlocks/ActionButton";
import JournalCard from "../Journals/JournalCard";

const Journal = ({ props }) => {
    let journals = [];
    const currentTime = new Date().getTime();
    
    props.articles.map(article => {
        const targetTime = article.data.publishedAt !== null ? new Date(article.data.publishedAt).getTime() : null;

        if(article.data && article.data.status === "published" && (article.data.publishedAt === null || targetTime === null || currentTime > targetTime))
            journals.push(article.data);
    });
    console.log('journals', props);
    return (
        <div className="journal container" id={props.anchor ? props.anchor : undefined} >
            {props.title && <h2 className="font-regular font-normal md:font-light text-xl md:text-2xl mt-7 mb-10 md:mt-12 md:mb-12 text-center">{props.title}</h2>}
            {/*<div className="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-4 justify-between gap-x-8 justify-items-center items-center">*/}
            <div className="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-4 items-center md:items-stretch justify-start md:justify-items-center md:gap-8 mb-[45px] md:mb-[90px] md:justify-between">
                {journals && journals.map((journal, index) => (
                    <JournalCard 
                        key={index}
                        id={journal.id}
                        photo={journal.featuredImages[0] ? journal.featuredImages[0].url : ""} 
                        category={journal.category}
                        title={journal.title}
                        excerpt={journal.excerpt.document}
                        createdAt={journal.createdAt}
                        slug={journal.slug}
                        readTime={journal.readingTime}
                    />
                ))}
            </div>

            {props.action && props.action.label && props.action.url && (
                <ActionButton url={props.action.url} label={props.action.label} />
            )}
        </div>
    );
}

export default Journal;