import PropertyCard from "../Property/PropertyCard";

const SavedSearchResults = ({ search }) => {
    const property = {
        address: "",
        bedrooms: 2,
        city_propertyfinder:"London",
        community_propertyfinder:"Nine Elms",
        currency_iso_code:"GBP",
        description:"United Kingdom Sotheby’s International Realty are delighted to present this luxurious lateral two-bedroom apartment available for sale in AYKON London ONE, a new level of luxury living in the heart of the Capital.\n\nAs one of the most established real estate development companies in the Middle East, DAMAC in exclusive partnership with Versace Home have collaborated for the first time to bring the pinnacle in branded living experiences to London. This two-bedroom apartment is situated on the 30th floor of this 50-storey iconic tower and features a spacious living room, a fully fitted kitchen, two spacious bathrooms (one en-suite) and two winter gardens offering incredible panoramic views of London.\n\nThe first-class, high-spec facilities include over 8,000 sq. ft. of communal garden space, an indoor swimming pool and jacuzzi, a residents’ lounge, a state-of-the-art gym and a children’s play area. There is also a 24-hour concierge, parking and storage available, and all of this is right by a vast network of public transportation options, riverside walks, shops and restaurants.\n\nTo view this amazing property, contact us today.",
        fullbathrooms:2,
        idField:"UK-S-39399",
        listing_agent_email:"jaishree.bricepf@sothebysrealty.co.uk",
        listing_id: "",
        listing_title: "Flat 3004, 63-71 Bondway, Nine Elms, SW8 1SQ.",
        listingprice: "1250000.0000",
        listingtype: "Sale",
        postalcode: "SW8 1SQ",
        property: "a0O7T000006BiDeUAK",
        property_propertyfinder: "Bondway",
        propertytype: "Apartment",
        reference_number: "39399",
        state: "N/A",
        status: "Published",
        sub_community_propertyfinder: "Bondway",
        tags: [],
        totalarea: "834.0000",
        media: [
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/haja8jrj0/0_Exterior-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/cfw90ihhd/0_Exterior-1.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/gjqcv793v/1_Reception-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/zj4zqs6pl/1_Reception-1.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/f5konq359/10_Swimming%20Pool%20%28Internal%29-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/fnp967h9b/11_sauna-steam%20room-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/6stpwqxmx/12_Commual%20balcony-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/iemtkb5nt/13_Gym-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/y6b0tp0ig/2_Sunroom%201-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/xovh0lenf/4_Kitchen-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/8oewafv8a/5_Bedroom%202-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/kpyh8lgd9/6_Main%20Bedroom-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/wo70xlbya/8_Main%20Ensuite-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/ng0r3ow4j/9_Shower%20Room-0.jpg",
        ]
    };

    const properties = [property, property, property];

    return properties.length > 0 ? (
        <div>
            {properties?.length > 0 && (
                    <div className="grid gap-5 md:gap-[50px] xl:gap-[43px] grid-cols md:grid-cols-2 xl:grid-cols-3">
                    {properties.map((property, index) => (
                        <div key={index}>
                            <PropertyCard property={property} />
                        </div>
                    ))}
                    </div>
                )
            }
            <div className="mt-[50px] pb-[50px] flex justify-center">
                <span className="button inline-block">
                    View all saved properties
                </span>
            </div>
            
        </div>
    ) : "<div>There are no properties to show</div>";
};

export default SavedSearchResults;