import { useQuery, gql } from '@apollo/client';

export const useGetMenu = () => {
  const GET_MENU = gql`
    query Menus {
      menus {
        id
        parent {
          id
        }
        parentCount
        title
        url
        order
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_MENU, { 
      variables: {
        
      },
  });

  if(loading || error) {
    return null;
  }

  return data.menus;
}

export const useGetSettings = () => {
  const GET_SETTINGS = gql`
    query Settings {
      setting {
        id
        email
        phone
        whatsapp
        currencies {
          iso_code
        }
        columns
        relatedLinks
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_SETTINGS);

  if(loading || error) {
    return null;
  }

  return data.setting;
}