import useSticky from '../../utils/useSticky';
import { useState, useRef } from 'react';
import Slider from "react-slick";
import { getCurrencySymbol, formatPriceOutput } from '../../utils/currency';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as CloseIcon } from '../../assets/icons/cross_icon.svg';
import LazyBackground from '../../utils/LazyBackground';
import GalleryPortal from '../../utils/GalleryPortal';
import { ReactComponent as SliderPrevArrowBlack } from "../../assets/icons/slider_prev_black.svg";
import { ReactComponent as SliderNextArrowBlack } from "../../assets/icons/slider_next_black.svg";
import { useMediaQuery } from 'react-responsive';
import LazyFullscreenBackground from '../../utils/LazyFullscreenBackground';
import PropertyPopupLinks from './PropertyPopupLinks';
import PropertyHero from './PropertyHero';

const PropertyTopInfo = ({ 
    property, 
    agent,
    //activeImageIndex, 
    //openRequestInformation,
    }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1025px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isSticky = useSticky(isMobile ? 0 : 30);
    const [fullscreenGallery, setFullscreenGallery] = useState(null);
    const [fullscreenFloorplanGallery, setFullscreenFloorplanGallery] = useState(null);
    const currencySymbol = getCurrencySymbol(property.currency_iso_code);
    const formattedPrice = formatPriceOutput(property.listingprice);
    const floorplanIndex = property.media.findIndex(imageUrl => imageUrl.includes("Floorplan"));
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(activeImageIndex);
    const sliderRef = useRef(null);
    const [isRequestInformationActive, setIsRequestInformationActive] = useState(false);
    const fullscreenWidth = isBigScreen ? window.innerWidth - 74 * 2 : window.innerWidth;
    const fullscreenHeight = isBigScreen ? window.innerHeight - 40 * 2 : window.innerHeight - 88 * 2;
    console.log('fullscreenWidth', fullscreenWidth);
    console.log('fullscreenHeight', fullscreenHeight);
    
    const openRequestInformation = () => {
        setIsRequestInformationActive(true);
    }

    const closeRequestInformation = () => {
        setIsRequestInformationActive(false);
    }

    const handleActiveImageIndexChange = (index) => {
        setActiveImageIndex(index);
    }

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    };

    const openFullscreen = (startIndex) => {
        document.body.style.overflow = 'hidden';
        setFullscreenGallery(startIndex);
        setCurrentSlide(startIndex);
        window.scrollTo(0, 0);
    }

    const closeFullscreen = () => {
        document.body.style.overflow = 'auto';
        setFullscreenGallery(null);
    }

    const openFullscreenFloorplan = () => {
        document.body.style.overflow = 'hidden';
        setFullscreenFloorplanGallery(true);
        console.log(fullscreenFloorplanGallery);
        window.scrollTo(0, 0);
    }

    const closeFullscreenFloorplan = () => {
        document.body.style.overflow = 'auto';
        setFullscreenFloorplanGallery(null);
    }

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
        adaptiveHeight: false,
        beforeChange: (current, next) => setCurrentSlide(next)
    };

    const settingsFloorplans = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
        beforeChange: (current, next) => setCurrentSlide(next)
    };
console.log(property)
/*<div className={`${isSticky && !activeImageIndex ? 'property-info-fixed' : ''}`}>*/
    return (
        <>
            {isSticky && <div style={{ height: `${isMobile || isTablet ? 30 : 40}px` }} />}

            <div className={`top-property-info ${(!isMobile && !isTablet) && isSticky? 'property-info-fixed' : ''}`}>
                <div className="container">
                    <div className="container mb-[5px] mt-[0] lg:-mt-[15px] flex-col lg:flex-row flex justify-between items-center">
                        <div className="flex flex-col">
                            <div className={`text-center lg:text-left ${(isMobile || isTablet) && isSticky? 'property-info-fixed' : ''}`}>
                                <span className="text-[#1D1D1B] text-[19px] lg:text-[18px] leading-[30px] mt-[2px] font-medium font-benton tracking-[-0.02em]">
                                {property.propertytype !== "None" ? `${property.propertytype}, ` : ''}{property.community_propertyfinder}, {property.city_propertyfinder}
                                </span>
                            </div>
                            <div className="flex justify-between mt-[6px] lg:mt-0">
                                <div>
                                    <p className="flex flex-wrap gap-x-5 gap-y-1 lg:gap-x-7 leading-5 lg:leading-[22px] font-benton font-normal text-black capitalize tracking-[1.5px] text-[14px] mb-[12px] lg:mb-[3px] justify-center lg:justify-start">
                                        {(property.listingprice > 0) &&
                                            <span>
                                                {currencySymbol}{formattedPrice}
                                            </span>
                                        }
                                        {(property.bedrooms > 0) &&
                                            <span>
                                                {property.bedrooms} beds
                                            </span>
                                        }
                                        {(property.fullbathrooms > 0) &&
                                            <span>
                                                {property.fullbathrooms} Baths
                                            </span>
                                        }
                                        {(property.totalarea > 0) &&
                                            <span>
                                                {parseInt(property.totalarea).toLocaleString()}Sqft
                                            </span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*<div className="flex">
                            <div className="hidden lg:flex gap-[30px] items-end text-sm font-benton leading-6 tracking-[1.5px] font-normal">
                                {property.floorplans && (
                                    <div className="capitalize flex hover:opacity-60"
                                        onClick={openFullscreenFloorplan}>
                                        <Link to="#">
                                            <FloorplanIcon className="mr-2.5" />
                                        </Link>
                                        <Link to="#">
                                            Floorplan
                                        </Link>
                                    </div>
                                )}
                                <div className="capitalize flex hover:opacity-60" 
                                    onClick={() => openFullscreen(activeImageIndex)}>
                                    <Link to="#">
                                        <GalleryIcon className="mr-2.5" />
                                    </Link>
                                    <Link to="#">
                                        Gallery ({property.media.length})
                                    </Link>
                                </div>
                            </div>

                            <div className="hidden lg:flex lg:ml-[30px] mt-3 lg:mt-0 mb-7 lg:mb-0">
                                <Link to="#" 
                                    className="button !py-[7px] !px-[20px] !border-[#888888]" 
                                    onClick={openRequestInformation}
                                >
                                    Request information
                                </Link>
                            </div>
                        </div>*/}
                        {!isMobile && !isTablet &&
                            <PropertyPopupLinks
                                property={property} 
                                openFullscreen={openFullscreen} 
                                activeImageIndex={activeImageIndex} 
                                openRequestInformation={openRequestInformation}
                                openFullscreenFloorplan={openFullscreenFloorplan}
                            />
                        }
                    </div>
                </div>

                {/* Fullscreen Gallery */} 
                {fullscreenGallery !== null && (
                    <GalleryPortal>
                        <div className="absolute z-[1000] top-0 left-0 bottom-0 w-full h-full bg-white flex flex-col items-center justify-center">
                            <div className="flex flex-col items-center justify-center relative w-full h-full">
                                {/* Desktop Close Icon */}
                                <div className={`absolute w-[74px] top-[24px] right-0 z-[1001] ${!isBigScreen ? "hidden" : ""}`}>
                                    <CloseIcon 
                                        onClick={closeFullscreen} 
                                        className="cursor-pointer mx-auto" 
                                    />
                                </div>

                                <div className="flex w-full h-[88px] lg:h-10">
                                    {/* Mobile Close Icon */}
                                    <div className={`container w-full h-full flex justify-end items-center ${isBigScreen ? "hidden" : ""}`}>
                                        <CloseIcon 
                                            onClick={closeFullscreen} 
                                            className="cursor-pointer" 
                                        />
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className={`h-[${fullscreenHeight}px] w-[74px] relative ${isBigScreen ? "" : "hidden"}`}>
                                        <SliderPrevArrowBlack onClick={handlePrevClick} className={`${isBigScreen ? "absolute" : "hidden"} cursor-pointer top-1/2 transform -translate-y-1/2 right-[34px]`} />
                                    </div>
                                
                                    <div style={{ width:`${fullscreenWidth}px` }}>
                                        <Slider {...settings} ref={sliderRef} className={`w-full`} style={{ maxWidth: `${window.innerWidth}px` }}>
                                            {property.media.map((image, index) => (
                                                <div key={index} className="!block">
                                                    <LazyBackground
                                                        className={`w-full bg-cover bg-no-repeat bg-center !max-w-[${fullscreenWidth}px]`}
                                                        backgroundImage={image}
                                                        width={fullscreenWidth}
                                                        height={fullscreenHeight}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>

                                    <div className={`h-[${fullscreenHeight}px] w-[74px] relative ${isBigScreen ? "" : "hidden"}` }>
                                        <SliderNextArrowBlack onClick={handleNextClick} className={`${isBigScreen ? "absolute" : "hidden"} cursor-pointer top-1/2 transform -translate-y-1/2 left-[34px]`} />
                                    </div>
                                </div>
                                
                                <div className={`container w-full h-[88px] lg:h-10 flex items-center ${isBigScreen ? "justify-center items-center" : "justify-between"}`}>
                                    <SliderPrevArrowBlack onClick={handlePrevClick} className={`${isBigScreen ? "hidden" : "block ml-8"} cursor-pointer mr-9`} />
                                    <span className="text-sm font-benton font-normal tracking-[1.5px]">
                                        {currentSlide + 1}/{property.media.length}
                                    </span>
                                    <SliderNextArrowBlack onClick={handleNextClick} className={`${isBigScreen ? "hidden" : "block mr-8"} cursor-pointer ml-9`} />
                                </div>
                            </div>
                            
                            
                            
                        </div>
                        
                    </GalleryPortal>
                )}

                {/* Floorplans */}
                {/*
                {fullscreenFloorplanGallery !== null && (
                    <GalleryPortal>
                        <div className="absolute z-[1000] top-0 left-0 bottom-0 w-full h-full bg-white flex flex-col items-center justify-center overflow-y-scroll">
                            <div className="flex flex-col items-center justify-center relative w-full ">
                                {property.floorplans.map((image, index) => (
                                    <div 
                                        key={index} 
                                        className="bg-contain bg-no-repeat bg-center w-full h-screen"
                                        style={{ backgroundImage: `url(${image.url})` }}
                                    >
                                    </div>
                                ))}
                                <div className="absolute top-4 right-0 z-[1001]">
                                    <CloseIcon 
                                        onClick={closeFullscreenFloorplan} 
                                        className="cursor-pointer" 
                                    />
                                </div>
                            </div>
                        </div>
                    </GalleryPortal>
                )}
                */}

                {/* Floorplans */} 
                {fullscreenFloorplanGallery !== null && (
                    <GalleryPortal>
                        <div className="absolute z-[1000] top-0 left-0 bottom-0 w-full h-full bg-white flex flex-col items-center justify-center">
                            <div className="flex flex-col items-center justify-center relative w-full h-full">
                                {/* Desktop Close Icon */}
                                <div className={`absolute w-[74px] top-[24px] right-0 z-[1001] ${!isBigScreen ? "hidden" : ""}`}>
                                    <CloseIcon 
                                        onClick={closeFullscreenFloorplan} 
                                        className="cursor-pointer mx-auto" 
                                    />
                                </div>

                                <div className="flex w-full h-[88px] lg:h-10">
                                    {/* Mobile Close Icon */}
                                    <div className={`container w-full h-full flex justify-end items-center ${isBigScreen ? "hidden" : ""}`}>
                                        <CloseIcon 
                                            onClick={closeFullscreenFloorplan} 
                                            className="cursor-pointer" 
                                        />
                                    </div>
                                </div>

                                <div className="flex">
                                    {property.floorplans.length > 1 &&
                                        <div className={`h-[${fullscreenHeight}px] w-[74px] relative ${isBigScreen ? "" : "hidden"}`}>
                                            <SliderPrevArrowBlack onClick={handlePrevClick} className={`${isBigScreen ? "absolute" : "hidden"} cursor-pointer top-1/2 transform -translate-y-1/2 right-[34px]`} />
                                        </div>
                                    }
                                    <div style={{ width:`${fullscreenWidth}px` }}>
                                    <Slider {...settings} ref={sliderRef} className={`w-full`} style={{ maxWidth: `${window.innerWidth}px` }}>
                                        <div>
                                        {property.floorplans.map((image, index) => (
                                            <div 
                                                key={index} 
                                                className="bg-contain bg-no-repeat bg-center"
                                                style={{ backgroundImage: `url(${image.url})`, width: fullscreenWidth, height: fullscreenHeight }}
                                            >
                                            </div>
                                        ))}
                                       </div>
                                    </Slider>
                                    </div>

                                    {property.floorplans.length > 1 &&
                                        <div className={`h-[${fullscreenHeight}px] w-[74px] relative ${isBigScreen ? "" : "hidden"}` }>
                                            <SliderNextArrowBlack onClick={handleNextClick} className={`${isBigScreen ? "absolute" : "hidden"} cursor-pointer top-1/2 transform -translate-y-1/2 left-[34px]`} />
                                        </div>
                                    }
                                </div>
                                {property.floorplans.length > 1 &&
                                    <div className={`container w-full h-[88px] lg:h-10 flex items-center ${isBigScreen ? "justify-center items-center" : "justify-between"}`}>
                                        <SliderPrevArrowBlack onClick={handlePrevClick} className={`${isBigScreen ? "hidden" : "block ml-8"} cursor-pointer mr-9`} />
                                        <span className="text-sm font-benton font-normal tracking-[1.5px]">
                                            {currentSlide + 1}/{property.floorplans.length}
                                        </span>
                                        <SliderNextArrowBlack onClick={handleNextClick} className={`${isBigScreen ? "hidden" : "block mr-8"} cursor-pointer ml-9`} />
                                    </div>
                                }
                            </div>
                            
                            
                            
                        </div>
                    </GalleryPortal>
                )}
            </div>
            {property && agent && <PropertyHero 
                property={property} 
                onActiveImageIndexChange={handleActiveImageIndexChange} 
                agent={agent} 
                onRequestInformationActive={isRequestInformationActive}    
                onCloseRequestInformation={closeRequestInformation}
                siteSettings={settings}
            />}
            {(isMobile || isTablet) && 
                <PropertyPopupLinks
                    property={property} 
                    openFullscreen={openFullscreen} 
                    activeImageIndex={activeImageIndex} 
                    openRequestInformation={openRequestInformation}
                    openFullscreenFloorplan={openFullscreenFloorplan}
                />
            }
        </>
    );
}

export default PropertyTopInfo;