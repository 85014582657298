import { useMediaQuery } from 'react-responsive';

const OurOffices = ({ offices, title="" }) => {
    const isMobileDevice = useMediaQuery({ query: '(max-width: 640px)' });
    const isMiddleDevice = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <div className={`${isMiddleDevice ? "container" : ""} w-full font-normal flex flex-col sm:items-center `}>
            {title && 
                <div className="mb-5 lg:mb-6 text-2xl xl:pl-5 max-w-[220px] sm:max-w-[480px] xl:max-w-none w-full">
                    {title}
                </div>
            }
            <div className="font-benton sm:grid grid-cols-1 sm:grid-cols-2 gap-x-[80px] xl:gap-x-[130px] lg:pr-5 xl:pr-[200px] xl:pl-5 md:py-5 max-w-[480px] xl:max-w-none">
                {offices.map((office, index) => {
                    const addressLines = office.address.split("\n");
                    {/*<div key={office.id} className={`max-w-[220px] ${index 
                    !== 0 && (index !== 1 || isMiddleDevice) ? "mt-12" : ""}`}>*/}
                    return (
                        <div key={office.id} className={`max-w-[220px] ${isMobileDevice ? (index !== 0 ? "mt-12" : "") : (index !== 0 && index !== 1) ? "mt-12" : ""}`}
                        >
                            <p className="font-acta text-lg leading-[22px] mb-4">{office.name}</p>
                            {addressLines.map((addressLine, index) => {
                                return (<p key={index} className={`${index === 0 ? "tracking-[0.375px] text-[15px] leading-[22px] mb-0" : "mb-0"}`}>{addressLine}</p>);
                            })}

                            <p className="mt-4 font-medium text-[15px]">
                                {/*<a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(office.addressLine1 + ' ' + office.addressLine2)}`} target="_blank">
                                    View maps
                                </a>*/}
                                <a href={office.link} target="_blank">
                                    View maps
                                </a>
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default OurOffices;