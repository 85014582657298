import PropertiesTop from "../PropertiesModule/PropertiesTop";
import { useEffect, useState } from "react";
import { useGetAgentProperties } from "../../queries/Agents";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AgentProperties from "./AgentProperties";
import { useMediaQuery } from 'react-responsive';

const TeamMemberProperties = ({ agentName, agentEmail }) => {
    const propertyTypes = ["For Sale", "For Rent", "New Developments"];
    const [activeTab, setActiveTab] = useState(0);
    const [properties, setProperties] = useState([[], [], []]);
    const isMobileDevice = useMediaQuery({ query: '(max-width: 768px)' });

    const handleTabChange = (index) => {
        setActiveTab(index);
    }

    const saleProperties = useGetAgentProperties(agentEmail, "Sale", 3);
    const rentProperties = useGetAgentProperties(agentEmail, "Rent", 3);
    const newDevelopmentsProperties = [];
    
    useEffect(() => {
        setProperties([saleProperties, rentProperties, newDevelopmentsProperties]);
    }, [saleProperties, rentProperties, agentEmail]);

    return (properties[0] && properties[0].length > 0 || properties[1] && properties[1].length > 0) ? (
        <div className="team-member-properties">
            <div className="h-[30px]"></div>
            <div className={`${isMobileDevice ? "wide-container" : "container" } border border-x-0 border-b-0 border-t border-footer-line mx-auto`}></div>

            <div className="md:hidden container text-[26px] leading-[30px] mt-[50px]">{agentName}'s Properties</div>
            <PropertiesTop 
                propertyTypes={propertyTypes}
                propertyTypeTitle={`${agentName}'s Properties`}
                onTabChange={handleTabChange} 
                activeTab={activeTab} 
            />
            
            {propertyTypes.map((type, index) => (
                activeTab === index && (
                    <div key={index}>
                        <AgentProperties 
                            properties={properties[index]}
                        />
                    </div>
                ))
            )}
            
            <div className="mt-11 md:mt-[65px] mb-[83px] md:mb-[115px] flex justify-center">
                <Link to={`/search?agent=${agentEmail}`}>
                    <span className="button mb-[115px] text-center">View all</span>
                </Link>
            </div>
            
        </div>
    ) : "";
}

export default TeamMemberProperties;