import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropertyCard from '../Property/PropertyCard';
import useFetch from '../../utils/useFetch';
import PrepareNeighbourhoodName from '../../utils/prepareNeighbourhoodName';

const NeighbourhoodGuideProperties = ({ neighbourhoodTitle }) => {
    const [activeTab, setActiveTab] = useState(0);
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const searchUrl = `${apiUrl}/api/search`;

    const handleTabClick = (index) => {
        setActiveTab(index);
    }
    
    const { data: propertiesForSale, loadingProperties, errorProperties } = useFetch  (
        searchUrl, 
        "POST", 
        {
            community: neighbourhoodTitle,
            listingType: "Sale",
            perPage: 3,
            offset: 0
        });

    const { data: propertiesForRent, isLoadingProperties, isErrorProperties } = useFetch  (
        searchUrl, 
            "POST", 
            {
                community: neighbourhoodTitle,
                listingType: "Rent",
                perPage: 3,
                offset: 0
            });

    const tabs = [{title: "For Sale", properties: propertiesForSale},
                  {title: "For Rent", properties: propertiesForRent},
                  {title: "New Developments", properties: []}];

    return (
        propertiesForSale && propertiesForRent &&
        <div className="neighbourhood-guide-properties container mt-24 mb-10 border border-[#2A2A2A] border-b-0 border-x-0">
            <div className="mt-9 mb-8 flex flex-col md:flex-row justify-between items-center">
                <div className="text-[#0A0A0A] text-[26px]">
                    {neighbourhoodTitle} Properties
                </div>
                <div className="flex flex-col md:flex-row gap-5 md:gap-12 font-benton font-normal text-[15px] leading-5 uppercase tracking-[0.375px] text-center md:text-left mt-8 md:mt-0">
                    {tabs.map((tab, index) => (
                        <span 
                            key={index} 
                            className={`cursor-pointer ${index === activeTab ? "text-[#0A0A0A]" : "text-[#888888]"}`}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab.title}
                        </span>
                    ))}
                </div>
            </div>
            <div>
                {tabs.map((tab, index) => (
                    <div key={index} className={`tab-content flex flex-col sm:grid md:grid-cols sm:grid-cols-2 lg:grid-cols-3 sm:gap-10 } ${index === activeTab ? "active" : "inactive"}`}>
                        {tab.properties.length > 0 && tab.properties.map((property, index) => (
                            <div key={index} className="w-full mt-12 md:mt-0 ">
                                <PropertyCard property={property} />
                            </div>
                        ))}
                        {tab.properties.length === 0 && <div>There are no properties to show</div>}
                    </div>
                ))}
            </div>
            <div className="mt-16 mb-10 text-center">
                <Link to={`/search?community=${PrepareNeighbourhoodName(neighbourhoodTitle)}`} className="button">
                    <span className="md:hidden">View all</span>
                    <span className="hidden md:inline">View all properties in {neighbourhoodTitle}</span>
                </Link>
            </div>
        </div>
    );
}

export default NeighbourhoodGuideProperties;