import SavedSearchResults from "./SavedSearchResults";
import SavedSearchTop from "./SavedSearchTop";

const SavedSearches = ({ searches }) => {
    return searches.length > 0 ? (
        <div className="savedSearches">
            {searches?.length > 0 && (
                    <div className="">
                    {searches.map((search, index) => (
                        <>
                            <div key={index}>
                                <SavedSearchTop search={search} />
                            </div>
                            <div>
                                <SavedSearchResults search={search} />
                            </div>
                        </>
                    ))}
                    </div>
                )
            }
            
        </div>
    ) : "<div>There are no searches to show</div>";
};

export default SavedSearches;