import React, { useEffect, useRef } from 'react';
import { getCloudflareImageUrl } from './getCloudflareUrl';

const LazyBackground = ({ className, backgroundImage, width, height, quality=75, useGradient }) => {
  const ref = useRef();
  const lowResImage = getCloudflareImageUrl(backgroundImage, 30, 30, 20, "cover");

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // When element is visible in the viewport, add the background image
          const lazyBackground = entry.target;
          lazyBackground.style.backgroundImage = `url(${lowResImage})`;
          lazyBackground.style.filter = "blur(20px)";

          const img = new Image();
          img.src = getCloudflareImageUrl(backgroundImage, width, height, quality, "cover");
          img.onload = () => {
            lazyBackground.style.backgroundImage = `url(${img.src})`;
            lazyBackground.style.filter = 'none';
          }
          // Stop observing this element
          observer.unobserve(lazyBackground);
        }
      });
    });

    observer.observe(ref.current);

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [backgroundImage, lowResImage, width, height, quality]);

  return (
    <div className={`image-replacing ${className}`} style={{height: height}} ref={ref}>
      {useGradient && <div className="absolute inset-0 gradient-overlay"></div>}
    </div>
  );
};

export default LazyBackground;