import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as Logo } from '../../assets/images/logo_uk.svg';
import { Link } from 'react-router-dom';
import useSticky from '../../utils/useSticky';
import { useState } from 'react';
import MainMenu from './MainMenu';
import Hamburger from 'hamburger-react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const HeaderMainPart = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isSticky = useSticky(isMobile ? 0 : 32);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const header = document.querySelector('.header-main');

    function zIndexChange() {
        
        if(header) {
            if(!header.classList.contains('menu-opened')) {
                setTimeout(function () {
                    header.classList.add('menu-opened');
                }, 300);
            } else if(header.classList.contains('menu-opened')) {
                setTimeout(function () {
                    header.classList.remove('menu-opened');
                }, 500);
            }
        }
    }

    return (
        <>
            {isSticky && <div style={{ height: `72px` }} />}

            <div className={`header-main relative flex items-center ${isSticky ? 'header-fixed' : ''}`}>
                <div className="container flex justify-between items-center">
                    <div onMouseDown={(e) => { e.stopPropagation(); zIndexChange(); }}>
                        <Hamburger toggled={isMenuOpen} toggle={setMenuOpen} size={24} />
                    </div>

                    <Link to="/" className="self-center" onClick={() => isHomePage ? window.location.reload() : "" }>
                        <Logo className="cursor-pointer" />
                    </Link>

                    <Link to="/search" className="search-icon transition-all duration-300">
                        <SearchIcon className="self-center cursor-pointer" />
                    </Link>
                </div>
                <MainMenu isOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
            </div>
        </>
    );
}

export default HeaderMainPart;