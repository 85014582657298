import useBackButton from '../../utils/useBackButton';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import renderers from '../../utils/renderers';

const TeamMemberRightInfo = ({ 
    teamMember, 
    onOpenRequestInformation
}) => {
    const goBack = useBackButton();

    return (
        <div className="container md:no-container !max-w-[820px]">
            <div 
                className="mt-[50px] inline-block cursor-pointer tracking-[1.5px]uppercase text-sm font-normal font-benton mb-7 hover:font-medium" 
                onClick={goBack}
            >
                BACK
            </div>
            <div className="max-w-[757px] font-benton font-normal mb-11 ">
                {teamMember && teamMember.document && <DocumentRenderer document={teamMember.document.document} renderers={renderers} />}
            </div>
            <span className="button !px-[30px]" onClick={onOpenRequestInformation}>
                Message {teamMember.firstname}
            </span>
        </div>
    );
}

export default TeamMemberRightInfo;