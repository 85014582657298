import { ReactComponent as DashboardSettingsIcon } from '../assets/icons/dashboard_settings.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import { useState, useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';
import PersonalDetails from '../components/Dashboard/PersonalDetails';
import RecommendedNewProperties from '../components/Dashboard/RecommendedNewProperties';
import SavedProperties from '../components/Dashboard/SavedProperties';
import SavedSearches from '../components/Dashboard/SavedSearches';

const Dashboard = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect}) => {
    const [isOpen, setIsOpen] = useState([true, false, false, false]);
    const dropdownRef = useRef(null);

    const handleTabClick = (index) => {
        const newIsOpen = [...isOpen];
        newIsOpen[index] = !newIsOpen[index];
        setIsOpen(newIsOpen);
    }

    useEffect(() => {
        document.title = "Dashboard - Sotheby's";
    }, []);

    const property = {
        address: "",
        bedrooms: 2,
        city_propertyfinder:"London",
        community_propertyfinder:"Nine Elms",
        currency_iso_code:"GBP",
        description:"United Kingdom Sotheby’s International Realty are delighted to present this luxurious lateral two-bedroom apartment available for sale in AYKON London ONE, a new level of luxury living in the heart of the Capital.\n\nAs one of the most established real estate development companies in the Middle East, DAMAC in exclusive partnership with Versace Home have collaborated for the first time to bring the pinnacle in branded living experiences to London. This two-bedroom apartment is situated on the 30th floor of this 50-storey iconic tower and features a spacious living room, a fully fitted kitchen, two spacious bathrooms (one en-suite) and two winter gardens offering incredible panoramic views of London.\n\nThe first-class, high-spec facilities include over 8,000 sq. ft. of communal garden space, an indoor swimming pool and jacuzzi, a residents’ lounge, a state-of-the-art gym and a children’s play area. There is also a 24-hour concierge, parking and storage available, and all of this is right by a vast network of public transportation options, riverside walks, shops and restaurants.\n\nTo view this amazing property, contact us today.",
        fullbathrooms:2,
        idField:"UK-S-39399",
        listing_agent_email:"jaishree.bricepf@sothebysrealty.co.uk",
        listing_id: "",
        listing_title: "Flat 3004, 63-71 Bondway, Nine Elms, SW8 1SQ.",
        listingprice: "1250000.0000",
        listingtype: "Sale",
        postalcode: "SW8 1SQ",
        property: "a0O7T000006BiDeUAK",
        property_propertyfinder: "Bondway",
        propertytype: "Apartment",
        reference_number: "39399",
        state: "N/A",
        status: "Published",
        sub_community_propertyfinder: "Bondway",
        tags: [],
        totalarea: "834.0000",
        media: [
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/haja8jrj0/0_Exterior-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/cfw90ihhd/0_Exterior-1.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/gjqcv793v/1_Reception-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/zj4zqs6pl/1_Reception-1.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/f5konq359/10_Swimming%20Pool%20%28Internal%29-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/fnp967h9b/11_sauna-steam%20room-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/6stpwqxmx/12_Commual%20balcony-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/iemtkb5nt/13_Gym-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/y6b0tp0ig/2_Sunroom%201-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/xovh0lenf/4_Kitchen-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/8oewafv8a/5_Bedroom%202-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/kpyh8lgd9/6_Main%20Bedroom-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/wo70xlbya/8_Main%20Ensuite-0.jpg",
            "https://s3.amazonaws.com/propertybase-clients/00D1t000000CnPpEAK/a0O7T000006BiDe/ng0r3ow4j/9_Shower%20Room-0.jpg",
        ]
    };

    const recommendedNewProperties = [property, property, property, property];
    const savedProperties = [property, property, property, property];
    const savedSearches = [
        {
            area: {min: 580, max: 10616},
            community: ['Belgravia', 'Kensington', 'City of London', "Regent's Park"],
            listingType: "Sale",
            minBaths: 0,
            minBeds: 3,
            offset: 0,
            perPage: 24,
            price: {min: 0, max: 99999999}
        },
        {
            area: {min: 580, max: 10616},
            community: ['Belgravia'],
            listingType: "Rent",
            minBaths: 2,
            minBeds: 2,
            offset: 0,
            perPage: 24,
            price: {min: 500, max: 15000}
        },
        {
            area: {min: 580, max: 5000},
            community: [],
            listingType: "Sale",
            minBaths: 2,
            minBeds: 4,
            offset: 0,
            perPage: 24,
            price: {min: 350000, max: 99999999}
        }
    ];

    return (
        <div className="dashboard">
            <div className="container relative">
                <div 
                    className="mx-auto h-[91px] flex justify-between items-center"
                    ref={dropdownRef}
                    onClick={() => handleTabClick(0)}    
                >
                    <span className="text-[#0A0A0A] font-normal text-[26px]">
                        Welcome back, Jeremy
                    </span>
                    <span className="flex">
                        <DashboardSettingsIcon className="cursor-pointer mr-14" />
                        <Link to="/logout" className="flex items-center">
                            <LogoutIcon className="mr-2.5" />
                            <span className="text-[#111111] font-benton font-normal tracking-[0.375px] text-[13px] leading-[19px]">Log Out</span>
                        </Link>
                    </span>
                </div>
                {isOpen[0] && 
                    <PersonalDetails 
                        currencyOptions={currencyOptions}
                        selectedCurrencyOption={selectedCurrencyOption}
                        onCurrencyOptionSelect={onCurrencyOptionSelect}
                        dimensionOptions={dimensionOptions}
                        selectedDimensionOption={selectedDimensionOption}
                        onDimensionOptionSelect={onDimensionOptionSelect}
                    />
                }

                <div className="absolute h-[1px] bottom-0 left-0 bg-[#2A2A2A]" style={{width: 'calc(100vw - 22px)', maxWidth: '1462px'}}></div>
            </div>

            <div className="container relative">
                <div 
                    className="h-[91px] flex justify-between items-center cursor-pointer"
                    ref={dropdownRef}
                    onClick={() => handleTabClick(1)}
                >
                    <span className="text-[#0A0A0A] font-normal text-[26px]">
                        Recommended New Properties
                    </span>
                    <span className="flex">
                        <ArrowDown width={20} height={12} className={`transform ${isOpen[1] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                    </span>
                </div>
                {isOpen[1] &&
                    <div>
                        <RecommendedNewProperties properties={recommendedNewProperties} />
                    </div>
                }

                <div className="absolute h-[1px] bottom-0 left-0 bg-[#2A2A2A]" style={{width: 'calc(100vw - 22px)', maxWidth: '1462px'}}></div>
            </div>

            <div className="container relative">
                <div 
                    className="h-[91px] flex justify-between items-center cursor-pointer"
                    ref={dropdownRef}
                    onClick={() => handleTabClick(2)}    
                >
                    <span className="text-[#0A0A0A] font-normal text-[26px]">
                        Saved Properties
                    </span>
                    <span className="flex">
                        <ArrowDown width={20} height={12} className={`transform ${isOpen[2] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                    </span>
                </div>
                {isOpen[2] &&
                    <div>
                        <SavedProperties properties={savedProperties} />
                    </div>
                }

                <div className="absolute h-[1px] bottom-0 left-0 bg-[#2A2A2A]" style={{width: 'calc(100vw - 22px)', maxWidth: '1462px'}}></div>
            </div>

            <div className="container relative">
                <div 
                    className="h-[62px] flex justify-between items-end cursor-pointer"
                    ref={dropdownRef}
                    onClick={() => handleTabClick(3)}    
                >
                    <span className="text-[#0A0A0A] font-normal text-[26px] leading-[30px]">
                        Saved Searches
                    </span>
                    <span className="flex">
                        <ArrowDown width={20} height={12} className={`mb-1.5 transform ${isOpen[3] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                    </span>
                </div>
                {isOpen[3] &&
                    <SavedSearches searches={savedSearches} />
                }
            </div>
            
        </div>
    );
}

export default Dashboard;