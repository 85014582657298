import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';

const TopDropdown = ({ options, selectedOption, onSelect}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionSelect = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef.current]);

    if(!selectedOption)
        return null;

    return (
        <div ref={dropdownRef} className="relative">
            <button
                type="button"
                className="inline-flex items-center justify-between w-full min-w-[50px]"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="pl-1 font-bold cursor-pointer">{selectedOption.label}</span> <ArrowDown className={`self-center ml-2 cursor-pointer transform ${isOpen ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`} />
            </button>
            {isOpen && (
                <div className="absolute flex z-[999] w-full -left-2 top-[28px] animate-topDropdownOpen">
                    <ul className="w-full bg-white border border-gray-300 rounded-md overflow-hidden !py-1">
                    {options.map((option) => (
                        <li
                        key={option.value}
                        className={`text-center cursor-pointer w-full hover:bg-topline ${option.value === selectedOption.value ? 'font-bold' : ''}`}
                        onClick={() => handleOptionSelect(option)}
                        >
                        {option.label}
                        </li>
                    ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TopDropdown;