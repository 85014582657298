import { useEffect, useState } from "react";
import { useGetContactUs } from "../queries/Pages";
import DynamicComponent from "../components/DynamicComponent";
import { SeoHelmet, seoData } from "../utils/Seo";
import Partnership from "../components/ContactUs/Partnership";

const ContactUs = () => {
    const contactUsPageData = useGetContactUs();
    const [pageComponents, setPageComponents] = useState(null);
    const [seoSettings, setSeoSettings] = useState({});

    useEffect(() => {
        if(contactUsPageData) {
            setSeoSettings(seoData({...contactUsPageData, defaultTitle: "Contact Us - Sotheby's"}));
            setPageComponents(contactUsPageData.document.document);
        }
    }, [contactUsPageData]);
    
    return (
        <div className="about-us">
            <SeoHelmet seoSettings={seoSettings} />

            {pageComponents && pageComponents.length > 0 && pageComponents.map((componentData, index) => (
                <DynamicComponent
                    key={index}
                    componentData={componentData}
                />
            ))}

            <Partnership />
        </div>
    );
}

export default ContactUs;