function collectText(element) {
    let textContent = '';

    if(typeof element === 'string') {
        textContent += element;
    }
    else if(Array.isArray(element)) {
        element.forEach(childElement => {
            textContent += ' ' + collectText(childElement);
        });
    }
    else if(typeof element === 'object' && element !== null) {
        Object.values(element).forEach(value => {
            textContent += ' ' + collectText(value);
        });
    }

    return textContent;
};

function getArticleReadingTime(articleObject) {
    const articleText = collectText(articleObject);
    const wordCount = articleText.split(' ').filter(Boolean).length;
    const readingTime = Math.ceil(wordCount / 200);

    return readingTime;
}

export default getArticleReadingTime;