import { useGetPrivacyPolicy } from "../queries/Pages";
import { useEffect, useState } from "react";
import { DocumentRenderer } from '@keystone-6/document-renderer';
import renderers from '../utils/renderers';
import { SeoHelmet, seoData } from "../utils/Seo";

const PrivacyPolicy = () => {
    const privacyPolicy = useGetPrivacyPolicy();
    const [seoSettings, setSeoSettings] = useState({});

    useEffect(() => {
        if(privacyPolicy)
            setSeoSettings(seoData({...privacyPolicy, defaultTitle: "Privacy Policy Page - Sotheby's"}));
        
    }, [privacyPolicy]);

    return (privacyPolicy && (
        <div className="privacy-policy container">
            <SeoHelmet seoSettings={seoSettings} />
            
            <div className="font-normal text-[26px] h-[26px] md:h-[91px] flex justify-start items-center mb-5 md:mb-0">
                {privacyPolicy.page_title}
            </div>
            <div className="font-benton text-[15px] leading-[22px] font-normal max-w-[1060px]">
                <div>
                    {privacyPolicy && <DocumentRenderer document={privacyPolicy.page_content.document} renderers={renderers} />}
                </div>
            </div>
        </div>
    ));
}

export default PrivacyPolicy;