const VideoBlock = ({ props }) => {
    const url = new URL(props.url);

    let videoId;
    let videoPlatform;
    let embedUrl;

    if(url.host.includes('youtube')) {
        videoPlatform = 'youtube';
        videoId = new URLSearchParams(url.search).get('v');
        embedUrl = `https://www.youtube.com/embed/${videoId}?controls=1`;
    }
    else if(url.host.includes('vimeo')) {
        videoPlatform = 'vimeo';
        videoId = url.pathname.split('/')[1];
        embedUrl = `https://player.vimeo.com/video/${videoId}`;
    }

    return videoPlatform ? (
        <div className="wide-container h-[375px] md:h-[905px]">
            <iframe 
                className="w-full h-full" 
                src={embedUrl}
                title={`${videoPlatform} video player`}
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
            ></iframe>
        </div>
    ) : '';
}

export default VideoBlock;