import { Link } from 'react-router-dom';
import { useState, useRef } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';
import { useMediaQuery } from 'react-responsive';
import { useGetNeighbourhoods } from "../../queries/Neighbourhoods";

const FooterNavigation = () => {
    const [isOpen, setIsOpen] = useState([false, false, false, false]);
    const tabRef = useRef(null);
    const isMobileDevice = useMediaQuery({ query: '(max-width: 768px)' });
    const neighbourhoods = useGetNeighbourhoods(0);

    const handleTabClick = (index) => {
        const newIsOpen = [...isOpen];
        newIsOpen[index] = !newIsOpen[index];
        setIsOpen(newIsOpen);
    }
 
    return (
        <div className="flex flex-wrap mt-11 md:mt-14 text-center mb-14 tracking-[0.38px]">
            <div className="w-full md:w-1/4 border border-black md:border-0 border-t-0 border-x-0">
                <ul className="!pb-0">
                    <div 
                        className="flex md:block items-center justify-between"
                        ref={tabRef}
                        onClick={() => handleTabClick(0)}    
                    >
                        <li 
                            className="font-medium text-[15px] h-[54px] md:h-auto flex md:block items-center"
                        >
                            Browse homes in
                        </li>
                        <span className="flex pr-1.5 md:hidden">
                            <ArrowDown width={20} height={12} className={`transform ${isOpen[0] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                        </span>
                    </div>
                    {(isOpen[0] || !isMobileDevice) && 
                    <div className="font-normal">
                        <li className="mt-3 text-left md:text-center">
                            <Link to="/search" className="hover:font-medium">All Locations</Link>
                        </li>
                        {neighbourhoods &&
                            neighbourhoods.map((neighbourhood, index) => (
                                <li className="mt-3 text-left md:text-center" key={index}>
                                    <Link to={`/search?community=${encodeURIComponent(neighbourhood.neighbourhood_title.replace(/%20/g, '+'))}`} className="hover:font-medium">{neighbourhood.neighbourhood_title}</Link>
                                </li>
                            ))
                        }
                    </div>
                    }
                </ul>
            </div>
            <div className="w-full md:w-1/4 border border-black md:border-0 border-t-0 border-x-0">
                <ul className="!pb-0">
                    <div 
                        className="flex md:block items-center justify-between"
                        ref={tabRef}
                        onClick={() => handleTabClick(1)}    
                    >
                        <li className="font-medium text-[15px] h-[54px] md:h-auto flex md:block items-center"
                            ref={tabRef}
                            onClick={() => handleTabClick(1)}
                        >
                            Neighbourhood guides
                        </li>
                        <span className="flex pr-1.5 md:hidden">
                            <ArrowDown width={20} height={12} className={`transform ${isOpen[1] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                        </span>
                    </div>
                    {(isOpen[1] || !isMobileDevice) &&
                    <div className="font-normal">
                        {neighbourhoods && 
                            neighbourhoods.map((neighbourhood, index) => (
                                <li className="mt-3 text-left md:text-center" key={index}>
                                    <Link to={`/neighbourhood/${neighbourhood.slug}`} className="hover:font-medium">{neighbourhood.neighbourhood_title}</Link>
                                </li>
                            ))
                        }
                    </div>
                    }
                </ul>
            </div>
            <div className="w-full md:w-1/4 border border-black md:border-0 border-t-0 border-x-0">
                <ul className="!pb-0">
                    <div 
                        className="flex md:block items-center justify-between"
                        ref={tabRef}
                        onClick={() => handleTabClick(2)}    
                    >
                        <li className="font-medium cursor-pointer text-[15px] h-[54px] md:h-auto flex md:block items-center"
                            ref={tabRef}
                            onClick={() => handleTabClick(2)}
                        >
                            Journal
                        </li>
                        <span className="flex pr-1.5 md:hidden">
                            <ArrowDown width={20} height={12} className={`transform ${isOpen[2] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                        </span>
                    </div>
                    {(isOpen[2] || !isMobileDevice) &&
                    <div className="font-normal">
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Portfolio</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">The Agent</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">The Market</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Neighbourhoods</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">The Lifestyle</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Design & Architecture</Link>
                        </li>
                        <li className="mt-3 mb-6 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Company News</Link>
                        </li>
                    </div>
                    }
                </ul>
            </div>
            <div className="w-full md:w-1/4 border border-black md:border-0 border-t-0 border-x-0">
                <ul className="!pb-0">
                    <div 
                        className="flex md:block items-center justify-between"
                        ref={tabRef}
                        onClick={() => handleTabClick(3)}    
                    >
                        <li className="font-medium cursor-pointer text-[15px] h-[54px] md:h-auto flex md:block items-center"
                            ref={tabRef}
                            onClick={() => handleTabClick(3)}
                        >
                            Company
                        </li>
                        <span className="flex pr-1.5 md:hidden">
                            <ArrowDown width={20} height={12} className={`transform ${isOpen[3] ? "transform -rotate-180 transition-all duration-300" : "duration-200"}`}/>
                        </span>
                    </div>
                    {(isOpen[3] || !isMobileDevice) &&
                    <div className="font-normal">
                        <li className="mt-3 text-left md:text-center">
                            <Link to="/about-us" className="hover:font-medium">About</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">List your home</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="/team" className="hover:font-medium">The Team</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="/media-pr" className="hover:font-medium">Media</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Preferred Partners</Link>
                        </li>
                        <li className="mt-3 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Latest news</Link>
                        </li>
                        <li className="mt-3 mb-6 text-left md:text-center">
                            <Link to="#" className="hover:font-medium">Newsletter</Link>
                        </li>
                    </div>
                    }
                </ul>
            </div>
        </div>
    )
}

export default FooterNavigation;