const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

export const getCloudflareImageUrl = (
  src,
  width,
  height,
  quality,
  fit,
  options
) => {
  const params = ["format=auto"];

  const contentDomain = "https://felixandfriends.com/";

  // todo default image
  if (!src) {
    return;
  }

  quality = quality || 75;

  if (width) {
    params.push(`width=${width}`);
  }

  if (height) {
    params.push(`height=${height}`);
  }

  if (fit) {
    params.push(`fit=${fit}`);
  }

  if (quality) {
    params.push(`quality=${quality}`);
  }

  if (options) {
    params.push(...options);
  }

  const paramsString = params.join(",");

  return `${contentDomain}cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};
