import { ReactComponent as BackIcon } from '../../assets/icons/property_back.svg';
import { Link } from 'react-router-dom';
import useBackButton from '../../utils/useBackButton';

const UnderTitleNavigation = ({ nextLink }) => {
    const goBack = useBackButton();

    return (
        <div className="wide-container flex justify-between mt-5">
            <div className="flex items-center mb-[14px] justify-center md:justify-start">
                <Link to="#">
                    <BackIcon className="self-center hover:opacity-70" />
                </Link>
                <Link to="#">
                    <span 
                        className="ml-3.5 font-benton text-[15px] leading-[22px] text-[#888888] tracking-wide font-normal h-full flex justify-center hover:opacity-70"
                        onClick={goBack}    
                    >BACK</span>
                </Link>
            </div>
            {nextLink &&
                <div className="flex items-center mb-[14px] justify-center md:justify-start">
                    <Link to={`${nextLink.url}`}>
                        <span 
                            className="mr-3.5 font-benton text-[15px] leading-[22px] text-[#888888] tracking-wide font-normal h-full flex justify-center hover:opacity-70"  
                        >{nextLink.anchor}</span>
                    </Link>
                    <Link to={`${nextLink.url}`}>
                        <BackIcon className="self-center transform rotate-180 hover:opacity-70" />
                    </Link>
                </div>
            }
        </div>
    );
}

export default UnderTitleNavigation;