import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PartnerCard from "./PartnerCard";

const OtherPartners = ({ partners }) => {
    return (
        <div className="partner container">
            <h2 className="font-regular font-normal md:font-light text-xl md:text-2xl h-[63px] md:h-[135px] text-center flex items-center justify-center">Other Partners</h2>
            <div className="flex flex-col md:grid md:grid-cols-2 xl:grid-cols-4 items-center md:items-stretch md:justify-items-center md:gap-8 mb-[45px] md:mb-[90px] md:space-between">
                {partners.map(partner => (
                    <PartnerCard 
                        key={partner.id}
                        id={partner.id}
                        slug={partner.slug}
                        photo={partner.featuredImages.length > 0 ?partner.featuredImages[0].url : false } 
                        category={partner.category}
                        title={partner.title}
                        excerpt={partner.excerpt.document}
                        date={partner.date}
                        readTime={partner.readingTime}
                        createdAt={partner.createdAt}
                    />
                ))}
            </div>
            <div className="text-center">
                <Link to="/partners" className="button mb-[70px]">View more</Link>
            </div>
        </div>
    );
}

export default OtherPartners;