export async function sendEmail({ 
    from,
    to,
    senderName,
    subject,
    message,
    phone=null
}) {
    const validationResult = validateEmailParams({
        from: from,
        senderName: senderName,
        to: to,
        subject: subject,
        message: message,
        phone: phone
    });

    if(!validationResult.valid) {
        return { success: false, error: validationResult.error };
    }

    try {
        const emailParams = {
            from,
            senderName,
            to,
            subject,
            message,
            phone
        };

        //const response = await fetch("https://dev.felixandfriends.com/api/sendEmail", {
        const response = await fetch("https://fandftesting.com/sothebys/sendEmail.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailParams)
        });

        if(!response.ok) {
            return { success: false, error: "Network response was not ok" };
        }

        const sendingResult = await response.json();
        
        if(sendingResult === true) {
            return { success: true };
        } else {
            return { success: false, error: "Unknown error while sending email"};
        }
    } catch (error) {
        return { success: false, error: "There was an error sending email" };
    }
}

function validateEmailParams(params) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^\+?[0-9]+$/;

    if (!emailRegex.test(params.from)) {
        return { valid: false, error: 'Invalid sender email address' };
    }

    if (!emailRegex.test(params.to)) {
        return { valid: false, error: 'Invalid recipient email address' };
    }

    if (!params.senderName || params.senderName.length === 0) {
        return { valid: false, error: 'Sender name is required' };
    }

    if (!params.subject || params.subject.length === 0) {
        return { valid: false, error: 'Subject is required' };
    }

    if (!params.message || params.message.length === 0) {
        return { valid: false, error: 'Message is required' };
    }

    if (params.phone && !phoneRegex.test(params.phone)) {
        return { valid: false, error: 'Invalid phone number' };
    }

    return { valid: true };
}
