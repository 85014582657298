import { ReactComponent as SothebysLogo } from '../../assets/icons/property_agent_info_logo.svg';
import { ReactComponent as AgentPhone } from '../../assets/icons/property_agent_phone.svg';
import { ReactComponent as AgentWhatsapp } from '../../assets/icons/property_agent_whatsapp.svg';
import { ReactComponent as AgentEmail } from '../../assets/icons/property_agent_email.svg';
import { Link } from 'react-router-dom';
import getGoogleDriveFileLink from "../../utils/getGoogleDriveFileLink";
import { useMediaQuery } from 'react-responsive';
import LazyBackground from '../../utils/LazyBackground';

const PropertyAgentInfo = ({ agent }) => {
    const languages = agent && agent.languages && agent.languages.split(",");
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <div className={`${isMobile || isTablet ? "wide-container relative mt-[50px]" : "container"} lg:h-[340px] flex flex-col lg:flex-row items-center`}>
            <div className="hidden lg:flex h-[258px] bg-dark-blue basis-0 flex-grow items-center justify-center">
                <SothebysLogo />
            </div>
            <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`} className="absolute lg:static w-full max-w-[335px] lg:basis-[295px] h-full">
                {/*<div className="bg-cover bg-no-repeat bg-center h-full" style={{ backgroundImage: `url("${getGoogleDriveFileLink(agent.photo)}")` }}>
                </div>*/}
                <LazyBackground
                    className={"bg-cover bg-no-repeat bg-center w-full h-full"}
                    backgroundImage={getGoogleDriveFileLink(agent.photo)}
                    width={isMobile || isTablet ? 335 : 295}
                    height={isMobile || isTablet ? 386 : 340}
                />
            </Link>
            <div className="mt-[250px] lg:mt-0 pt-[168px] lg:pt-0 lg:h-[258px] bg-dark-blue lg:basis-0 lg:flex-grow flex items-center text-white w-full lg:w-auto">
                <div className="w-full lg:w-auto flex flex-col gap-4 items-center lg:items-start lg:ml-[85px] pb-[30px] lg:pb-0">
                    <div className="font-benton font-normal text-sm uppercase">
                        {agent.position}
                    </div>
                    <Link to={`${agent.slug ? `/agent/${agent.slug}` : "#"}`}>
                        <div className="text-[32px] leading-9 hover:opacity-80">
                            {agent.firstname} {agent.lastname}
                        </div>
                    </Link>
                    <div className="flex gap-7">
                        <a href={`tel:${agent.phone}`} className="hover:opacity-80">
                            <AgentPhone />
                        </a>
                        <a href={`https://wa.me/${agent.whatsapp}`} className="hover:opacity-80">
                            <AgentWhatsapp />
                        </a>
                        <a href={`mailto:${agent.email}`} className="hover:opacity-80">
                            <AgentEmail />
                        </a>
                    </div>
                    <div className="font-benton text-[13px] font-normal">
                        {languages && languages.map((language, index) => (
                            <span key={index} className={`${index > 0 ? "border border-t-0 border-b-0 border-r-0 border-white px-2.5" : "pr-2.5"}`}>{language}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyAgentInfo;