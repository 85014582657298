import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

const DropdownArrow = ({ activeDropdown, id }) => {
    return (
        <span className={`ml-2 self-center arrow absolute right-[8px] top-1/2  ${
            activeDropdown === id ? 'transform rotate-180' : ''
        } transform -translate-y-1/2`}>
            <ArrowDown />
        </span>
    );
};

function changePlaceholder(selectedOptions, defaultPlaceholder) {
    if(Array.isArray(selectedOptions)) {
        if(selectedOptions.length > 0)
            return selectedOptions.length > 1 ? (selectedOptions[0] + " + " + (selectedOptions.length-1) + ((selectedOptions.length-1) === 1 ? " location" : " locations")).toUpperCase() : selectedOptions[0].toUpperCase();
        else
            return defaultPlaceholder;
    }
    else
        return selectedOptions.toUpperCase();
}

const SearchInputSelect = ({
    id,
    placeholder,
    options,
    optionType = false,
    useDropdownArrow = false,
    onSelectionChange,
    activeDropdown,
    onToggle,
    onOptionsUpdate,
    initialSelectedOptions,
    onFocus,
    onBlur
}) => {
    const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
    const [inputValue, setInputValue] = useState('');
    const openingElementRef = useRef(null);
    const filterListElementRef = useRef(null);

    const handleOptionSelect = (option) => {
        let newSelectedOptions = selectedOptions;
    
        if (optionType === 'multipleSelect') {
            if (option === 'All Locations') {
                const otherOptions = options.map(opt => opt.title).filter(val => val !== 'All Locations');
    
                if (otherOptions.every(val => selectedOptions.includes(val))) {
                    newSelectedOptions = [];
                } else {
                    newSelectedOptions = otherOptions;
                }
            } else {
                if (selectedOptions.includes(option)) {
                    newSelectedOptions = selectedOptions.filter(loc => loc !== option);
                } else {
                    newSelectedOptions = [...selectedOptions, option];
                }
            }
        } else if (optionType === 'select') {
            newSelectedOptions = option;
            onToggle(null);
        }
    
        setSelectedOptions(newSelectedOptions);
        setInputValue('');
        setFilteredOptions(options);
        openingElementRef.current.focus();

        /*if(Array.isArray(newSelectedOptions)) {
            if(newSelectedOptions.length > 0)
                setInputPlaceholder(newSelectedOptions.length > 1 ? (newSelectedOptions[0] + " + " + (newSelectedOptions.length-1) + ((newSelectedOptions.length-1) === 1 ? " location" : " locations")).toUpperCase() : newSelectedOptions[0].toUpperCase());
            else
                setInputPlaceholder(placeholder);
        }
        else
            setInputPlaceholder(newSelectedOptions.toUpperCase());*/
        setInputPlaceholder(changePlaceholder(newSelectedOptions, placeholder));
    
        if (typeof onSelectionChange === "function") {
            onSelectionChange(newSelectedOptions);
        }
    
        onOptionsUpdate(newSelectedOptions);
    };    

    const toggleFilterList = (event) => {
        event.stopPropagation();
        onToggle(id);
    };

    const handleClickOutside = (event) => {
        if (
            openingElementRef.current &&
            !openingElementRef.current.contains(event.target) &&
            (!filterListElementRef.current ||
                !filterListElementRef.current.contains(event.target))
        ) {
            onToggle(null);
        }
    };

    const handleFilterChange = (event) => {
        const filterText = event.target.value.toLowerCase();
        setInputValue(filterText);
        setFilteredOptions(options.filter(option =>
            option.title.toLowerCase().includes(filterText)
        ));
    };

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        setInputPlaceholder(changePlaceholder(selectedOptions, placeholder));
    }, [selectedOptions, placeholder]);

    useEffect(() => {
        if (activeDropdown === id) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
                document.removeEventListener('touchstart', handleClickOutside);
            };
        }
    }, [activeDropdown, id]);

    return (
        <div className={`SearchFilterOptions search-input-select bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full relative ${activeDropdown === id ? 'transition duration-300 ease-in-out' : ''}`}>
            
            <span className="flex justify-between relative">
                <input type="text" 
                    className={`w-full h-[46px] bg-[#F1F1F1] placeholder:font-benton font-benton text-[11px] placeholder:text-[11px] placeholder:text-black tracking-[1.2px] leading-4 font-normal outline-none ${activeDropdown === id ? "border-0 md:border border-black" : ""} pl-3.5`}
                    placeholder={inputPlaceholder} 
                    onChange={handleFilterChange}
                    ref={openingElementRef}
                    onClick={toggleFilterList}
                    value={inputValue}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {useDropdownArrow && (
                    <DropdownArrow 
                        activeDropdown={activeDropdown} 
                        id={id}
                    />
                )}
            </span>

            {activeDropdown === id && (
                <div className={`static md:absolute z-50 top-[52px] bg-[#F1F1F1] flex flex-col w-full rounded-md max-h-[160px] custom-scrollbar overflow-y-scroll`} ref={filterListElementRef}>
                    {filteredOptions.map((option) => (
                        <div
                            className={`py-2 px-3.5 hover:bg-dark-blue hover:text-white cursor-pointer ${
                                selectedOptions.includes(option.title)
                                    ? 'bg-dark-blue text-white'
                                    : ''
                            }`}
                            value={option.value}
                            key={option.id}
                            onClick={() => handleOptionSelect(option.title)}
                        >
                            {option.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchInputSelect;
