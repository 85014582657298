import MobileSearchCategories from "../components/Home/MobileSearchCategories";
import HomeHero from "../components/Home/HomeHero";
import { useEffect, useState } from "react";
import { useGetHome } from "../queries/Pages";
import DynamicComponent from "../components/DynamicComponent";
import { SeoHelmet, seoData } from "../utils/Seo";

const Home = () => {
    const homepage = useGetHome();
    const homeComponents = homepage?.document.document;
    const [seoSettings, setSeoSettings] = useState({});

    useEffect(() => {
        if(homepage) {
            setSeoSettings(seoData({...homepage, defaultTitle: "International Realty Sotheby's Homepage"}));
        }
    }, [homepage]);

    return (
        <div className="homepage">
            <SeoHelmet seoSettings={seoSettings} />

            <MobileSearchCategories />
            
            {homeComponents && homeComponents.map((componentData, index) => (
                componentData.component === "hero" ?
                <HomeHero key={index} props={componentData.props || {}} />
                :
                <DynamicComponent
                    key={index}
                    componentData={componentData}
                />
            ))}
        </div>
    );
}

export default Home;