function getGoogleDriveFileLink(url) {
    if(url === undefined || url.includes("uc?id="))
        return url;
    
    try {
        const parsedUrl = new URL(url);

        if(parsedUrl.hostname === "drive.google.com") {
            const match = url.match(/file\/d\/(.*?)\/view/);

            return match ? "https://drive.google.com/uc?id="+match[1] : null;
        }
    } catch (error) {
        console.error(`Invalid URL: ${url}`);
    }

    return url;
}

export default getGoogleDriveFileLink;