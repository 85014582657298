import { useMediaQuery } from 'react-responsive';
import RichTextRenderer from '../../utils/RichTextRenderer';

const SimpleTextBlock = ({ props, children }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div id={props.anchor ? props.anchor : undefined} className={`simple-text-block ${isMobile ? "container" : "small-container"} mx-auto w-full flex flex-col !max-w-[732px]`}>
            <RichTextRenderer data={children} />
        </div>
    );
}

export default SimpleTextBlock;