import { ReactComponent as SideArrow } from "../../assets/icons/gray_side_arrow.svg";
import { useState } from 'react';
import useSticky from '../../utils/useSticky';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as CloseIcon } from '../../assets/icons/cross_icon.svg';
import { useHistory } from "react-router-dom";
import DropdownArrow from "../DropdownArrow";

function getChildren(parentId, menu) {
    let children = [];

    menu.map(item => {
        if(item.parent && item.parent.id === parentId)
            children.push(item);
    });

    return children.length > 0 ? children : null;
}

function showHideSubmenu(item, expandedParentMenus) {
    if(expandedParentMenus.includes(item.id)) {
        return expandedParentMenus.filter(id => id !== item.id);
    } else {
        return [...expandedParentMenus, item.id];
    }
}

const JournalsMenu = ({ menu }) => {
    const isSticky = useSticky(32);
    const history = useHistory();
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const [dropdownExpanded, setDropdownExpanded] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);
    const [activeParentMenu, setActiveParentMenu] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedParentMenus, setExpandedParentMenus] = useState([]);

    const handleDesktopMenuClick = (item, isArrowClick = false) => {
        const children = getChildren(item.id, menu);
        console.log('menu clicked');
        if(isArrowClick) {
            setExpandedParentMenus(showHideSubmenu(item, expandedParentMenus));
            
            return;
        }

        setExpandedParentMenus(showHideSubmenu(item, expandedParentMenus));

        if(activeMenu === item.id && !children)
            return;

        if(children && activeMenu === item.id) {
            return;
        }

        setActiveMenu(item.id);

        if(!children)
            if(item.parent)
                setActiveParentMenu(item.parent.id);
            else
                setActiveParentMenu(0);
    };

    const handleMobileMenuClick = (item) => {
        const children = getChildren(item.id, menu);
        setActiveMenu(item.id);

        if(children) {
            if(dropdownExpanded === item.id)
                setDropdownExpanded(false);
            else
                setDropdownExpanded(item.id);
        } else {
            setDropdownExpanded(false);
            closeMobileMenu();
        }

        history.push(`${item.id === 0 ? "/journals" : `/journals/${item.slug}`}`);
    };

    const handleMobileChildClick = (child, event) => {
        event.stopPropagation();
        setActiveMenu(child.id);
        setActiveParentMenu(child.parent);
        closeMobileMenu();

        history.push(`/journals/${child.slug}`);
    }

    const handleOpenMobileMenu = (id) => {
        setIsMobileMenuOpened(!isMobileMenuOpened);
        setIsExpanded(id);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpened(false);
    };

    return (
        <>
            {/* Desktop Menu */}
            <div className={`journals-menu hidden md:flex flex-col gap-2.5 font-benton text-[15px] font-normal text-[#888888] uppercase ${isSticky ? 'sticky top-[72px]' : ''}`}>
                {menu.map(item => {
                    let children = getChildren(item.id, menu);
                    
                    return item.parent ? null :
                        (<div 
                            key={item.id} 
                            className={`text-center md:text-left inline-flex ${children ? "flex-col gap-[6px]" : ""} ${activeMenu === item.id ? "text-black" : ""}`}
                        >
                            
                                <span 
                                    className={`cursor-pointer flex ${activeMenu === item.id ? "font-bold" : ""}`}
                                    onClick={(event) => {
                                        if(event.target.closest('svg')) return;
                                        handleDesktopMenuClick(item);
                                    }}
                                >
                                    <Link to={`${item.id === 0 ? "/journals" : `/journals/${item.slug}`}`}>
                                    {item.title} 
                                    </Link>
                                    {children ? (
                                    <SideArrow 
                                        className={`ml-2 cursor-pointer ${expandedParentMenus.includes(item.id) ? "transform -rotate-90 transition-all duration-300" : "duration-200"}`} 
                                        id={item.id} 
                                        onClick={(event) => { 
                                            event.stopPropagation(); handleDesktopMenuClick(item, true); 
                                        }}
                                    />
                                    )
                                        : 
                                        ""
                                    }
                                </span>
                            
                            {children && (expandedParentMenus.includes(item.id)) &&
                                <div className="flex flex-col gap-2.5">
                                {children.map(child => (
                                    <Link to={`/journals/${child.slug}`} key={child.id}>
                                        <span 
                                            key={child.id} 
                                            className={`bullet cursor-pointer ${activeMenu === child.id ? "text-black font-bold" : "text-[#888888]"}`}
                                            onClick={() => handleDesktopMenuClick(child)}    
                                        >
                                            {child.title}
                                        </span>
                                    </Link>
                                ))}
                                </div>
                            }
                        </div>)
                    
                })}
            </div>
            {/* Mobile Menu */}
            <div className="flex flex-col md:hidden mb-[30px]">
                {isMobileMenuOpened && 
                    <div className="fixed inset-0 bg-white z-50 flex flex-col overflow-auto">
                        <div className="container">
                            <div className="mt-7 mb-3 text-xl flex justify-center relative">
                                Journal Categories
                                <CloseIcon 
                                    className="absolute right-0 top-[3px]" 
                                    onClick={closeMobileMenu} 
                                />
                            </div>
                            {menu.map(item => {
                                let children = getChildren(item.id, menu);
                                 
                                return item.parent ? null : (
                                    <div className="SearchFilterOptions bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full relative border border-transparent mt-[7px]" key={item.id}>
                                        
                                        <div
                                            className="py-3.5 cursor-pointer relative"
                                            onClick={() => handleMobileMenuClick(item) }
                                        >
                                            <span className="pl-3.5 pr-2 flex justify-between">
                                                {item.title}
                                                {children && <DropdownArrow id={item.id} />}
                                            </span>
                                    
                                            {dropdownExpanded === item.id && <div className="flex flex-col mt-2">
                                                {children && children.map(child => (
                                                    <span 
                                                        className={`pl-3.5 text-[15px] leading-[22px] capitalize h-[39px] flex items-center ${activeMenu === child.id ? "bg-sothebys-blue text-white" : ""}`}
                                                        key={child.id}
                                                        onClick={(event) => handleMobileChildClick(child, event)}    
                                                    >
                                                        {child.title}
                                                    </span>
                                                ))}
                                            </div>}
                                        </div>
                                        
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                {menu.map(item => {
                    let children = getChildren(item.id, menu);

                    return (
                        activeMenu === item.id && <div className="SearchFilterOptions container bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full relative border border-transparent" key={item.id}>
                            <div
                                className="py-3.5 cursor-pointer relative"
                                onClick={handleOpenMobileMenu}
                            >
                                <span className="pl-3.5 pr-2 flex justify-between">
                                    {item.title}
                                    <DropdownArrow isExpanded={false} />
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default JournalsMenu;