import PartnerCard from "./PartnerCard";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';

const PartnersListing = ({ partners }) => {
    const isWideScreen = useMediaQuery({ query: '(min-width: 1536px)' });
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1535px)' });
    const [typesGrid, setTypesGrid] = useState([]);
    
    useEffect(() => {
        /*if(isWideScreen)
            setTypesGrid(["heading", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular"]);

        if(isMediumScreen)
            setTypesGrid(["heading", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular"]);*/
        setTypesGrid(["regular", "regular", "regular", "regular", "regular","regular", "regular", "regular", "regular", "regular"]);
    }, [isWideScreen, isMediumScreen]);

    return (
        <>
            <div className="flex md:gap-x-[30px] gap-y-0 flex-wrap justify-center md:justify-start">
                {partners.map((partner, index) => (
                    <PartnerCard key={partner.id}
                        id={partner.id}
                        type={typesGrid[index]}
                        page={"listing"}
                        photo={partner.featuredImages[0] ? partner.featuredImages[0].url : ""} 
                        photo2={partner.featuredImages[1] ? partner.featuredImages[1].url : false}
                        category={partner.category}
                        title={partner.title}
                        excerpt={partner.excerpt.document}
                        createdAt={partner.createdAt}
                        readTime={partner.readTime}
                        slug={partner.slug}
                    />
                ))}
            </div>
            {/*<div className="my-[83px] flex justify-center">
                <span className="button !mx-[30px]">Load more</span>
            </div>*/}
        </>
    );
}

export default PartnersListing;