import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useGetNeighbourhoodByTitle } from "../../queries/Neighbourhoods";
import { useState, useEffect } from "react";
import LazyBackground from "../../utils/LazyBackground";
import { useMediaQuery } from 'react-responsive';

const NeighbourhoodBanner = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [neighbourhood, setNeighbourhood] = useState(null);
    const [currentCommunity, setCurrentCommunity] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [shouldRefetch, setShouldRefetch] = useState(false);

    useEffect(() => {
        if(queryParams.has('community.0')) {
            const communities = Array.from(queryParams.entries())
                .filter(([key]) => key.startsWith("community."))
                .map(([, value]) => value);
            
            if(communities.length === 1)
                setCurrentCommunity(communities[0]);
            else
                setCurrentCommunity(null);
        }
        else 
            setCurrentCommunity(null);
    }, [queryParams]);

    const fetchedNeighbourhood = useGetNeighbourhoodByTitle(currentCommunity, shouldRefetch);

    useEffect(() => {
        if(fetchedNeighbourhood) {
            setNeighbourhood(fetchedNeighbourhood);
            setShouldRefetch(false);
        }
    }, [fetchedNeighbourhood]);

    useEffect(() => {
        setShouldRefetch(true);
    }, [currentCommunity]);
        
    const RenderNeighbourhoodBanner = ({ neighbourhood }) => {
        if(fetchedNeighbourhood && neighbourhood?.banner_image?.url) {
            return (
                <div className="wide-container relative">
                    <LazyBackground
                        className={`mt-7 md:mt-0 w-full max-w-[1624px] h-[334px] md:h-[432px]`}
                        backgroundImage={neighbourhood.banner_image.url}
                        width={1624}
                        height={isMobile ? "334" : "432"}
                        quality={75}
                    />
                    <Link to={neighbourhood.Banner_url} className="w-full h-full">
                        <div className="button absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-white !border-0">
                            {neighbourhood.Banner_label}
                        </div>
                    </Link>
                </div>
            );
        }
    }

    {return neighbourhood && currentCommunity ? RenderNeighbourhoodBanner({ neighbourhood }) : null;
    }
};

export default NeighbourhoodBanner;