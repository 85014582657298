import React, { useEffect, useState, useRef, useMemo } from 'react';
import { GoogleMap, useLoadScript, MarkerF, InfoWindow, useJsApiLoader, StreetViewPanorama } from '@react-google-maps/api';
import HomeIcon from '../assets/icons/favorites.svg';

const ShowPropertyMap = ({ latitude, longitude, width, height}) => {
    const mapRef = useRef(null);
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    const [markerPosition, setMarkerPosition] = useState({lat, lng});
    const { isLoaded } = useLoadScript({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY});
    
    const center = useMemo(() => ({lat: lat, lng: lng}), []);
    useEffect(() => {
        setTimeout(() => {
            setMarkerPosition({lat, lng});
        }, 100);
    }, [lat, lng]);

    if(!isLoaded)
        return <div>Loading map...</div>;

    return (
        <GoogleMap
            zoom={14}
            center={center}
            mapContainerStyle={{ height: height, width: width }}
        >
            <MarkerF position={center} />
        </GoogleMap>
    );
}

export const MemoizedShowPropertyMap = React.memo(ShowPropertyMap);

export const ShowStreetView = ({ latitude, longitude, width, height }) => {
    const [isStreetViewAvailable, setIsStreetViewAvailable] = useState(null);
    const { isLoaded } = useLoadScript({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY});
  
    useEffect(() => {
        if (isLoaded) {
            const lat = parseFloat(latitude);
            const lng = parseFloat(longitude);
            const streetViewService = new window.google.maps.StreetViewService();
            
            streetViewService.getPanorama({ location: { lat, lng }, radius: 50 }, (data, status) => {
                if (status === window.google.maps.StreetViewStatus.OK) {
                    setIsStreetViewAvailable(true);
                } else {
                    setIsStreetViewAvailable(false);
                }
            });
        }
    }, [isLoaded, latitude, longitude]);

    const initializeStreetView = (node) => {
        if (node && isStreetViewAvailable && window.google) {
            const lat = parseFloat(latitude);
            const lng = parseFloat(longitude);
            new window.google.maps.StreetViewPanorama(
                node,
                {
                    position: { lat, lng },
                    pov: { heading: 34, pitch: 0 },
                }
            );
        }
    };

    if(isStreetViewAvailable === null) {
        return <div>Loading...</div>;
    }
    else if(isStreetViewAvailable === false) {
        return <div>Street View is unavailable for this location!</div>;
    }
    else {
        return <div ref={initializeStreetView} style={{ width: width, height: height }} />;
    }
};


export function ShowSchoolsMap({ latitude, longitude, width, height }) {
    const [schools, setSchools] = useState([]);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [infoWindowOpen, setInfoWindowOpen] = useState(true);
    const mapStyles = {
        height: height,
        width: width
    };
    const { isLoaded } = useLoadScript({googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY});

    const defaultCenter = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    }

    const onMarkerClick = (marker) => {
        setActiveMarker(marker);

        setTimeout(() => {
            var elements = document.getElementsByClassName('gm-style-iw-d');

            for(var i = 0; i < elements.length; i++) {
                if(elements[i].innerHTML.trim() === '') {
                    elements[i].closest('.gm-style-iw-a').remove();
                }
            }
        }, 1);
    }

    const onInfoWindowClose = () => {
        setActiveMarker(null);
    }
    
    useEffect(() => {
        const fetchSchools = async () => {
            const proxyUrl = 'https://fandftesting.com/proxy.php';

            const data = {
                location: `${defaultCenter.lat},${defaultCenter.lng}`,
                radius: '5000',
                type: 'school',
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }

            const response = await fetch(proxyUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const jsonData = await response.json();
            
            setSchools(jsonData.results);
        };

        fetchSchools();
    }, []);

    useEffect(() => {
        if(isLoaded)
            setMapLoaded(true);
    }, []);

    if(schools.length === 0) {
        return <p>Loading...</p>
    }

    return (
        <GoogleMap
            zoom={14}
            center={defaultCenter}
            mapContainerStyle={{ height: height, width: width }}
            onLoad={() => setMapLoaded(true)}
        >
            <MarkerF 
                position={defaultCenter}
                icon={{
                    url: HomeIcon,
                    scaledSize: new window.google.maps.Size(50, 50)
                  }} 
            />

            {mapLoaded && schools.map((school, index) => {
                return (
                    <div key={index}>
                        <MarkerF 
                        position={{ 
                            lat: parseFloat(school.geometry.location.lat), 
                            lng: parseFloat(school.geometry.location.lng) 
                        }}
                        icon={{
                            url: school.icon,
                            scaledSize: new window.google.maps.Size(30, 30)
                        }}
                        onClick={() => onMarkerClick(school)} 
                    />
                    {activeMarker === school && (
                        <InfoWindow
                            position={{ 
                                lat: parseFloat(school.geometry.location.lat), 
                                lng: parseFloat(school.geometry.location.lng) 
                            }}
                            onCloseClick={onInfoWindowClose}
                            className="my-info"
                        >
                            <div>
                                <h4>{school.name}</h4>
                                <p>{school.vicinity}</p>
                            </div>
                        </InfoWindow>
                    )}
                    </div>
                )})
            }
        </GoogleMap>
    );
}

export const MemoizedShowSchoolsMap = React.memo(ShowSchoolsMap);

export const CheckStreetViewAvailability = ({latitude, longitude, setStreetViewAvailable}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });
  
    const [isAvailable, setIsAvailable] = useState(false);
  
    useEffect(() => {
        if (isLoaded) {
          const sv = new window.google.maps.StreetViewService();
          sv.getPanorama(
            { location: { lat: latitude, lng: longitude }, radius: 50 }, 
            (data, status) => {
              if (status === window.google.maps.StreetViewStatus.OK) {
                setIsAvailable(true);
              } else {
                setIsAvailable(false);
              }
            }
          );
        }
    }, [isLoaded, latitude, longitude]);

  
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
  
    return isAvailable ? <div>Street view available</div> : <div>Not available</div>;
};