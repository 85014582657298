import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LazyBackground from "../../utils/LazyBackground";
import { getCloudflareImageUrl } from "../../utils/getCloudflareUrl";
import { useMediaQuery } from 'react-responsive';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import { convertDate } from "../../utils/dateUtils";
import { ReactComponent as SideArrow } from "../../assets/icons/black_side_arrow.svg";

function getMaxWidthClass(type) {
    switch(type) {
        case "heading":
            return "md:max-w-[702px] 2xl:max-w-none";
        case "regular":
            return "max-w-[336px]";
        case "double":
            return "max-w-[702px]";
        default:
            return "";
    }
}

function getMaxHeightClass(type) {
    switch(type) {
        case "heading":
            return "md:h-[702px]";
        case "regular":
            return "h-[478px]";
        case "double":
            return "h-[702px]";
        default:
            return "";
    }
}

const MediaPrArticleCard = ({
    id, 
    type = "regular",
    page = "",
    photo, 
    photo2 = false, 
    category, 
    title, 
    excerpt, 
    createdAt,
    slug, 
    readTime }) => {
     
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const date = convertDate(createdAt, "MMMM do, yyyy");
    
    return (
        <div className={`${page === "listing" && isMobile ? "wide-container" : ""} flex flex-col w-full ${getMaxWidthClass(type)}`}>
            <div className="flex flex-col">
                <div className={`${type === "heading" ? "flex flex-row items-end gap-[30px]" : "h-[334px] md:h-[478px]"}`}>
                    <Link to={`/pr-article/${slug}`} className="w-full h-full hover:opacity-70">
                        <LazyBackground 
                            className={`mt-7 md:mt-0 w-full h-full bg-cover bg-no-repeat bg-center ${getMaxHeightClass(type)}`}
                            backgroundImage={photo}
                            width={702}
                            height={type === "heading" ? 702 : 478}
                            quality={75}
                        />
                    </Link>
                    {type === "heading" && photo2 &&
                        <Link to={`/pr-article/${slug}`} className="hidden 2xl:block w-full max-w-[336px] hover:opacity-70">
                            <LazyBackground
                                className={"bg-cover bg-no-repeat bg-center w-full h-[435px]"}
                                backgroundImage={photo2}
                                width={336}
                                height={435}
                                quality={75}
                            />
                        </Link>
                    }
                </div>
                <div className="container md:no-container">
                    <p className="mb-3 mt-4 tracking-[1.1px] font-benton text-[11px] leading-6 uppercase border-t-0 border-l-0 border-r-0 border-[1.2px] border-black w-fit font-normal">
                        <Link to={`/media-pr/${category.slug}`} className="hover:opacity-70">{category.title}</Link>
                    </p>
                    <p className="text-xl mb-3 font-acta">
                        <Link to={`/pr-article/${slug}`} className="hover:opacity-70">{title}</Link>
                    </p>
                    <div className="tracking-[0.2px] font-benton font-normal text-[15px] mb-3 leading-6 max-w-[500px]">
                        <DocumentRenderer document={excerpt} />
                    </div>
                </div>
            </div>
            <div className="container md:no-container tracking-[0.2px] font-benton font-medium text-[13px] leading-5 h-full items-end flex">
                {date}
            </div>
            <div className="mt-3 font-benton font-medium text-[13px] leading-5 mb-[30px]">
                <Link to={`/pr-article/${slug}`} className="flex items-center hover:opacity-70">
                    Read article <span className="ml-2"><SideArrow /></span>
                </Link>
            </div>
        </div>
    );
}

export default MediaPrArticleCard;