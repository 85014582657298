import { useState, useEffect } from 'react';
import SearchOptions from "../components/Search/SearchOptions";
import SearchProperties from "../components/Search/SearchProperties";
import { Link } from 'react-router-dom';
import NeighbourhoodBanner from '../components/Search/NeighbourhoodBanner';

const Search = ({ settings }) => {
    const [showResults, setShowResults] = useState(false);
    const [propertyCount, setPropertyCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const perPage = 24;
    const [searchResponses, setSearchResponses] = useState([]);

    const handleLoadMore = () => {
        setPageNumber(pageNumber => pageNumber + 1);
    }

    const handlePageNumberChange = (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const handleSearchClick = (response) => {
        if(pageNumber === 0)
            setSearchResponses([response]);
        else
            setSearchResponses((prevResponses) => [...prevResponses, response]);

        setShowResults(true);
    }

    const handlePropertyCountChange = (response) => {
        setPropertyCount(response);
    }

    useEffect(() => {
            document.title = "Search Properties on Sotheby's";
    }, []);

    return (
        <div>
            <SearchOptions 
                onSearchClick={handleSearchClick} 
                perPage={perPage} 
                pageNumber={pageNumber}
                onPropertyCountChange={handlePropertyCountChange}
                onPageNumberChange={handlePageNumberChange}
                onLoadMore={handleLoadMore}
                settings={settings}
            />

            <NeighbourhoodBanner />

            {showResults && (
                <div className="searchResults">
                    <p className="mt-12 font-benton text-sm uppercase text-center">Search Results</p>
                    <p className="mt-4 text-center text-4xl max-w-[910px] mx-auto">
                        {propertyCount && propertyCount > 0 ?
                            `We have ${propertyCount} properties available based on your search` :
                            "No properties found"
                        }
                    </p>
                    <div className="mt-10 mb-[62px] mx-auto text-center">
                        <Link to="#" className="button">Save search</Link>
                    </div>

                    {searchResponses.map((properties, index) => (
                        <SearchProperties 
                            key={index} 
                            properties={properties} 
                            columns={settings.columns}    
                        />
                    ))}
                    

                    {perPage * (pageNumber + 1) < propertyCount && (
                        <div className="mt-11 text-center">
                            <button className="button" onClick={handleLoadMore}>Load More</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Search;