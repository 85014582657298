import PropertyCard from "../Property/PropertyCard";

const PropertiesList3Columns = ({ properties }) => {
    console.log('3 columns run')
    return (
        <div className="search-result-3-columns tab-content container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-[43px] gap-y-[20px] md:gap-y-[35px] xl:gap-y-[50px]">
            { properties && properties.map((property, index) => (
                <div key={property.idField}>
                    <PropertyCard key={index} property={property} />
                </div>
            ))}       
        </div>
    )
}

export default PropertiesList3Columns;