import MediaPrArticleCard from "./MediaPrArticleCard";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';

const MediaPrListing = ({ articles }) => {
    const isWideScreen = useMediaQuery({ query: '(min-width: 1536px)' });
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1535px)' });
    const [typesGrid, setTypesGrid] = useState([]);
    
    useEffect(() => {
        if(isWideScreen)
            setTypesGrid(["heading", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular"]);

        if(isMediumScreen)
            setTypesGrid(["heading", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular", "regular"]);
    }, [isWideScreen, isMediumScreen]);

    return (
        <>
            <div className="flex md:gap-x-[30px] gap-y-0 flex-wrap justify-center md:justify-start">
                {articles.map((article, index) => (
                    <MediaPrArticleCard key={article.id}
                        id={article.id}
                        type={typesGrid[index]}
                        page={"listing"}
                        photo={article.featuredImages[0] ? article.featuredImages[0].url : ""} 
                        photo2={article.featuredImages[1] ? article.featuredImages[1].url : false}
                        category={article.category}
                        title={article.title}
                        excerpt={article.excerpt.document}
                        createdAt={article.createdAt}
                        readTime={article.readTime}
                        slug={article.slug}
                    />
                ))}
            </div>
            {/*<div className="my-[83px] flex justify-center">
                <span className="button !mx-[30px]">Load more</span>
            </div>*/}
        </>
    );
}

export default MediaPrListing;