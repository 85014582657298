import MediaPrArticleCard from "../MediaPR/MediaPrArticleCard";
import ActionButton from "./ActionButton";

const MediaPrContentBlock = ({ props }) => {
    let articles = [];
    const currentTime = new Date().getTime();

    props.articles.map(article => {
        const targetTime = article.data.publishedAt !== null ? new Date(article.data.publishedAt).getTime() : null;

        if(article.data && article.data.status === "published" && (article.data.publishedAt === null || targetTime === null || currentTime > targetTime))
            articles.push(article.data);
    });
    
    return (
        <div className="container" id={props.anchor ? props.anchor : undefined}>
            {props.title &&
                <h3 className="text-center">{props.title}</h3>
            }
            <div className="flex md:gap-x-[30px] gap-y-[40px] md:gap-y-[72px] flex-wrap justify-center md:justify-start">
                {articles.map((article, index) => (
                    <MediaPrArticleCard key={article.id}
                        id={article.id}
                        type={"regular"}
                        page={"listing"}
                        photo={article.featuredImages[0] ? article.featuredImages[0].url : ""} 
                        photo2={article.featuredImages[1] ? article.featuredImages[1].url : false}
                        category={article.category}
                        title={article.title}
                        excerpt={article.excerpt.document}
                        createdAt={article.createdAt}
                        readTime={article.readTime}
                        slug={article.slug}
                    />
                ))}
            </div>

            {props.action && props.action.label && props.action.url && (
                <ActionButton url={props.action.url} label={props.action.label} />
            )}
        </div>
    );
};

export default MediaPrContentBlock;