import MobileSearchCategories from "../components/Home/MobileSearchCategories";
import HomeHero from "../components/Home/HomeHero";
import { useEffect, useState } from "react";
import { useGetHome, useGetLanding } from "../queries/Pages";
import DynamicComponent from "../components/DynamicComponent";
import { SeoHelmet, seoData } from "../utils/Seo";
import { useRouteMatch } from 'react-router-dom';

const Landing = ({ settings }) => {
    const match = useRouteMatch();
    const slug = match.params.slug;
    const cleanedSlug = slug.startsWith("/") ? slug.slice(1) : slug;
    const landingPage = useGetLanding("/landings/"+cleanedSlug);
    const landingPageComponents = landingPage?.document.document;
    const [seoSettings, setSeoSettings] = useState({});

    /*useEffect(() => {
        if(homepage) {
            setSeoSettings(seoData({...homepage, defaultTitle: "International Realty Sotheby's Homepage"}));
        }
    }, [homepage]);*/

    return (
        <div className="homepage">
            <SeoHelmet seoSettings={seoSettings} />
 
            {landingPageComponents && landingPageComponents.map((componentData, index) => (
                componentData.component === "hero" ?
                <HomeHero key={index} props={componentData.props || {}} />
                :
                <DynamicComponent
                    key={index}
                    componentData={componentData}
                />
            ))}
        </div>
    );
}

export default Landing;