import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

// Create a component for the arrow
const DropdownArrow = ({ isExpanded }) => {
    return (
        <span className={`ml-2 self-center arrow ${isExpanded ? 'arrowRotate' : ''
        }`}>
            <ArrowDown />
        </span>
    );
};

const SearchSelectMulticolumnToggle = React.forwardRef((props, ref) => {
    const { onClick, isExpanded } = props;
    //const [isExpanded, setIsExpanded] = useState(false);

    /*const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
        onClick();
    }*/

    return (
        <div
            className={`SearchFilterOptionsMulticolumnToggle bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full h-full flex items-center justify-center cursor-pointer relative ${isExpanded ? 'transition duration-300 ease-in-out border border-black' : ''}`}
            onClick={onClick}
            ref={ref}
        >
            <div className="hidden md:block pl-3.5 pr-2 relative w-[24px] h-[24px]">
                {!isExpanded && (
                    <div className={`absolute w-full border-2 border-black h-[2px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-sm ${isExpanded ? 'opacity-0' : ''}`}></div>
                )}

                <div className={`absolute h-full border-2 border-black w-[2px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-sm ${isExpanded ? 'rotate-90' : ''}`}></div>
            </div>
            <div className="flex items-start justify-between md:hidden w-full pl-3.5 pr-2">
                Features & Amenities
                <DropdownArrow isExpanded={isExpanded} />
            </div>
        </div>
    );
});
  
export default SearchSelectMulticolumnToggle;
