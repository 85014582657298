import { useEffect } from "react";
import MediaPrListing from "../components/MediaPR/MediaPrListing";
import MediaPrMenu from "../components/MediaPR/MediaPrMenu";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetMediaPrCategories, useGetPrArticles } from "../queries/MediaPr";

const MediaPR = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const { category } = useParams();

    useEffect(() => {
        document.title = "Media / PR Articles - Sotheby's";
    }, []);

    const prCategories = useGetMediaPrCategories();
    const { data: prArticles, isLoading, isError } = useGetPrArticles(10, 0, category);
    
    return (
        <div className={`media-pr ${!isMobile ? "container" : ""} md:mt-[35px] flex flex-col md:flex-row md:gap-[37px]`}>
            <div className="md:flex-grow min-w-min md:max-w-[335px]">
                <h1 className="h-[50px] text-[32px] md:text-[48px] leading-[48px] mb-2.5 md:mb-[51px] text-center md:text-left">Media / PR</h1>
                {prCategories && <MediaPrMenu menu={prCategories} />}
            </div>
            <div className="flex-grow w-full md:max-w-[1068px]">
                {isLoading && <div>Loading Articles . . .</div>}
                {isError && <div>An error occured!</div>}
                {prArticles && prArticles.length > 0 && <MediaPrListing articles={prArticles} />}
                {prArticles && prArticles.length === 0 &&
                    <div className={`${isMobile ? "container mt-5" : ""}`}>No articles available in this category</div>
                }
            </div>
        </div>
    );
}

export default MediaPR;