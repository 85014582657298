import PropertyCard from "../Property/PropertyCard";

const AgentProperties = ({ properties }) => {    
    return (
        <div className="container">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-[30px] xl:gap-[43px]">
                {properties.length > 0 &&
                    properties.map((property, index) => (
                        <div key={index} className="">
                            <PropertyCard property={property} />
                        </div>
                    ))
                }
                {properties.length === 0 &&
                    <div>There are no properties to show</div>
                }
            </div>
        </div>
    );
}

export default AgentProperties;