export function getCurrencySymbol(currencyCode) {
    if(currencyCode === "GBP")
        return "£";
    else if(currencyCode === "GBP")
        return "£";
    else if(currencyCode === "GBP")
        return "£";
}

export function formatPriceOutput(price) {
    return parseFloat(price).toLocaleString();
}