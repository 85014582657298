import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import DynamicComponent from '../components/DynamicComponent';
import { useGetPrArticle, useGetOtherPrArticles } from "../queries/MediaPr";
import OtherPrArticles from '../components/MediaPR/OtherPrArticles';
import { SeoHelmet, seoData } from "../utils/Seo";

const PrArticle = () => {
    const { slug } = useParams();
    const article = useGetPrArticle(slug);
    const articleComponents = article && article.document.document;
    const otherPrArticles = useGetOtherPrArticles(4, article?.id);
    const [seoSettings, setSeoSettings] = useState({});
    let extraProps = {};

    useEffect(() => {
        if(article)
            setSeoSettings(seoData({...article, defaultTitle: `${article.title} - Sotheby's`}));
    }, [article]);

    return (
        <div className="pr-article mt-7">
            <SeoHelmet seoSettings={seoSettings} />

            {articleComponents && articleComponents.map((componentData, index) => {
                if(componentData.component === "hero") { 
                    extraProps = {
                        props: 
                            {
                                title: article.title, 
                                createdAt: article.createdAt, 
                                author: article.author, 
                                category: article.category, 
                                image: article.featuredImages[0].url, 
                                readTime: article.readingTime
                            }
                        };
                } else {
                    extraProps = {};
                }

                return (
                    <DynamicComponent
                        key={index}
                        componentData={{...componentData, ...extraProps}}
                    />
                );
            })}

            {otherPrArticles && <OtherPrArticles articles={otherPrArticles} />}
        </div>
    );
}

export default PrArticle;