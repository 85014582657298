import React, { useState, useEffect } from 'react';

const Counter = ({ targetNumber, duration, startAnimation, setAnimationComplete }) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        if (startAnimation) {
            let start = 0;
            const increment = targetNumber / (duration / 10);
            /*if(targetNumber === 26000)
                console.log(increment);*/
            const timer = setInterval(() => {
                start += increment;
                setCount(Math.floor(start));

                if (start >= targetNumber) {
                    clearInterval(timer);
                    setCount(targetNumber);
                    setAnimationComplete(true);
                }
            }, 10);

            return () => clearInterval(timer);
        }
    }, [startAnimation]);

    return (
        <>
            <span>{count.toLocaleString()}</span>
        </>
    );
};


export default Counter;