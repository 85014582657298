import { useState, useEffect, useRef } from "react";
import { ReactComponent as RightArrow } from "../../assets/icons/right_arrow.svg";
import { searchOptions } from "../database";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import HomeSearchInputSelect from "../Search/HomeSearchInputSelect";
import { useGetLocations } from "../../queries/Properties";
import PrepareNeighbourhoodName from "../../utils/prepareNeighbourhoodName";

function getYoutubeVideoId(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^\&\?\/]+)/;
  const match = url.match(regex);
  return (match && match[1]) ? match[1] : null;
}

function extractGoogleDriveId(url) {
  const regex = /file\/d\/([a-zA-Z0-9_-]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

const getMimeType = (extension) => {
  const types = {
    'mp4': 'video/mp4',
    'm4v': 'video/x-m4v',
    'webm': 'video/webm',
  };

  return types[extension] || 'video/mp4';
}

const getFileExtension = (url) => {
  return url.split('.').pop();
}

const HomeHero = ({ props }) => {
  const getLocations = useGetLocations();

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false
  };
console.log('home hero', props)
  const videoRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [placeholderDesktop, setPlaceholderDesktop] = useState(
    "Search properties in:"
  );
  const [placeholderMobile, setPlaceholderMobile] = useState(
    "Search properties..."
  );
  const [activeCategory, setActiveCategory] = useState("Sale");
  const history = useHistory();
  const images = isMobile ? props.mobile_images : props.image;
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [community, setCommunity] = useState();
  const [videoError, setVideoError] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState(null);
  const [selfHostedVideoUrl, setSelfHostedVideoUrl] = useState(null);

  const handleLocationSelectionChange = (selectedLocation) => {
    setCommunity(selectedLocation);
  }

  const handleToggle = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const handleOptionsUpdate = (newOptions) => {
    
  }

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const handleSearchArrowClick = () => {
    let url = `/search?listingType=${activeCategory}`;

    if(community && community.length > 0) {
      const communityParams = community.map((value, index) => `community.${index}=${PrepareNeighbourhoodName(value)}`).join('&');
      url += `&${communityParams}`;
    }
    
    history.push(url);
  };

  useEffect(() => {
    if(getLocations)
      setLocations(getLocations);
  }, [getLocations]);

  // for Selfhosted Video
  useEffect(() => {
    if(selfHostedVideoUrl)
      videoRef.current.play();
  }, [selfHostedVideoUrl]);
  
  useEffect(() =>   {
    let dbVideoUrl = props.assets[0].asset.value.url;
    
    if(props.assets[0]?.asset?.discriminant === true && dbVideoUrl) {
      if(dbVideoUrl.includes("youtube.com"))
        setYoutubeVideoId(getYoutubeVideoId(dbVideoUrl));
      else if(dbVideoUrl.includes("drive.google.com")) {
        let gDriveFileId = extractGoogleDriveId(dbVideoUrl);

        if(gDriveFileId) {
          setSelfHostedVideoUrl(`https://drive.google.com/uc?export=download&id=${gDriveFileId}`);
        }
        else {
          setSelfHostedVideoUrl(dbVideoUrl);
        }
      }
      else {
        const extension = getFileExtension(dbVideoUrl);
        const mimeType = getMimeType(extension);
        setSelfHostedVideoUrl({ url:dbVideoUrl, type: mimeType });
      }
    }
  }, [props.assets]);

  return (
    <div className="wide-container w-full h-[554px] md:h-[952px] relative">
      {/*<Slider {...settings}>
        {images && images.map((image, index) => (
          <div key={index}>
            <LazyBackground
              className={`bg-cover bg-no-repeat bg-center h-[554px] md:h-[952px]`}
              backgroundImage={image.data.url}
              width={1634}
              height={902}
              quality={75}
              useGradient={true}
            />
          </div>
        ))}
        </Slider>*/}
      
      {/* YT video */}
      {youtubeVideoId && 
        <div className="video-background h-full w-full max-w-[1634px] relative">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtubeVideoId}?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&fs=0&disablekb=1&autohide=1&modestbranding=1&enablejsapi=1`}
            frameBorder="0"
            allowFullScreen
            allow="autoplay; encrypted-media"
            style={{ position: 'relative', zIndex: 1 }}
          ></iframe>
          <div className="absolute w-full h-full z-10 top-0 left-0"></div>
        </div>
      }


      {/* Self Hosted Video */}
      {selfHostedVideoUrl &&
        <div className="video-background h-full w-full max-w-[1634px] relative">
            <video 
                ref={videoRef}
                autoPlay 
                loop 
                muted
                playsInline
                onError={() => {console.log('errror'); setVideoError(true)}}
                style={{
                    position: "absolute",
                    width: "100%",
                    left: "50%",
                    top: "50%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translate(-50%, -50%)",
                    zIndex: "1",
                }}
            >
                <source 
                  src={selfHostedVideoUrl ? selfHostedVideoUrl.url : ""} 
                  type={selfHostedVideoUrl ? selfHostedVideoUrl.type : "video/mp4"}
                />
                Your browser does not support HTML5 video.
            </video>
            <div className="gradient-overlay z-[2]"></div>
            <div className="absolute w-full h-full z-10 top-0 left-0"></div>
        </div>
      }
      

      <div className="hero-searchbar absolute z-10 top-[28px] md:top-[20px] left-1/2 transform -translate-x-1/2 w-full bg-white mx-auto rounded-full flex items-center font-benton font-normal text-dark-blue">
        <div className="hero-searchbar-input w-full md:bg-white rounded-full h-full">
          <span className="ml-6 md:ml-0 h-full flex items-center text-[13px] font-normal">
            <HomeSearchInputSelect
              id="filter1"
              placeholder={isMobile ? placeholderMobile : placeholderDesktop}
              options={locations}
              optionType="multipleSelect"
              useDropdownArrow={false}
              onSelectionChange={handleLocationSelectionChange}
              activeDropdown={activeDropdown}
              onToggle={handleToggle}
              onOptionsUpdate={handleOptionsUpdate}
            />
          </span>
        </div>
        <div className="md:w-full h-full flex justify-end items-center tracking-[0.075rem] text-[11px]">
          {searchOptions.listingTypes.map((listingType) => (
            <span
              key={listingType.id}
              className={`hidden md:block mr-3 cursor-pointer border border-transparent uppercase px-[12px] py-[5px] ${
                activeCategory === listingType.value ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(listingType.value)}
            >
              {listingType.title}
            </span>
          ))}

          <span
            className="search-arrow cursor-pointer self-center w-[52px] flex justify-center items-center rounded-full bg-sothebys-blue h-full transition-all duration-300 mr-[-2px]"
            onClick={handleSearchArrowClick}
          >
            <RightArrow />
          </span>
        </div>
      </div>

      <h2 className="hero-copy-text font-normal absolute z-[3] top-[92px] left-1/2 transform -translate-x-1/2 w-full text-white text-center mx-auto">
        {props.title}
      </h2>
    </div>
  );
};

export default HomeHero;
