import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LazyBackground from "../../utils/LazyBackground";
import { useMediaQuery } from 'react-responsive';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import { ReactComponent as SideArrow } from "../../assets/icons/black_side_arrow.svg";
import useFetch from '../../utils/useFetch';

function getMaxWidthClass(type) {
    switch(type) {
        case "heading":
            return "md:max-w-[702px] 2xl:max-w-none";
        case "regular":
            return "max-w-[336px]";
        case "double":
            return "max-w-[517px]";
        default:
            return "";
    }
}

const NeighbourhoodsListingCard = ({
    id, 
    type = "regular",
    page = "",
    photo, 
    photo2 = false, 
    category, 
    title, 
    excerpt, 
    slug }) => {

    const requestBody = {
        community: title,
        listingType: "Sale"
    };
    
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const { data: response, isLoading: isLoadingProperties, error: errorProperties } = useFetch(
        `${apiUrl}/api/search-count`, 
        "POST", 
        requestBody);
     
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div className={`${page === "listing" && isMobile ? "wide-container" : ""} flex flex-col w-full ${getMaxWidthClass(type)}`}>
            <div className="flex flex-col">
                <div className={`${type === "heading" ? "flex flex-row items-end gap-[30px]" : ""}`}>
                    <Link to={`/neighbourhood/${slug}`} className="w-full h-full">
                        <LazyBackground
                            className={`md:mt-0 w-full h-[334px] md:h-[${type === "heading" ? "702" : "478"}px] hover:opacity-70`}
                            backgroundImage={photo}
                            width={getMaxWidthClass(type)}
                            height={isMobile ? "334" : "702"}
                        />
                    </Link>
                    {type === "heading" && photo2 &&
                        <Link to={`/neighbourhood/${slug}`} className="hidden 2xl:block w-full max-w-[336px] hover:opacity-70">
                            <LazyBackground
                                className={"w-full h-[463px] md:h-[336px]"}
                                backgroundImage={photo2}
                                width={336}
                                height={336}
                            />
                        </Link>
                    }
                </div>
                <div className="container md:no-container">
                    <p className="mb-3 mt-4 tracking-[1.1px] font-benton text-[11px] leading-6 uppercase border-t-0 border-l-0 border-r-0 border-[1.2px] border-black w-fit font-normal">
                        {category && <Link to={`/neighbourhoods/${category.slug}`} className="hover:opacity-70">
                            {category.title}
                        </Link> }
                    </p>
                    <p className="text-xl mb-3 font-acta">
                        <Link to={`/neighbourhood/${slug}`} className="hover:opacity-70">{title}</Link>
                    </p>
                    {excerpt &&
                        <div className="tracking-[0.2px] font-benton font-normal text-[15px] mb-3 leading-6 max-w-[500px]">
                            <DocumentRenderer document={excerpt} />
                        </div>
                    }
                </div>
            </div>
            <div className="container md:no-container tracking-[0.2px] font-benton font-medium text-[13px] leading-5 h-full items-end flex mb-[30px]">
                <Link to={`/neighbourhood/${slug}`} className="flex items-center justify-end">
                    Read guide <span className="ml-2"><SideArrow /></span>
                </Link>
                <Link to={`/search?community=${title.toLowerCase().replace(/\s/g, '-').replace(/'/g, '')}`} className="flex items-center ml-4">
                    {response && response.count} properties <span className="ml-2"><SideArrow /></span>
                </Link>
            </div>
        </div>
    );
}

export default NeighbourhoodsListingCard;