import { useState, useEffect } from 'react';

const useSticky = (topOffset = 0) => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    setSticky(window.pageYOffset > topOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [topOffset]);

  return isSticky;
};

export default useSticky;