import { useEffect, useRef, useState, forwardRef } from 'react';

const SearchSelectMulticolumn = forwardRef(({
    id,
    title,
    options,
    optionType,
    useDropdownArrow,
    onSelectionChange,
    activeDropdown,
    onToggle,
    onOptionsUpdate,
    toggleRef,
    selectedOptions,
    setSelectedOptions,
    onClear
}, ref) => {
    const handleOptionSelect = (option) => {
        if (optionType === 'multipleSelect') {
            setSelectedOptions((prevSelectedOptions) => {
                let updatedOptions;
                if (prevSelectedOptions.includes(option)) {
                    updatedOptions = prevSelectedOptions.filter(
                        (loc) => loc !== option
                    );
                } else {
                    updatedOptions = [...prevSelectedOptions, option];
                }
                onOptionsUpdate(updatedOptions);
                return updatedOptions;
            });
        } else if (optionType === 'select') {
            setSelectedOptions([option]);
            onToggle(null);
            onOptionsUpdate([option]);
        }
    };

    const clearSelectedOptions = () => {
        setSelectedOptions([]);
        onOptionsUpdate([]);
        if (onClear) onClear();
    };

    ref.current = { clearSelectedOptions };

    const openingElementRef = useRef(null);
    const filterListElementRef = useRef(null);
    const optionElementRef = useRef(null);

    const toggleFilterList = (event) => {
        event.stopPropagation();
        onToggle(id);
    };

    const handleClickOutside = (event) => {
        /*if (
          openingElementRef.current &&
          !openingElementRef.current.contains(event.target) &&
          (!filterListElementRef.current ||
            !filterListElementRef.current.contains(event.target)) &&
          (!toggleRef.current || !toggleRef.current.contains(event.target))
        )
        if (
            (!filterListElementRef.current ||
              !filterListElementRef.current.contains(event.target)) &&
            (!toggleRef.current || !toggleRef.current.contains(event.target))
          )*/
          if ((!toggleRef.current || !toggleRef.current.contains(event.target)) &&
            (!filterListElementRef.current || !filterListElementRef.current.contains(event.target))
        )
         {
          onToggle(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [activeDropdown, id, toggleRef]);

    return (
        <div className="SearchFilterOptionsMulticolumn bg-[#F5F6F6] font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full relative">
            <div className="flex flex-col">
                {/* Title */}
                <div className="hidden md:block ml-6 mt-2.5">
                    <p className="font-acta font-light text-xl leading-9">
                        {title}
                    </p>
                </div>
                {/* Grid */}
                <div 
                    className="flex flex-col md:grid md:grid-cols-4 bg-[#F5F6F6] md:ml-6 mt-2.5 mb-4 text-base" 
                    ref={filterListElementRef}
                >
                    {options.map((option) => (
                        <span
                            className={`py-2 pl-6 md:pl-3.5 pr-3 hover:bg-dark-blue hover:text-white cursor-pointer w-full md:w-fit ${
                                selectedOptions.includes(option.value)
                                ? "bg-dark-blue text-white"
                                : "bg-none"
                            }`}
                            value={option.value}
                            key={option.id}
                            onClick={() => handleOptionSelect(option.value)}
                            ref={optionElementRef}
                        >
                            {option.title}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default SearchSelectMulticolumn;
