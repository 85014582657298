import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';

const DropdownArrow = ({ isExpanded, id }) => {
    return (
        <span className={`ml-2 self-center arrow ${
            isExpanded === id ? 'arrowRotate' : ''
        }`}>
            <ArrowDown />
        </span>
    );
};

export default DropdownArrow;