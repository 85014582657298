import { ReactComponent as AppleIcon } from '../assets/icons/login_apple.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/login_google.svg';
import { ReactComponent as LinkedinIcon } from '../assets/icons/login_linkedin.svg';
import { ReactComponent as ShowPasswordIcon } from '../assets/icons/show_password.svg';
import { ReactComponent as HidePasswordIcon } from '../assets/icons/hide_password.svg';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        document.title = "Login Page - Sotheby's";
    }, []);

    return (
        <div className="container md:!max-w-[460px] md:!mx-auto text-center font-normal">
            <div className="mt-7 md:mt-12 mb-9 md:mb-7 text-[22px] leading-[27px] text-black font-normal">Log in</div>
            <div className="mb-7">
                <div>
                    <input type="email" placeholder="E-mail address" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none mb-4 pl-4" />
                </div>
                <div className="relative mb-4">
                    <input type={showPassword ? "text":"password"} placeholder="Password" className="login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none pl-4" />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={toggleShowPassword}>
                        {showPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                    </div>
                </div>
            </div>
            <div>
                <Link to="#">
                    <span className="button !px-[30px]">
                        Log in
                    </span>
                </Link>
                <div className="mt-7 mb-4 font-benton text-[15px] leading-[22px]">
                    or login with
                </div>
                <div className="flex gap-[55px] justify-center mb-6">
                    <a href="#">
                        <AppleIcon />
                    </a>
                    <a href="#">
                        <GoogleIcon />
                    </a>
                    <a href="#">
                        <LinkedinIcon />
                    </a>
                </div>
                <div className="flex flex-col gap-2.5 text-[#002349] font-benton text-[15px] leading-[22px] tracking-[0.375px] font-medium">
                    <Link to="/reset-password">
                        Forgot password?
                    </Link>
                    <Link to="/register">
                        Register new account
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;