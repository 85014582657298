import { useMediaQuery } from 'react-responsive';
import ActionButton from './ActionButton';
import LazyBackground from '../../utils/LazyBackground';

const TextWithImage = ({ props }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const textLeft = props.image_position === "r" ? true : false;
    const text = props.paragraph && props.paragraph.replace(/<br>/g, '\n');
    let verticalAlignment = "justify-center";
    
    if(props.text_vertical_alignment === "top")
        verticalAlignment = "justify-start";
    else if(props.text_vertical_alignment === "middle")
        verticalAlignment = "justify-center";
    else if(props.text_vertical_alignment === "bottom")
        verticalAlignment = "justify-end";

    return (
        <div id={props.anchor ? props.anchor : undefined} className={`text-with-image-block ${!isMobile ? "container" : ""} ${!props.image ? "small-container" : ""} w-full flex flex-col ${props.image ? "md:flex-row" : "md:flex-col"} md:gap-6 ${textLeft !== true ? "flex-col-reverse md:flex-row-reverse" : ""}`}>
            <div className={`${!isMobile ? "" : "container"} w-full ${props.image ? "md:w-1/2 max-w-[700px]" : "md:w-full"} flex flex-col ${verticalAlignment} ${props.big_image ? "basis-2/5" : ""}`}>
                {props.overtitle && 
                    <p className={`w-full font-benton text-sm uppercase tracking-widest font-normal mb-4 ${props.overtitle_underline ? "underline" : ""}`} dangerouslySetInnerHTML={{ __html: props.overtitle }}>
                    </p>
                }

                { props.title &&
                    <h3 className="text-[#1D1D1B] text-4xl block w-full text-left">{props.title}</h3>
                }

                { props.bigCopy &&
                    <p className="mt-8 text-[#0A0A0A] font-benton tracking-[0.375px] text-[28px] leading-[38px] font-extralight">{props.bigCopy}</p>
                }

                { props.smallCopy &&
                    <p className="mt-8 text-[#0A0A0A] font-benton tracking-[0.375px] text-[20px] leading-[30px] font-normal">{props.smallCopy}</p>
                }

                { text &&
                    <p className="mt-8 text-[#0A0A0A] font-benton tracking-[0.375px] text-[20px] leading-[30px] font-normal mb-0" style={{ whiteSpace: 'pre-line' }}>{text}</p>
                }

                {props.action && props.action.label && props.action.url && (
                    <ActionButton url={props.action.url} label={props.action.label} />
                )}
            </div>
            { props.image && 
                <div className={`${isMobile ? "wide-container mb-7" : ""} w-full flex md:w-1/2 ${textLeft === true ? "md:justify-end" : "md:justify-start"} ${props.big_image ? "basis-3/5" : ""}`}>
                    <LazyBackground
                        className={`w-full bg-cover bg-no-repeat bg-center ${props.big_image ? "" : "max-w-[565px]"} h-[463px] md:h-[682px]`}
                        backgroundImage={props.image.data.url}
                        width={props.big_image ? 864 : 565}
                        height={isMobile ? 463 : 682}
                    />
                </div>
            }
        </div>
    );
}

export default TextWithImage;