const renderers = {
    paragraph: ({ children, textAlign = 'left' }) => <p style={{ textAlign }}>{children}</p>,
    heading: ({ level, children }) => {
      const headingTag = `h${level}`;
      return <headingTag>{children}</headingTag>;
    },
    'unordered-list': ({ children }) => {
      return <ul>{children}</ul>;
    },
    'ordered-list': ({ children }) => {
        return <ol>{children}</ol>;
    },
    'list-item': ({ children }) => {
        return <li>{children.map(child => child.children)}</li>;
    },
    'list-item-content': ({ children }) => {
        return <>{children}</>;
    },
};

export default renderers;