import { ReactComponent as PdfIcon } from '../../assets/icons/pdf.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/map.svg';
import { ReactComponent as StreetViewIcon } from '../../assets/icons/street_view.svg';
import { ReactComponent as SchoolCheckerIcon } from '../../assets/icons/school_checker.svg';
import { ReactComponent as EpcIcon } from '../../assets/icons/epc.svg';
import { ReactComponent as EpcIconGray } from '../../assets/icons/epc_gray.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/property_back.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/property_save.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/property_share.svg';
import { ReactComponent as PrintIcon } from '../../assets/icons/property_print.svg';
import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { ReactComponent as SliderPrevArrow } from '../../assets/icons/slider_prev_black.svg';
import { ReactComponent as SliderNextArrow } from '../../assets/icons/slider_next_black.svg';
import useBackButton from '../../utils/useBackButton';
import { MemoizedShowPropertyMap, MemoizedShowSchoolsMap, ShowStreetView } from '../../utils/Maps';
import LazyBackground from '../../utils/LazyBackground';
import { useMediaQuery } from 'react-responsive';

const PropertyMainInfo = ({ property }) => {
    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current),
    };

    const [map, setMap] = useState(null);
    const [streetViewAvailable, setStreetViewAvailable] = useState(null);
    const goBack = useBackButton();
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const slidesTitles = [
        {icon: <MapIcon className="self-center" />, text: 'Map View'},
        {icon: <StreetViewIcon className="self-center" />, text: 'Street View'},
        {icon: <SchoolCheckerIcon className="self-center" />, text: 'School Checker'},
        {icon: <EpcIcon className="self-center" />, text: 'EPC'},
        {icon: <PdfIcon className="self-center" />, text: 'Generate PDF'}
    ];

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    }

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    }

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    const paragraphs = property.description ? property.description.split('\n\n') : [''];
    const firstParagraph = paragraphs[0];
    const remainingDescription = paragraphs.slice(1).join('\n\n');
    const remainingParagraphs = paragraphs.slice(1);
    const nextParagraph = remainingParagraphs.length > 0 ? remainingParagraphs[0] : '';
    const additionalDescription = remainingParagraphs.slice(1).join('\n\n');

    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    }

    /*useEffect(() => {
        const checkStreetView = async () => {
            try {
                const isAvailable = await CheckStreetViewAvailability(property.latitude, property.longitude);
                setStreetViewAvailable(isAvailable);
            } catch (error) {
                console.error(error);
            }
        };

        checkStreetView();
    }, [property.latitude, property.longitude]);*/

    /*useEffect(() => {
        CheckStreetViewAvailability(property.latitude, property.longitude, setStreetViewAvailable);
    }, [property.latitude, property.longitude]);*/

    return (
        <div className={`${isMobile || isTablet ? "wide-container" : "container"} flex flex-col lg:flex-row justify-between mt-[50px] lg:mt-[70px]`}>
            <div className={`${isMobile || isTablet ? "container !mx-auto" : ""}w-full lg:w-1/2 flex  justify-start`}>
                <div className="w-full max-w-[530px]">
                    <div className="flex items-center">
                        <Link to="#" className="hover:opacity-70">
                            <BackIcon className="self-center" />
                        </Link>
                        <Link to="#" className="flex">
                            <span 
                                className="ml-3.5 font-benton text-[15px] leading-[22px] text-[#888888] tracking-wide font-normal hover:text-black"
                                onClick={goBack}    
                            >BACK</span>
                        </Link>
                    </div>
                    <div className="mt-6 text-black uppercase font-benton font-normal leading-4 tracking-[1.5px] text-[10px]">
                        Property Ref: {property.reference_number}
                    </div>
                    <div className="mt-2.5 tracking-tighter font-normal text-2xl text-[42px] leading-[49px]">
                        {property.listing_title}
                    </div>
                    <div className="mt-5 uppercase font-benton tracking-[1.5px] font-normal text-sm leading-6 flex gap-[52px]">
                        <div className="flex gap-3 items-center hover:opacity-70">
                            <Link to="#">
                                <SaveIcon className="self-center" />
                            </Link>
                            <Link to="#">
                                <span>Save</span>
                            </Link>
                        </div>
                        <div className="flex gap-3 items-center hover:opacity-70">
                            <Link to="#" className="flex">
                                <ShareIcon className="self-center" />
                            </Link>
                            <Link to="#">
                                <span>Share</span>
                            </Link>
                        </div>
                        <div className="flex gap-3 items-center hover:opacity-70 hidden">
                            <Link to="#" className="flex">
                                <PrintIcon className="self-center" />
                            </Link>
                            <Link to="#">
                                <span>Print</span>
                            </Link>
                        </div>
                    </div>
                    <div className="mt-5 text-2xl font-normal">
                        {firstParagraph}
                    </div>
                    <div className="mt-5 font-benton text-base">
                        {nextParagraph}
                        {showMore && <div className="mt-2">{additionalDescription}</div>}
                        <span className="font-bold cursor-pointer" onClick={toggleShowMore}>
                            {showMore ? " Read less" : " Read more"}
                        </span>
                    </div>
                </div>
            </div>
            {/* Desktop */}
            <div className="hidden lg:block mt-[75px] md:mt-0 w-1/2">
                <div className="flex flex-col max-w-[687px] ml-auto">
                    <div className="flex justify-between mb-6 px-[3px] font-benton font-normal">
                        <Link to="#" className="flex">
                            <PdfIcon className="self-center" fill="gray" />
                        </Link>
                        <Link to="#">
                            <span className="text-[#888888]">Generate PDF</span>
                        </Link>
                        <div className="border border-t-0 border-r-0 border-b-0 border-[rgba(0, 0, 0, 0.2)]"></div>
                        <Link to="#" className="flex" onClick={() => handleTabClick(0)}>
                            <MapIcon className="self-center" fill={`${activeTab === 0 ? "black" : "gray"}`} />
                        </Link>
                        <Link to="#" className={`tab ${activeTab === 0 ? "active text-black" : "inactive text-[#888888]"}`} onClick={() => handleTabClick(0)} data-title="Map View">
                            <span>Map View</span>
                        </Link>
                        <div className="border border-t-0 border-r-0 border-b-0 border-[rgba(0, 0, 0, 0.2)]"></div>
                        <Link to="#" className="flex" onClick={() => handleTabClick(1)}>
                            <StreetViewIcon className="self-center" fill={`${activeTab === 1 ? "black" : "gray"}`}/>
                        </Link>
                        <Link to="#" className={`tab ${activeTab === 1 ? "active text-black" : "inactive text-[#888888]"}`} onClick={() => handleTabClick(1)} data-title="Street View">
                            <span>Street View</span>
                        </Link>
                        <div className="border border-t-0 border-r-0 border-b-0 border-[rgba(0, 0, 0, 0.2)]"></div>
                        <Link to="#" className="flex" onClick={() => handleTabClick(2)}>
                            <SchoolCheckerIcon className="self-center" fill={`${activeTab === 2 ? "black" : "gray"}`} />
                        </Link>
                        <Link to="#" className={`tab ${activeTab === 2 ? "active text-black" : "inactive text-[#888888]"}`} onClick={() => handleTabClick(2)} data-title="School Checker">
                            <span>School Checker</span>
                        </Link>
                        <div className={`border border-t-0 border-r-0 border-b-0 border-[rgba(0, 0, 0, 0.2)] ${property.epc_url ? "" : "hidden"}`}></div>
                        <Link to="#" className={`flex ${property.epc_url ? "" : "hidden"}`} onClick={() => handleTabClick(3)}>
                            {activeTab === 3 && <EpcIcon className="self-center" />}
                            {activeTab !== 3 && <EpcIconGray className="self-center" />}
                        </Link>
                    
                        <Link to="#" className={`tab ${activeTab === 3 ? "active text-black" : "inactive text-[#888888]"} ${property.epc_url ? "" : "hidden"}`} onClick={() => handleTabClick(3)} data-title="EPC">
                            <span>EPC</span>
                        </Link>
                    </div>
                    {/* Tabs Content */}
                    <div className={`tab-content ${activeTab === 0 ? "active" : "inactive"}`}>
                        {property && <MemoizedShowPropertyMap
                            latitude={property.latitude}
                            longitude={property.longitude}
                            width={"100%"}
                            height={600}
                        />}
                    </div>
                    {/*<div className={`tab-content ${activeTab === 1 ? "active" : "inactive"}`}>*/}
                    {activeTab === 1 && (
                        <div>
                            <ShowStreetView 
                                latitude={property.latitude} 
                                longitude={property.longitude}
                                width={"100%"}
                                height={600}
                                isActive={activeTab === 1}
                            />
                        </div>
                    )}
                    {/*<div className={`tab-content ${activeTab === 2 ? "active" : "inactive"}`}>*/}
                    {activeTab === 2 && (
                        <div>
                            <MemoizedShowSchoolsMap
                                latitude={property.latitude}
                                longitude={property.longitude}
                                width={"100%"}
                                height={600}
                                isActive={activeTab === 2}
                            />
                        </div>
                    )}
                    {property.epc_url && 
                        <div className={`tab-content ${activeTab === 3 ? "active" : "inactive"} flex justify-center`}>
                            <LazyBackground
                                className={"w-full max-w-[516px] h-[473px]"}
                                backgroundImage={property.epc_url}
                                width={516}
                                height={473}
                            />
                        </div>
                    }
                </div>
            </div>
            {/* Mobile */}
            <div className="block lg:hidden mt-[40px] w-full justify-center">
                <div className="flex flex-col">
                    <div className="container flex justify-between items-center h-[72px]">
                        <SliderPrevArrow onClick={handlePrevClick} />
                        <div className="flex items-center justify-center">
                            {slidesTitles[currentSlide].icon}
                            <span className="ml-4 font-normal font-benton">{slidesTitles[currentSlide].text}</span>    
                        </div>
                        <SliderNextArrow onClick={handleNextClick} />
                    </div>
                    <Slider {...sliderSettings} ref={sliderRef}>
                        <div className="!block">
                            {property && <MemoizedShowPropertyMap
                                latitude={property.latitude}
                                longitude={property.longitude}
                                width={"100%"}
                                height={isMobile ? 374 : 600}
                            />}
                        </div>
                        <div className="!block">
                            <ShowStreetView 
                                latitude={property.latitude} 
                                longitude={property.longitude}
                                width={"100%"}
                                height={isMobile ? 374 : 600}
                                isActive={currentSlide === 1}
                            />
                        </div>
                        <div className="!block">
                            <MemoizedShowSchoolsMap
                                latitude={property.latitude}
                                longitude={property.longitude}
                                width={"100%"}
                                height={isMobile ? 374 : 600}
                                isActive={currentSlide === 2}
                            />
                        </div>
                        <div className={`${isMobile ? "h-[374px]" : "h-[600px]"} !block`}>
                            <LazyBackground
                                className={`bg-no-repeat bg-center h-full`}
                                backgroundImage={property.epc_url}
                                width={isMobile ? 374 : 600}
                            />
                        </div>
                        <div>
                            
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default React.memo(PropertyMainInfo);