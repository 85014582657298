import TitleBlock from "../components/ContentBlocks/TitleBlock";
import { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from '../assets/icons/search_rounded.svg';
import { useGetAgents } from "../queries/Agents";
import TeamMemberCard from "../components/TeamMember/TeamMemberCard";

const Team = ({ settings }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [variables, setVariables] = useState({ name: searchTerm });
    const agents = useGetAgents(variables);

    const props = {
        title: "Meet the team",
        paragraph: "For those who feel the need for a change but aren’t in a place to set aside time for house hunting, a luxury rental offers an immediate option for escape and novelty.",
        text_alignment: "center"
    };

    useEffect(() => {
        document.title = "Team Sotheby's";
    }, []);

    useEffect(() => {
        setVariables({ name: searchTerm });
    }, [searchTerm]);

    return (
        <div className="team-page">
            <div className="mt-12"></div>
            <TitleBlock props={props} />
            <div className="mt-[70px] mb-[30px] md:mb-11 h-10 !max-w-[332px] mx-auto rounded-full relative">
                <input 
                    type="text" 
                    className="bg-[#F9F9F8] w-full h-full rounded-full pl-5 font-benton outline-none" 
                    placeholder="Search for an advisor" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}    
                />
                <SearchIcon className="absolute top-0 right-0 cursor-pointer" />
            </div>
            <div className="container flex w-full max-w-[1276px] flex-wrap gap-[38px] mx-auto justify-center">
                {agents && agents.length > 0 && agents.map((agent, index) => (
                    <TeamMemberCard member={agent} phone={settings.phone} whatsapp={settings.whatsapp} key={index} />
                ))}
                {agents && agents.length === 0 && (
                    <div className="text-center mx-auto mt-12">
                        <p className="font-acta font-normal text-[20px] text-[#2A2A2A]">
                            No results for “{searchTerm}”
                        </p>
                        <p className="font-benton text-[15px] font-normal text-[#0A0A0A]">
                            Check your search for typos, try a different search term, <strong><a href={`tel:${settings.phone}`}>Call Us</a></strong> {settings.phone} or <strong><a href={`mailto:${settings.email}`}>Email Us</a></strong>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Team;