import React from 'react';

const SearchInput = React.forwardRef(({ id, placeholder, onChange, onClick }, ref) => {
    return (
        <input type="text" 
            className="search-input w-full h-[46px] bg-[#F1F1F1] placeholder:font-benton font-benton text-[11px] pl-3.5 placeholder:text-[11px] placeholder:text-black tracking-[1.2px] leading-4 font-normal  focus:border-0 md:focus:border focus:border-black focus:outline-none focus:pl-3.5"
            placeholder={placeholder} 
            onChange={onChange}
            ref={ref}
            onClick={onClick}
        />
    );
});

export default SearchInput;