import { useState, useEffect } from "react";

const useFetch = (url, method = 'GET', requestBody = null, dependencies = []) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!url) {
      setIsLoading(false);
      return;
    }

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (requestBody && (method !== 'GET' && method !== 'HEAD')) {
      options.body = JSON.stringify(requestBody);
    }

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response ERROR!");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [url, method, ...dependencies]);
  
  return { data, isLoading, error };
};

export default useFetch;