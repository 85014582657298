import { Link } from 'react-router-dom';
import NeighbourhoodCard from './NeighbourhoodCard';

const OtherNeighbourhoods = ({ neighbourhoods }) => {
    return neighbourhoods.length > 0 ? (
        <div className="container border border-transparent">
            <div className="my-12 text-center text-black text-2xl">
                Other neighbourhoods
            </div>
            <div className="grid grid-cols sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {neighbourhoods.slice(0, 3).map((neighbourhood, index) => (
                    <div key={index}>
                        <NeighbourhoodCard neighbourhood={neighbourhood} neighbourhoodUrl={neighbourhood.slug} />
                    </div>
                ))}
            </div>
            <div className="mt-10 text-center">
                <Link to="/neighbourhoods" className="button">View more</Link>
            </div>
        </div>
    ) : "";
}

export default OtherNeighbourhoods;