import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import TopDropdown from '../TopDropdown';

const HeaderTopline = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect, settings}) => {

    return (
        <div className="hidden md:block h-8">
            <div className="container h-8  mt-8 md:mt-0 hidden md:flex md:justify-around lg:justify-between tracking-[0.375px]">
                <div className="flex font-normal">
                    <span className="flex flex-wrap content-center">
                        Currency: 
                        {currencyOptions && selectedCurrencyOption && <TopDropdown options={currencyOptions} selectedOption={selectedCurrencyOption} onSelect={onCurrencyOptionSelect} /> }
                    </span>
                    <span className="pl-5 lg:pl-7 flex flex-wrap content-center">
                        Property dimensions: 
                        <TopDropdown options={dimensionOptions} selectedOption={selectedDimensionOption} onSelect={onDimensionOptionSelect} minWidth={100} />
                    </span>
                </div>
                <div className="flex font-normal">
                    {settings && settings.email &&
                        <span className="flex flex-wrap content-center mr-7 hover:opacity-60">
                            <MailIcon className="self-center mr-2" />
                            <a href={`mailto:${settings.email}`}>
                                {settings.email}
                            </a>
                        </span>
                    }
                    {settings && settings.phone &&
                        <span className="flex flex-wrap content-center hover:opacity-60">
                            <PhoneIcon className="self-center mr-2" />
                            <a href={`tel:${settings.phone}`}>{settings.phone}
                            </a>
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default HeaderTopline;