const HomeTitle = ({ props }) => {
    //<div className="container mx-auto text-center mt-12 border-b border-[#2A2A2A]">
    return (
        <div className="container mx-auto text-center mt-12">
            <p className="font-benton text-sm uppercase tracking-widest font-normal">
                {props.overtitle}
            </p>
            <p className="text-4xl max-w-4xl mx-auto mt-4 text-[#1D1D1B] mb-12 md:mb-16">{props.title}</p>
        </div>
    );
}

export default HomeTitle;