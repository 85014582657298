import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetGDPRPopup } from '../../queries/Pages';

const GDPRMessage = () => {
    const hasAcceptedGDPR = localStorage.getItem('gdprAccepted');
    const [visible, setVisible] = useState(!hasAcceptedGDPR);
    const GDPRPopup = useGetGDPRPopup(visible);

    useEffect(() => {
        if(hasAcceptedGDPR)
            setVisible(false);
    }, [hasAcceptedGDPR]);

    const acceptedGDPR = () => {
        localStorage.setItem('gdprAccepted', 'true');
        setVisible(false);
    };

    const GDPRText = GDPRPopup?.popup_description.document.flatMap((docItem, docIndex) => {
        if(docItem.type !== "paragraph") return [];
        
        return [
            <p className="text-sm font-benton font-normal mt-2">
                {docItem.children.map((childItem, childIndex) => {
                if(childItem.type === "link")
                    return <Link key={childIndex} to={childItem.href} className="underline font-bold hover:opacity-80">{childItem.children[0].text}</Link>
                else 
                    return childItem.text;
                })}
            </p>
        ];
    });

    return visible && GDPRPopup ? (
        <div className="gdpr-message fixed left-0 bottom-0 w-full h-[300px] md:h-[110px] bg-sothebys-blue flex items-center z-50">
            <div className="container text-white flex flex-col md:flex-row md:gap-10 justify-between h-full md:h-auto">
                <div className="flex flex-col h-1/2 md:h-auto justify-end">
                    <p className="text-xl tracking-[-0.02em] font-acta">{GDPRPopup.popup_title}</p>
                    {GDPRText}
                </div>
                <div className="flex items-center h-1/2 md:h-auto min-w-[200px]">
                    <span className="button !px-8 !border-white hover:bg-white hover:text-black" onClick={() => acceptedGDPR()}>Accept all cookies</span>
                </div>
            </div>
        </div>
    ) : null;
}

export default GDPRMessage;