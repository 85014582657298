import { useEffect, useRef, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import useDebounce from "../../utils/useDebounce";
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

const DropdownArrow = ({ activeDropdown, id }) => {
    return (
        <span className={`ml-2 self-center arrow ${
            activeDropdown === id ? 'arrowRotate' : ''
        }`}>
            <ArrowDown />
        </span>
    );
};

const formatNumber = (num) => {
    if (num < 1000000) {
        return `${(num / 1000).toFixed(2)}k`;
    } else {
        return `${(num / 1000000).toFixed(2)}M`;
    }
};

const PriceSlider = ({
    id,
    title,
    minPrice,
    maxPrice,
    changeTitle = false,
    options,
    optionType = false,
    initialMinPrice,
    initialMaxPrice,
    useDropdownArrow = false,
    onPriceRangeChange,
    activeDropdown,
    onToggle,
    onOptionsUpdate,
    width,
    }) => {
    const [currentTitle, setCurrentTitle] = useState(title);
    const minWidthPercentage = `${width}%`;
    const openingElementRef = useRef(null);
    const filterListElementRef = useRef(null);
    const [sliderValues, setSliderValues] = useState([minPrice, maxPrice]);
    const debouncedSliderValues = useDebounce(sliderValues, 500);
    const [displayValues, setDisplayValues] = useState([minPrice, maxPrice]);
    const [externalUpdate, setExternalUpdate] = useState(true);
    const [step, setStep] = useState(0);

    const toggleFilterList = (event) => {
        event.stopPropagation();
        onToggle(id);
    };

    const handleClickOutside = (event) => {
        if (
            openingElementRef.current &&
            !openingElementRef.current.contains(event.target) &&
            (!filterListElementRef.current ||
                !filterListElementRef.current.contains(event.target))
        ) {
            onToggle(null);
        }
    };

    useEffect(() => {
        onPriceRangeChange(debouncedSliderValues);
    }, [debouncedSliderValues]);

    useEffect(() => {
        setSliderValues([initialMinPrice, initialMaxPrice]);

        if(initialMaxPrice > 50000)
            setStep(10000);
        else
            setStep(250);
    }, [initialMaxPrice]);

    useEffect(() => {
        if (activeDropdown === id) {
            document.addEventListener('mouseup', handleClickOutside);
            document.addEventListener('touchend', handleClickOutside);

            return () => {
                document.removeEventListener('mouseup', handleClickOutside);
                document.removeEventListener('touchend', handleClickOutside);
            };
        }
    }, [activeDropdown, id]);

    const handleSliderChange = (values) => {
        setSliderValues(values);
        setDisplayValues(values);
        setExternalUpdate(false);

        if(changeTitle)
            setCurrentTitle(`£${formatNumber(values[0])}-${formatNumber(values[1])}`);
    };

    useEffect(() => {
        if(!externalUpdate) {
            setDisplayValues([sliderValues[0], sliderValues[1]]);
        }
        else {
            setDisplayValues([minPrice, maxPrice]);
            setExternalUpdate(true);
        }
        
    }, [minPrice, maxPrice]);

    useEffect(() => {
        setSliderValues([minPrice, maxPrice]);
        setDisplayValues([minPrice, maxPrice]);

        //if(changeTitle)
            //setCurrentTitle(`£${formatNumber(minPrice)}-${formatNumber(maxPrice)}`);
            //setCurrentTitle(`£`);
    }, [minPrice, maxPrice]);
    
    return (
        <div className={`SearchFilterOptions bg-[#F1F1F1] uppercase font-benton text-[11px] tracking-[1.2px] leading-4 font-normal w-full ${activeDropdown === id ? 'transition duration-300 ease-in-out border-0 md:border md:border-black' : 'border border-transparent'}`}>
            <div
                className="py-3.5 cursor-pointer relative"
                onClick={toggleFilterList}
                ref={openingElementRef}
            >
                <span className="pl-3.5 pr-2 flex justify-between">
                    {currentTitle}
                    {useDropdownArrow && (
                        <DropdownArrow activeDropdown={activeDropdown} id={id} />
                    )}
                </span>
            </div>

            {activeDropdown === id && (
                <div className={`static md:absolute top-[52px] bg-[#F1F1F1] flex h-[44px] items-center px-3`} style={{ minWidth: `calc(${minWidthPercentage} - 0.5rem)` }} ref={filterListElementRef}>
                    <span className="w-[60px] text-right">
                        £{formatNumber(displayValues[0])}
                    </span>
                    <span className="w-[200px] px-2.5">
                    <Slider
                        range
                        min={initialMinPrice}
                        max={initialMaxPrice}
                        step={step}
                        /*defaultValue={[minPrice, maxPrice]}*/
                        value={sliderValues}
                        onChange={handleSliderChange}
                    />
                    </span>
                    <span className="w-[60px]">
                        £{formatNumber(displayValues[1])}
                    </span>
                </div>
            )}
        </div>
    );
};

export default PriceSlider;
