import { ReactComponent as AppleIcon } from '../assets/icons/login_apple.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/login_google.svg';
import { ReactComponent as LinkedinIcon } from '../assets/icons/login_linkedin.svg';
import { ReactComponent as ShowPasswordIcon } from '../assets/icons/show_password.svg';
import { ReactComponent as HidePasswordIcon } from '../assets/icons/hide_password.svg';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetUser } from '../queries/Users';
import { sendEmail } from '../utils/sendEmail';

const Register = ({ settings }) => {
    const { getUser, isLoading, isError, data } = useGetUser();
    const [showPassword, setShowPassword] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [userExists, setUserExists] = useState(false);

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        //getUser(formData.email);
        setFormSubmitted(false);
        

        const errors = validateForm(formData);
        setFormErrors({ ...errors, emailSending: null });
       
        //return;
        /*if(userExists) {
            setFormErrors(prevErrors => ({ ...prevErrors, user: "This email address is already registered" }));
            return;
        }*/

        try {
            const userData = await getUser(formData.email);
            console.log(userData);
            if(userData && userData.length > 0) {
                setFormErrors(prevErrors => ({ ...prevErrors, user: "This email address is already registered" }));
                return;
            }
        } catch(error) {
            console.error("Error fetching user data", error);
        }

        return;

        if(Object.keys(errors).length === 0) {
            const sendingResult = await sendEmail({
                from: formData.email,
                to: settings.email,
                senderName: formData.fullName,
                subject: "[Sothebys UK] Account Registration",
                message: "User Registered",
                //phone: formData.phone
            });
            
            if(sendingResult.success) {
                setFormValid(true);
                setFormSubmitted(true);
                setFormData({
                    fullName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                });
            } else {
                setFormValid(false);
                setFormErrors(prevErrors => ({ ...prevErrors, emailSending: sendingResult.error }));
            }
        } else {
            setFormValid(false);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const validateForm = (data) => {
        const errors = {};
        if(!data.fullName) errors.fullName = 'Full name is required';
        if(!data.email) {
            errors.email = 'Email is required';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
            errors.email = 'Invalid email address';
        }
        if (!data.password) {
            errors.password = 'Password is required';
        } 
        if (!data.confirmPassword) {
            errors.confirmPassword = 'Password confirmation is required';
        }
        if(data.password !== data.confirmPassword)
            errors.confirmPassword = 'Passwords do not match';
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if(data && data.length > 0) {
            setUserExists(true);
        } else {
            setUserExists(false);
        }
    }, [data]);

    useEffect(() => {
        document.title = "Registration Page - Sotheby's";
    }, []);

    return (
        <div className="container md:!max-w-[460px] md:!mx-auto text-center font-normal">
            <div className="mt-7 md:mt-12 mb-9 md:mb-7 text-[22px] leading-[27px] text-black font-normal">Register a new account</div>
            <form className="mb-4" onSubmit={handleSubmit}>
                <div>
                    <input 
                        type="text" 
                        name="fullName"
                        placeholder="Full name" 
                        className={`login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none mb-4 pl-4 ${formErrors.fullName ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                        value={formData.fullName}
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <input 
                        type="email" 
                        name="email"
                        placeholder="E-mail address" 
                        className={`login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none mb-4 pl-4 ${formErrors.email ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                        value={formData.email}
                        onChange={handleChange} 
                    />
                </div>
                <div className="relative mb-4">
                    <input 
                        type={showPassword ? "text":"password"}
                        name="password" 
                        placeholder="Password" 
                        className={`login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none pl-4 ${formErrors.password ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`}
                        value={formData.password}
                        onChange={handleChange} 
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={toggleShowPassword}>
                        {showPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                    </div>
                </div>
                <div className="relative mb-4">
                    <input 
                        type={showPassword ? "text":"password"}
                        name="confirmPassword" 
                        placeholder="Confirm Password" 
                        className={`login-field w-full rounded border border-[#DDDDDC] h-[58px] focus:outline-none pl-4 ${formErrors.confirmPassword ? 'border border-[#FF0000] text-[#FF0000] placeholder:text-[#FF0000]' : ''}`} 
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={toggleShowPassword}>
                        {showPassword ? <HidePasswordIcon className="mt-0.5" /> : <ShowPasswordIcon />}
                    </div>
                </div>

                {!formValid && (
                    <div className="mb-4">
                        Please add details on all fields marked <span className="text-[#FF1414]">red</span>.
                        {Object.values(formErrors).map((error, index) => {
                            return (<p key={index}>{error}</p>);
                        })}
                    </div>
                )}

                <button className="button !px-[30px] !py-[13px]" type="submit">
                    Register now
                </button>
            </form>
            <div>
                <div className="mb-4 font-benton font-[15px] leading-[22px]">
                    or register with
                </div>
                <div className="flex gap-[55px] justify-center">
                    <a href="#">
                        <AppleIcon />
                    </a>
                    <a href="#">
                        <GoogleIcon />
                    </a>
                    <a href="#">
                        <LinkedinIcon />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Register;