// npx tailwindcss -i ./src/styles.css -o ./src/assets/css/styles.css --watch 


import HeaderTopline from '../components/Header/HeaderTopline';
import HeaderMainPart from '../components/Header/HeaderMainPart';

const Header = ({currencyOptions, selectedCurrencyOption, onCurrencyOptionSelect, dimensionOptions, selectedDimensionOption, onDimensionOptionSelect, settings}) => {
    return (
        <header className="header font-benton text-xs leading-6 over">
            <HeaderTopline 
                currencyOptions={currencyOptions}
                selectedCurrencyOption={selectedCurrencyOption}
                onCurrencyOptionSelect={onCurrencyOptionSelect}
                dimensionOptions={dimensionOptions}
                selectedDimensionOption={selectedDimensionOption}
                onDimensionOptionSelect={onDimensionOptionSelect}
                settings={settings}
            />
            <HeaderMainPart />
        </header>
    );
}
 
export default Header;