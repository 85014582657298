import { Link } from 'react-router-dom';
import LazyBackground from '../../utils/LazyBackground';
import useFetch from '../../utils/useFetch';
import PrepareNeighbourhoodName from '../../utils/prepareNeighbourhoodName';
import { useMediaQuery } from 'react-responsive';

const NeighbourhoodCard = ({ neighbourhood, neighbourhoodUrl = false }) => {
    const requestBody = {
        community: neighbourhood.neighbourhood_title,
        listingType: "Sale"
    };
 
    const isMobileDevice = useMediaQuery({ query: '(max-width: 1024px)' });
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const { data: response, isLoading: isLoadingProperties, error: errorProperties } = useFetch(
        `${apiUrl}/api/search-count`, 
        "POST", 
        requestBody);
        
    return (
        neighbourhood && (
        <Link to={neighbourhoodUrl ? `${neighbourhoodUrl}` : `/search?community.0=${PrepareNeighbourhoodName(neighbourhood.neighbourhood_title)}`} className="neighbourhood-card relative group overflow-hidden flex items-center justify-center">
            {neighbourhood && neighbourhood.listing_images && neighbourhood.listing_images.length > 0 &&
                <LazyBackground
                className={"w-full h-[231px] md:h-80 bg-cover bg-no-repeat bg-center transition-transform duration-1000 ease-in-out transform group-hover:scale-120"}
                backgroundImage={neighbourhood.listing_images[0].url}
                width={isMobileDevice ? 335 : 450}
                height={isMobileDevice ? 232 : 311}
                />
            }
            {/* Hide this white inner lines for now */}
            <div className="hidden absolute inset-0 border border-white m-2.5"></div>

            <div className="neighbourhood-title-background">
                <div className="invisible mx-[22.5px] my-5 text-white tracking-wider text-2xl md:text-[2rem] font-normal">
                    {neighbourhood.neighbourhood_title}
                </div>
            </div>
    
            <div className="absolute w-full h-full flex items-center justify-center text-white tracking-wider text-2xl md:text-[2rem] transition-all duration-500 ease-in-out transform group-hover:translate-y-[-19px] font-normal">
                    <div>{neighbourhood.neighbourhood_title}</div>
                </div>            
    
            <div className="absolute w-full h-full flex items-center justify-center text-white tracking-wider font-benton uppercase text-[20px] opacity-0 transition-all duration-500 ease-in-out transform group-hover:opacity-100 top-[-30px] group-hover:top-[19px] font-normal">
                {response && (<div>View {response.count} properties</div>)}
            </div>
        </Link>
        )
    );
}

export default NeighbourhoodCard;