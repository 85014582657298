import FeaturesAmenities from '../components/Property/FeaturesAmenities';
import PropertyAgentInfo from '../components/Property/PropertyAgentInfo';
import PropertyHero from '../components/Property/PropertyHero';
import PropertyMainInfo from '../components/Property/PropertyMainInfo';
import PropertyTopInfo from '../components/Property/PropertyTopInfo';
import SimilarProperties from '../components/Property/SimilarProperties';
import { useParams } from 'react-router-dom';
import useFetch from "../utils/useFetch";
import React, { useState, useEffect } from 'react';
import { useGetAgent } from '../queries/Agents';
import { useGetProperty } from '../queries/Properties';
import { SeoHelmet, seoData } from "../utils/Seo";
import { useMediaQuery } from 'react-responsive';
import PropertyPopupLinks from '../components/Property/PropertyPopupLinks';

const Property = ({ settings }) => {
    const { propertyId } = useParams();
    //const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [seoSettings, setSeoSettings] = useState({});
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    /*const handleActiveImageIndexChange = (index) => {
        setActiveImageIndex(index);
    }

    const [isRequestInformationActive, setIsRequestInformationActive] = useState(false);*/

    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const { data: similarProperties, isLoading, error } = useFetch(
        `${apiUrl}/api/search`, 
        "POST", 
        {});

    const property = useGetProperty(propertyId);

    /*const openRequestInformation = () => {
        setIsRequestInformationActive(true);
    }

    const closeRequestInformation = () => {
        setIsRequestInformationActive(false);
    }*/

    let agent = useGetAgent(false, false, property ? property.listing_agent_email : false);
    
    if((!agent || agent.active === "inactive") && settings) {
        agent = {
            email: "marylebone.branch@sothebysrealty.co.uk",
            phone: settings.phone,
            whatsapp: settings.whatsapp,
            firstname: "Marylebone",
            lastname: "Branch",
            languages: "",
            photo: "https://sothebys-assets.s3.eu-west-2.amazonaws.com/509f7f97-a0a8-47a6-91f1-5c115d2c2045.jpg",
            position: "",
            slug: ""
        }
    }

    useEffect(() => {
        if(property) {
            setSeoSettings(seoData({...property, defaultTitle: `${property.listing_title} - Sotheby's`}));
        }
    }, [property]);
    
    /*
    {property && <PropertyHeader 
                property={property}
                activeImageIndex={activeImageIndex}
                openRequestInformation={openRequestInformation}
            />}
            */
    return (
        <div className="single-property">
            <SeoHelmet seoSettings={seoSettings} />

            {property && <PropertyTopInfo 
                property={property} 
                agent={agent} 
                //activeImageIndex={activeImageIndex} 
                //openRequestInformation={openRequestInformation}
            />}
            {/*
            {property && agent && <PropertyHero 
                property={property} 
                onActiveImageIndexChange={handleActiveImageIndexChange} 
                agent={agent} 
                onRequestInformationActive={isRequestInformationActive}    
                onCloseRequestInformation={closeRequestInformation}
                siteSettings={settings}
            />}
            */}

            {/*{(isMobile || isTablet) && property &&
                <PropertyPopupLinks
                    property={property} 
                    openFullscreen={handleOpenFullscreen} 
                    activeImageIndex={activeImageIndex} 
                    openRequestInformation={openRequestInformation}
                    openFullscreenFloorplan={handleOpenFullscreenFloorplan}
                />
            }*/}
            
            {property && <PropertyMainInfo property={property} />}
            {property && <FeaturesAmenities property={property} />}
            {agent && <PropertyAgentInfo agent={agent} />}
            

            {/*<PropertyCta />
            <PropertyContent />*/}

            
            {similarProperties && <SimilarProperties 
                properties={similarProperties} 
                viewMoreButton={true}
                showTitle={true} 
            />}
            
        </div>
    );
}

export default React.memo(Property);