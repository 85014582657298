import { ReactComponent as PhoneIcon } from '../../assets/icons/phone_footer.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email_footer.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp_footer.svg';

const FooterContacts = ({ settings }) => {
    
    return (
        <div className="w-full md:w-1/3 xl:w-1/2 flex items-center">
            <div className="font-normal text-left hidden xl:flex items-center tracking-[0.375px]">
                <span className="mx-1.5">
                    Contact us
                </span>
                {settings && settings.phone &&
                    <span className="contact-item py-2.5 px-6 border border-blacked rounded-full mx-1.5 text-[15px] hover:font-medium flex flex-col" data-title={settings.phone}>
                        <a href={`tel:${settings.phone}`}>
                            {settings.phone}
                        </a>
                    </span>
                }
                {settings && settings.email &&
                    <span className="contact-item py-2.5 px-6 border border-blacked rounded-full mx-1.5 text-[15px] hover:font-medium flex flex-col" data-title="E-mail">
                        <a href={`mailto:${settings.email}`}>
                            E-mail
                        </a>
                    </span>
                }
                {settings && settings.whatsapp &&
                    <span className="contact-item py-2.5 px-6 border border-blacked rounded-full mx-1.5 text-[15px] hover:font-medium flex flex-col" data-title="Whatsapp">
                        <a href={`https://wa.me/${settings.whatsapp
}`}>
                            Whatsapp
                        </a>
                    </span>
                }
            </div>
            <div className="xl:hidden flex justify-between md:justify-around w-full">
                <span className="font-benton font-medium md:font-normal text-[#111111] text-[15px] md:text-[13px] leading-[18px] tracking-[0.375px]">
                    Contact us
                </span>
                <div className="flex">
                    {settings && settings.email &&
                        <a href={`mailto:${settings.email}`} className="mr-7">
                            <EmailIcon />
                        </a>
                    }
                    {settings && settings.whatsapp &&
                        <a href={`https://wa.me/${settings.whatsapp
    }`} className="mr-7">
                            <WhatsappIcon />
                        </a>
                    }
                    {settings && settings.phone &&
                        <a href={`tel:${settings.phone}`}>
                            <PhoneIcon />
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}

export default FooterContacts;