import { useState, useEffect, useRef } from 'react';
import { ReactComponent as ExpandMenuArrow } from '../../assets/icons/slider_next_black.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as SignInIcon } from '../../assets/icons/sign_in.svg';
import { ReactComponent as FavoritesIcon } from '../../assets/icons/favorites.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as NewsletterIcon } from '../../assets/icons/mail.svg';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as BackIcon } from '../../assets/icons/property_back.svg';
import { useGetMenu } from '../../queries/Menus';

const MainMenu = ({ isOpen, setMenuOpen }) => {
    const [activeMenu, setActiveMenu] = useState(null);
    const menuRef = useRef(null);
    const location = useLocation();
    const unsortedMenu = useGetMenu();
    const [menu, setMenu] = useState([]);
    const isPropertyPage = location.pathname.includes("/property/") ? true : false;
    
    const handleMenuClick = (item) => {
        const hasChildren = menu.some(menuItem => menuItem.parent[0] && menuItem.parent[0].id === item.id);
        
        if(hasChildren) {
            if(activeMenu === item.id)
                setActiveMenu(null);
            else
                setActiveMenu(item.id);
        } else {
            setMenuOpen(false);
            setActiveMenu(null);
        }
    }

    const handleSubmenuClick = () => {
        setActiveMenu(null);
        setMenuOpen(false);
    }

    const [menuTop, setMenuTop] = useState('-100vh');

    useEffect(() => {
    if (isOpen) {
        setMenuTop('60px');
    } else {
        setMenuTop('-100vh');
    }
    }, [isOpen]);

    // click outside to close the menu
    useEffect(() => {
        function handleClickOutside(event) {
            if(menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, setMenuOpen]);

    useEffect(() => {
        if(unsortedMenu) {
            const sortedMenu = [...unsortedMenu].sort((a, b) => {
                if(a.order === null) return 1;
                if(b.order === null) return -1;
                return a.order - b.order;
            });

            setMenu(sortedMenu);
        }
    }, [unsortedMenu]);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    
    return (
        <div 
            className={`absolute z-[100] left-0 bg-white w-full mt-3 overflow-y-auto transition-all duration-700`} 
            ref={menuRef} 
            style={{
                top: menuTop,
                height: isMobile ? 'calc(100vh - 72px)' : ''
              }}
        >
            <div className="container flex flex-col md:flex-row justify-between overflow-auto h-full md:h-auto">
                <div className="flex flex-row tracking-[0.375px] text-[26px] md:text-2xl font-acta font-normal w-full md:w-fit mb-0 md:mb-6">
                    {/* Main Menu */}
                    {((isMobile && activeMenu === null) || !isMobile) && (
                        <div className="w-full md:w-fit">
                            {menu && menu.map((item, index) => {
                                if(item.parent.length === 0) {
                                    const hasChildren = menu.some(menuItem => menuItem.parent[0] && menuItem.parent[0].id === item.id);

                                    return (
                                        <div className="flex items-center justify-between" key={index} >
                                            
                                                <span 
                                                    className={`main-menu-item inline-flex justify-center flex-col items-center h-[53px] cursor-pointer ${activeMenu === item.id ? 'font-medium pb-1' : ''}`}
                                                    onClick={() => handleMenuClick(item)}
                                                    data-title={item.title}
                                                >
                                                    {item.url !== ""
                                                        ? <Link to={item.url}>{item.title}</Link>
                                                        : item.title
                                                    }
                                                </span>
                                                {hasChildren && <ExpandMenuArrow className="md:ml-12 cursor-pointer" 
                                                onClick={() => handleMenuClick(item)} />}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}

                    {/* Sub Menu */}
                    {((isMobile && activeMenu !== null) || !isMobile) && (
                        <div className="md:ml-[61px]">
                            {/* Back Icon */}
                            <div className="flex md:hidden items-center mb-2.5 mt-3" onClick={() => setActiveMenu(null)}>
                                    <BackIcon className="self-center" />
                                    <span className="ml-3.5 font-benton text-[15px] leading-[22px] text-[#888888] tracking-wide font-normal">BACK</span>
                            </div>

                            {menu && menu.map((item, index) => {
                                const hasChildren = menu.some(menuItem => menuItem.parent[0] && menuItem.parent[0].id === item.id);

                                if(hasChildren && activeMenu === item.id) {
                                    return (
                                        <div className="flex flex-col" key={index}>
                                            {menu.filter(menuItem => menuItem.parent[0] && menuItem.parent[0].id === item.id).map(childItem => (
                                                <Link 
                                                    key={childItem.id}
                                                    to={childItem.url} 
                                                    onClick={handleSubmenuClick}
                                                >
                                                    <span key={childItem.id} className="flex items-center h-[53px] cursor-pointer">
                                                        {childItem.title}
                                                    </span>
                                                </Link>
                                            ))}
                                        </div>
                                    );
                                }

                                return null;
                            })}
                        </div>
                    )}
                </div>
                
                {/* Right column */}
                <div className="flex flex-col items-start md:items-end mt-[15px] font-normal tracking-[1.5px] gap-5 mb-2.5 md:mb-0">
                    
                    <Link to="/login" onClick={handleSubmenuClick}>
                        <span className="flex items-center leading-6 flex-row-reverse md:flex-row hover:opacity-60">
                            <span className="ml-3 md:ml-0">SIGN IN</span> <SignInIcon className="md:ml-3" />
                        </span>
                    </Link>
                    <Link to="#">
                        <span className="flex items-center leading-6 flex-row-reverse md:flex-row hover:opacity-60">
                            <span className="ml-3 md:ml-0">FAVORITES</span> <FavoritesIcon className="md:ml-3" />
                        </span>
                    </Link>
                    <Link to="#">
                        <span className="flex items-center leading-6 flex-row-reverse md:flex-row hover:opacity-60">
                            <span className="ml-3 md:ml-0">SAVED SEARCHES</span> <SearchIcon className="md:ml-3" />
                        </span>
                    </Link>
                    <Link to="#">
                        <span className="flex items-center leading-6 flex-row-reverse md:flex-row hover:opacity-60">
                            <span className="ml-3 md:ml-0">NEWSLETTER</span> <NewsletterIcon className="md:ml-3" />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MainMenu;