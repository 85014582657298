import { Helmet } from "react-helmet-async";

const SeoHelmet = ({ seoSettings }) => {
    return seoSettings ? (
        <Helmet key={Math.random()}>
            <title>{seoSettings.pageTitle}</title>
            {seoSettings.pageDescription && <meta name="description" content={seoSettings.pageDescription} />}
            {seoSettings.pageKeywords && <meta name="keywords" content={seoSettings.pageKeywords} />}
            {seoSettings.ogTitle && <meta property="og:title" content={seoSettings.ogTitle} />}
            {seoSettings.ogDescription && <meta property="og:description" content={seoSettings.ogDescription} />}
            {seoSettings.facebookImage && <meta property="og:image" content={seoSettings.facebookImage} />}
            {seoSettings.twitterImage && <meta name="twitter:image" content={seoSettings.twitterImage} />}
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    ) : null;
}

export { SeoHelmet };

const seoData = ({ OGTitle, OGDescription, PageTitle, PageDescription, PageKeywords, TwitterImage, FacebookImage, defaultTitle }) => {
    
    return {
        ogTitle: OGTitle,
        ogDescription: OGDescription,
        pageTitle: PageTitle?.length > 0 ? PageTitle : defaultTitle,
        pageDescription: PageDescription,
        pageKeywords: PageKeywords,
        twitterImage: TwitterImage?.url,
        facebookImage: FacebookImage?.url
    };
}

export { seoData };