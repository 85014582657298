import { useState, useEffect } from "react";
import { useGetAboutUs } from "../queries/Pages";
import DynamicComponent from "../components/DynamicComponent";
import { SeoHelmet, seoData } from "../utils/Seo";

const AboutUs = () => {
    const aboutUsPageData = useGetAboutUs();
    const [pageComponents, setPageComponents] = useState(null);
    const [seoSettings, setSeoSettings] = useState({});

    useEffect(() => {
        if(aboutUsPageData) {
            setSeoSettings(seoData({...aboutUsPageData, defaultTitle: "About Us - Sotheby's"}));
            setPageComponents(aboutUsPageData.document.document);
        }
    }, [aboutUsPageData]);

    return (
        <div className="about-us">
            <SeoHelmet seoSettings={seoSettings} />
            {pageComponents && pageComponents.length > 0 && pageComponents.map((componentData, index) => (
                <DynamicComponent
                    key={index}
                    componentData={componentData}
                />
            ))}
        </div>
    );
}

export default AboutUs;