import { Link } from 'react-router-dom';

const FooterLinks = () => {
    return (
        <div className="hidden md:flex flex-wrap mt-9 justify-center tracking-[0.375px]">
            <span>
                <Link to="/terms-of-use" className="footer-link inline-flex flex-col border-r border-footer-line px-4 hover:font-normal" data-title="Terms of use">Terms of use</Link>
            </span>
            <span>
                <Link to="/privacy-policy" className="footer-link inline-flex flex-col border-r border-footer-line px-4 hover:font-normal" data-title="Privacy policy">Privacy policy</Link>
            </span>
            <span>
                <Link to="#" className="footer-link inline-flex flex-col border-r border-footer-line px-4 hover:font-normal" data-title="Sitemap">Sitemap</Link>
            </span>
            <span>
                <Link to="#" className="footer-link inline-flex flex-col border-r border-footer-line px-4 hover:font-normal" data-title="Portfolio">Portfolio</Link>
            </span>
            <span>
                <Link to="#" className="footer-link inline-flex flex-col px-4 hover:font-normal" data-title="Archive">Archive</Link>
            </span>
        </div>
    )
}

export default FooterLinks;