import PropertyCard from "../Property/PropertyCard";

const PropertiesList = ({ properties }) => {
    
    return (
        <div className="tab-content container grid gap-5 md:gap-x-[35px] lg:gap-x-[48px] md:gap-y-[40px] lg:gap-y-[58px] grid-cols-1 lg:grid-cols-2">
            { properties && properties.map((property, index) => (
                <div key={property.idField} className="max-w-[695px] mx-auto lg:mx-0">
                    <PropertyCard key={index} property={property} />
                </div>
            ))}       
        </div>
    )
}

export default PropertiesList;